import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  MenuItem,
  Menu,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
import { getAllTax, getSingleTax } from "redux/festures/taxSlice";
import { getAllAffiliate } from "redux/festures/AffiliateSlice";
import MDBadge from "components/MDBadge";
import { updateJoiningStatus } from "redux/festures/AffiliateSlice";
import SingleAffilate from "./SingleAffilate";

// import Form from "./Form";
// import SingleBlog from "./SingleBlog";

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "fullName", accessor: "fullName" },
    { Header: "email", accessor: "email" },
    // { Header: "userId", accessor: "userId" },
    // {
    //   Header: "why You Want Join Affiliate Partener",
    //   accessor: "why You Want Join Affiliate Partener",
    // },

    isAdmin?.permissions?.all
      ? { Header: "joining Status", accessor: "joining Status" }
      : isAdmin?.permissions?.affiliate?.includes("UPDATE") && {
          Header: "joining Status",
          accessor: "joining Status",
        },
    isAdmin?.permissions?.all
      ? { Header: "status", accessor: "status" }
      : isAdmin?.permissions?.affiliate?.includes("UPDATE") && {
          Header: "status",
          accessor: "status",
        },
    isAdmin?.permissions?.all
      ? { Header: "View", accessor: "view" }
      : isAdmin?.permissions?.affiliate?.includes("VIEW") && {
          Header: "View",
          accessor: "view",
        },
  ].filter(Boolean);
}
const Affiliates = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [viewData, setViewData] = useState("");
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const { Loading, affiliate, isPages } = useSelector((state) => ({ ...state?.isAffiliate }));

  const [pagess, setPagess] = useState(1);

  useEffect(() => {
    dispatch(getAllAffiliate(`${process.env.REACT_APP_API}/getAllAffiliate?page=${pagess || ""}`));
  }, [pagess]);

  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  const [isOrderDetails, setIsOrderDetails] = useState(null);

  const handleOpenMenu = ({ event }) => {
    setOpenMenu(event.currentTarget);
  };
  const handleOpenMenu2 = ({ event }) => {
    setOpenMenu2(event.currentTarget);
  };

  // console.log(openMenu,"openMenu")

  const isStatusUpdateFunction = (isStatus) => {
    const joiningStatus = { joiningStatus: isStatus };
    // console.log(isStatus, "isStatus");

    dispatch(
      updateJoiningStatus({
        url: `${process.env.REACT_APP_API}/updateAffiliateJoiningStatus/${isOrderDetails?._id}`,

        data: joiningStatus,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: `${data?.payload?.message}`,
        })
      );
      if (data?.payload?.success) {
        dispatch(getAllAffiliate(`${process.env.REACT_APP_API}/getAllAffiliate`));
      }
    });
  };
  const StatusUpdateFunction = (isStatus) => {
    const status = { status: isStatus };
    // console.log(isStatus, "isStatus");

    dispatch(
      updateJoiningStatus({
        url: `${process.env.REACT_APP_API}/updateAffiliateStatus/${isOrderDetails?._id}`,

        data: status,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: `${data?.payload?.message}`,
        })
      );
      if (data?.payload?.success) {
        dispatch(getAllAffiliate(`${process.env.REACT_APP_API}/getAllAffiliate`));
      }
    });
  };

  useEffect(() => {
    if (affiliate && affiliate.length > 0) {
      const temprows =
        affiliate &&
        affiliate?.at(0) &&
        affiliate?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          fullName: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {/* {console.log(value?.taxPercent)} */}
                {value?.fullName || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          email: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {/* {console.log(value?.taxPercent)} */}
                {value?.email || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          userId: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {/* {console.log(value?.taxPercent)} */}
                {value?.userId || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                setViewData(value)

                // dispatch(getSingleBlog(`/getByBlogId/${value?._id}`));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
          "why You Want Join Affiliate Partener": (
            // <MDBox flexDirection="column">
            //   <MDTypography fontSize={12.5}>
            //     {/* {console.log(value?.taxPercent)} */}
            //     {value?.whyYouWantJoinAffiliatePartener || "N/A"}
            //   </MDTypography>
            // </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "250px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.whyYouWantJoinAffiliatePartener || "N/A"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          "joining Status": (
            <>
              <Tooltip title={value?.joiningStatus || "N/A"}>
                <IconButton
                  aria-controls="notification-menu"
                  disabled={value?.joiningStatus === "APPROVEL" && value?.joiningStatus === "REJECTED"}
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setIsOrderDetails(value);
                  }}
                >
                  <MDBadge
                    badgeContent={value?.joiningStatus}
                    color={
                      (value?.joiningStatus === "APPROVEL" && "success") ||
                      (value?.joiningStatus === "REJECTED" && "error") ||
                      (value?.joiningStatus === "PENDING" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          status: (
            <>
              <Tooltip title={value?.status || "N/A"}>
                <IconButton
                  aria-controls="notification-menu"
                  disabled={value?.joiningStatus === "PENDING"}
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu2({ event: e });
                    setIsOrderDetails(value);
                  }}
                >
                  <MDBadge
                    badgeContent={value?.status}
                    color={
                      (value?.status === "ACTIVE" && "success") ||
                      (value?.status === "SUSPENDED" && "error") ||
                      (value?.status === "TERMINATED" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [affiliate]);

  // console.log(affiliate, "affiliate");
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                User&apos;s Table{" "}
              </MDTypography>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : affiliate && affiliate.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={affiliate && affiliate.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        {/* <Form isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} /> */}
      {/* </SkModal> */}
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <SingleAffilate viewData={viewData}/>
      </SkModal>

      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("APPROVEL", true);
            }}
          >
            APPROVEL
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("PENDING", true);
            }}
          >
            PENDING
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("REJECTED", true);
            }}
          >
            REJECTED
          </MenuItem>
        </>
      </Menu>
      <Menu
        anchorEl={openMenu2}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu2)}
        onClose={() => setOpenMenu2(false)}
      >
        <>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu2(false);
              StatusUpdateFunction("ACTIVE", true);
            }}
          >
            ACTIVE
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu2(false);
              StatusUpdateFunction("TERMINATED", true);
            }}
          >
            TERMINATED
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu2(false);
              StatusUpdateFunction("SUSPENDED", true);
            }}
          >
            SUSPENDED
          </MenuItem>
        </>

        {/* <>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("APPROVEL", true);
            }}
          >
            APPROVEL
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("PENDING", true);
            }}
          >
            PENDING
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              isStatusUpdateFunction("REJECTED", true);
            }}
          >
            REJECTED
          </MenuItem>
        </> */}
      </Menu>
    </>
  );
};

export default Affiliates;
