import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllRange = createAsyncThunk("getAllRange", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllGlobleRange = createAsyncThunk("getAllGlobleRange", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateRange = createAsyncThunk("updateRange", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteRange = createAsyncThunk("deleteRange", async ({ url, data }) => {
  try {
    const res = await axios.delete(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createRange = createAsyncThunk("createRange", async ({ url, data }) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    res.data;
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});

const RangeSlice = createSlice({
  name: "RangeSlice",
  initialState: {
    Range: null,
    singleRange: null,
    isLoading: false,
    createUpdateLoading: false,
    Loading: false,
    singleDoshaRange: null,
  },
  reducers: {
    isApplyLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    isSingleData: (state, action) => {
      state.singleRange = action.payload;
    },
    isSigleDoshaRange: (state, action) => {
      state.singleDoshaRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRange.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllRange.fulfilled, (state, action) => {
        state.Loading = false;
        state.Range = action.payload?.data;
      })
      .addCase(getAllGlobleRange.fulfilled, (state, action) => {
        state.Loading = false;
        state.Range = action.payload?.data;
      })

      .addCase(getAllRange.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export const { isSingleData, isApplyLoading, isSigleDoshaRange } = RangeSlice.actions;
export default RangeSlice.reducer;
