import axios from "axios";
import http from "Utils/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllQuerys = createAsyncThunk("getAllQuerys", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleQueryById = createAsyncThunk("getSingleQueryById", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalQueryById = createAsyncThunk("getGlobalQueryById", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateQuerys = createAsyncThunk("updateQuerys", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteQuerys = createAsyncThunk("deleteQuerys", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createQueryByShubham = createAsyncThunk(
  "createQueryByShubham",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
const querySlice = createSlice({
  name: "querySlice",
  initialState: {
    query: null,
    singleQuery: null,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuerys.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllQuerys.fulfilled, (state, action) => {
        state.Loading = false;
        state.query = action.payload?.data;
      })
      .addCase(getGlobalQueryById.fulfilled, (state, action) => {
        state.Loading = false;
        state.query = action.payload?.data;
      })
      .addCase(getAllQuerys.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createQueryByShubham.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(createQueryByShubham.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(createQueryByShubham.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSingleQueryById.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getSingleQueryById.fulfilled, (state, action) => {
        state.Loading = false;
        state.singleQuery = action.payload?.data;
      })
      .addCase(getSingleQueryById.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export default querySlice.reducer;
