import PropTypes from "prop-types";
import {
  Add,
  Cancel,
  CurrencyRupee,
  Delete,
} from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import ApnaSelect2 from "components/ApnaSelect";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkModal from "components/SkModal";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ImagePicker from "components/ApnaUploader";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "redux/festures/categorySlice";
import { createProduct } from "redux/festures/productSlice";
import { handleAlert } from "redux/festures/alertSlice";
import { useMaterialUIController } from "context";
import { updateProduct } from "redux/festures/productSlice";
import http from "Utils/api2";
import SkLoading from "components/SkLoading";
import { getAllGlobalProducts } from "redux/festures/productSlice";
import CircularProgress from "@mui/material/CircularProgress";
import AstrieskIcon from "components/AstrieskIcon";
import { SkPrice } from "Utils/dateFunc";
import { getAllDisease } from "redux/festures/diseaseSlice";
import SkEditor from "components/SKeditor/SkEditor";
import { getSubGlobalCategory2 } from "redux/festures/categorySlice";
import { getEcomCategory } from "redux/festures/categorySlice";
import { getChildCategory } from "redux/festures/categorySlice";

const CreateForm = ({
  isOpen,
  setIsOpen,
  isSwitch,
  isOpenUpdate,
  setIsOpenUpdate,

  productId,
  updateProductModal,
  setUpdateProductModal,
  ecom,
}) => {
  const picInput = useRef(null);
  const dispatch = useDispatch();
  const admin = localStorage.getItem("admin_id");
  const [controller] = useMaterialUIController();
  const [isCategory, setIsCategory] = useState([]);
  const { darkMode } = controller;
  const { AllProducts, singleProduct, Loading, createUpdateLoading } = useSelector((state) => ({
    ...state.isProducts,
  }));

  console.log(singleProduct, "singleProduct");

  const { city } = useSelector((state) => state.isCity);
  const { category2, subCategory2, childCategory } = useSelector((data) => ({
    ...data?.isCategory,
  }));
  const { disease } = useSelector((data) => ({ ...data?.isDisease }));

  // console.log(category, "category");

  const [serverThumbnail, setServerThumbnail] = useState("");
  const [isThumbnil, setIsThumbnil] = useState(null);
  const [productBanner, setProductBanner] = useState(null);
  const [serverMobileBanner, setServerMobileBanner] = useState(null);
  const [serverProductBanner, setServerProductBanner] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [mobileBanner, setMobileBanner] = useState(null);
  const [serverProductImage, setServerProductImage] = useState(null);
  const [multipleVariant, setMultipleVariant] = useState("Yes");
  const unitOption = [
    {
      value: "kg",
      label: "Kilogram",
    },
    {
      value: "g",
      label: "Gram",
    },
    {
      value: "l",
      label: "Litre",
    },
    {
      value: "ml",
      label: "Mililitre",
    },
  ];

  const [allBrands, setAllBrands] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const [isProductDescription, setIsProductDescription] = useState("");
  const [isProductkeyBenefit, setIsProductkeyBenefit] = useState("");
  const [isProductdirectionForUse, setIsProductdirectionForUse] = useState("");
  const [isProductSafetyInformation, setIsProductSafetyInformation] = useState("");
  const [isProductotherInformation, setIsProductotherInformation] = useState("");

  const [isData, setIsData] = useState({
    name: "",
    brandId: "",
    categoryId: "",
    mrp: "",
    priceDiscount: "",
    // beforeTaxValue: "",
    afterTaxValue: "",
    returnInDays: "",
    stock: "",
    sold: "",
    taxId: "",
    // type: "AMOUNT",
    affiliate: "",
    dosha: "",
    disease: "",
    unit: "",
  });

  const [priceVariant, setPriceVariant] = useState([
    {
      mrp: [],
      afterTaxValue: [],
      stock: [],
      unit: [],
    },
  ]);
  const [isPCategoriesId, setIsPCategoriesId] = useState("");
  const [isSubCategoriesId, setIsSubCategoriesId] = useState("");
  const [mrpInput, setMrpInput] = useState("");
  const [afterTaxValueInput, setAfterTaxValueInput] = useState("");
  const [stockInput, setStockInput] = useState("");
  const [unit, setUnit] = useState("");

  useEffect(() => {
    if (isOpen) {
      http
        .get(`/getAllBrand`)
        .then((response) => {
          setAllBrands(response?.data?.data || []);
        })
        .catch((error) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: "error",
              msg: error?.response?.data?.message,
            })
          );
        });

      http
        .get(`/getAllTax`)
        .then((response) => {
          const tax =
            response?.data?.data && response?.data?.data?.length
              ? [...response?.data?.data]?.map((ele) => {
                  const temp = { ...ele };
                  temp.taxPercent = `${temp.taxPercent} %`;
                  return temp;
                })
              : [];
          setAllTax(tax);
        })
        .catch((error) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: "error",
              msg: error?.response?.data?.message,
            })
          );
        });

      dispatch(getCategory(`${process.env.REACT_APP_API}/getAllSubcategory/${admin}`));
      dispatch(getAllDisease("/dicease/getAllDicease?disable=false"));
    }
  }, [isOpenUpdate, isOpen]);
  useEffect(() => {
    dispatch(
      getSubGlobalCategory2({
        url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${
          isPCategoriesId || ""
        }&disable=false`,
      })
    );
    dispatch(
      getChildCategory({
        url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${
          isSubCategoriesId || ""
        }&disable=false`,
      })
    );
  }, [isPCategoriesId, isSubCategoriesId]);

  useEffect(() => {
    dispatch(
      getEcomCategory({
        url: `getAllPcategory/${admin}`,
      })
    );
  }, []);
  useEffect(() => {
    if (isOpenUpdate && singleProduct) {
      setIsData({
        name: singleProduct?.name || "",
        brandId: singleProduct?.brandId?._id || "",
        categoryId: singleProduct?.categoryId?._id || "",
        mrp: singleProduct?.mrp || "",
        priceDiscount: singleProduct?.priceDiscount || "",
        // beforeTaxValue: singleProduct?.priceVarient?.beforeTaxValue || "",
        afterTaxValue: singleProduct?.afterTaxValue || "",
        returnInDays: singleProduct?.returnInDays || "",
        dosha: singleProduct?.dosha || "",
        disease: singleProduct?.diseaseId || "",
        stock: singleProduct?.stock || "",
        sold: singleProduct?.sold || "",
        taxId: singleProduct?.taxId || "",
        type: singleProduct?.affiliate?.type || "",
        unit: singleProduct?.unit || "",
        // affiliate: singleProduct?.affiliate?.affiliate || "",
      });
      setPriceVariant([
        {
          mrp: singleProduct?.priceVariant?.[0]?.mrp || "",
          afterTaxValue: singleProduct?.priceVariant?.[0]?.afterTaxValue || "",
          stock: singleProduct?.priceVariant?.[0]?.stock || "",
          unit: singleProduct?.priceVariant?.[0]?.unit || "",
        },
      ]);
      setMrpInput(singleProduct?.priceVariant?.[0]?.mrp);
      setAfterTaxValueInput(singleProduct?.priceVariant?.[0]?.afterTaxValue);
      setStockInput(singleProduct?.priceVariant?.[0]?.stock);
      setUnit(singleProduct?.priceVariant?.[0]?.unit);
      setMultipleVariant(singleProduct?.multipleVariant ? "Yes" : "No");
      setServerThumbnail(singleProduct?.thumbnail);
      setServerProductBanner(singleProduct?.productBanner);
      setServerMobileBanner(singleProduct?.mobileBanner || "");
      setServerProductImage(singleProduct?.productImages);
      setIsPCategoriesId(singleProduct?.categoryId?.pCategory || "");
      const tempImages = [];
      const tempImages2 = [];
      const tempImages3 = [];

      if (singleProduct?.productImages && singleProduct?.productImages?.length) {
        singleProduct?.productImages?.forEach((ele) => tempImages.push(ele));
      }

      if (singleProduct?.productBanner && singleProduct?.productBanner?.length) {
        singleProduct?.productBanner?.forEach((ele) => tempImages2.push(ele));
      }

      if (singleProduct?.mobileBanner && singleProduct?.mobileBanner?.length) {
        singleProduct?.mobileBanner?.forEach((ele) => tempImages3.push(ele));
      }

      // Ensure that tempImages and tempImages2 are set to an empty array if they are null
      setServerProductBanner(tempImages2 || []);
      setServerProductImage(tempImages || []);
      setServerMobileBanner(tempImages3 || []);
      setIsProductDescription(singleProduct?.description);
      setIsProductkeyBenefit(singleProduct?.keyBenefit);
      setIsProductdirectionForUse(singleProduct?.directionForUse);
      setIsProductSafetyInformation(singleProduct?.SafetyInformation);
      setIsProductotherInformation(singleProduct?.otherInformation);
    } else {
      setIsData({
        name: "",
        brandId: "",
        categoryId: "",
        mrp: "",
        priceDiscount: "",
        returnInDays: "",
        // multipleVariant: "",
        afterTaxValue: "",
        stock: "",
        sold: "",
        taxId: "",
        type: "AMOUNT",
        affiliate: "",
        unit: "",
      });
      setIsPCategoriesId("");
      setPriceVariant([]);
      // setIsCategory([])
      setMultipleVariant("No");
      setServerProductBanner(null);
      setServerMobileBanner(null);
      setServerProductImage(null);
      setServerThumbnail(null);
      setProductBanner(null);
      setProductImages(null);
      setIsThumbnil(null);
      setMobileBanner(null);
      setIsProductDescription("");
      setIsProductkeyBenefit("");
      setIsProductdirectionForUse("");
      setIsProductSafetyInformation("");
      setIsProductotherInformation("");
    }
  }, [singleProduct, isOpenUpdate]);

  const handleSubmitCrateProduct = async (e) => {
    e.preventDefault();

    const formdata = new FormData();

    if (productBanner && productBanner?.length > 0) {
      productBanner?.forEach((image) => formdata.append("banner", image));
    }

    if (productImages && productImages?.length > 0) {
      productImages?.forEach((image) => formdata.append("images", image));
    }
    if (mobileBanner && mobileBanner?.length > 0) {
      mobileBanner?.forEach((image) => formdata.append("mobile", image));
    }

    if (isThumbnil) {
      formdata.append("thumbnail", isThumbnil);
    }

    formdata.append("name", isData?.name);

    // update soon this filed
    formdata.append("returnInDays", parseInt(isData?.returnInDays));
    formdata.append("brandId", isData?.brandId);
    formdata.append("categoryId", isData?.categoryId);
    formdata.append("multipleVariant", multipleVariant === "No" ? false : true);

    if (multipleVariant === "No") {
      formdata.append("mrp", isData?.mrp);
      formdata.append("afterTaxValue", isData?.afterTaxValue);
      formdata.append("stock", isData?.stock);
      formdata.append("unit", unit);
    } else {
      priceVariant.forEach((variant, index) => {
        formdata.append(`priceVariant[${index}][mrp]`, variant?.mrp);
        formdata.append(`priceVariant[${index}][afterTaxValue]`, variant?.afterTaxValue);
        formdata.append(`priceVariant[${index}][stock]`, variant?.stock);
        formdata.append(`priceVariant[${index}][unit]`, variant?.unit);
      });
    }

    formdata.append("taxId", isData?.taxId);
    {
      isData?.dosha && formdata.append("dosha", isData?.dosha);
    }
    {
      isData?.disease && formdata.append("diseaseId", isData?.disease);
    }

    {
      isProductDescription?.length > 0 && formdata.append("description", isProductDescription);
    }

    {
      isProductkeyBenefit?.length > 0 && formdata.append("keyBenefit", isProductkeyBenefit);
    }

    {
      isProductdirectionForUse?.length > 0 &&
        formdata.append("directionForUse", isProductdirectionForUse);
    }

    {
      isProductSafetyInformation?.length > 0 &&
        formdata.append("SafetyInformation", isProductSafetyInformation);
    }

    {
      isProductotherInformation?.length > 0 &&
        formdata.append("otherInformation", isProductotherInformation);
    }

    const url =
      singleProduct && isOpenUpdate
        ? `${process.env.REACT_APP_API}/updateProduct/${singleProduct._id}/${admin}`
        : `${process.env.REACT_APP_API}/createProduct/${admin}`;

    try {
      const data = await dispatch(
        singleProduct && isOpenUpdate
          ? updateProduct({ url, data: formdata })
          : createProduct({ url, data: formdata })
      );

      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      // console.log(data, "Data");
      if (data?.payload?.success) {
        dispatch(getAllGlobalProducts(`${process.env.REACT_APP_API}productFilter`));
        setIsOpen(false);
        setIsOpenUpdate(false);
        setIsProductDescription("");
        setIsProductkeyBenefit("");
        setIsProductdirectionForUse("");
        setIsProductSafetyInformation("");
        setIsProductotherInformation("");
        setProductBanner(null);
        setProductImages(null);
        setIsThumbnil(null);
        setMobileBanner(null);
      } 
    } catch (error) {
      console.error("Error submitting form:", error);
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.error ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
    }
  };

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (
      name === "price" ||
      name === "mrp" ||
      name === "unit" ||
      name === "time" ||
      name === "affiliate" ||
      name === "stock" ||
      name === "returnInDays" ||
      name === "afterTaxValue"
    ) {
      setIsData((prev) => ({
        ...prev,
        [name]: value.replace(/[^0-9]/gi, ""),
      }));
    } else
      setIsData((prev) => ({
        ...prev,
        [name]: value,
      }));

    if (
      name !== "price" &&
      name !== "mrp" &&
      name !== "unit" &&
      isData?.price !== "" &&
      isData?.mrp !== "" &&
      isData?.unit !== "" &&
      isData?.price > isData?.mrp
    ) {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "warning",
          msg: "Price should be less than or equal to MRP",
        })
      );
      setIsData((prev) => ({
        ...prev,
        price: isData?.mrp,
      }));
    }
  };

  const addPriceVariant = () => {
    const mrp = String(mrpInput).trim();
    const afterTaxValue = String(afterTaxValueInput).trim();
    const stock = String(stockInput).trim();

    if (mrp !== "" && afterTaxValue !== "" && stock !== "" && unit !== "") {
      setPriceVariant([...priceVariant, { mrp, afterTaxValue, stock, unit }]);
      setMrpInput("");
      setAfterTaxValueInput("");
      setStockInput("");
      setUnit("");
    }
  };

  const removePriceVariant = (index) => {
    const newPriceVariants = [...priceVariant];
    newPriceVariants.splice(index, 1);
    setPriceVariant(newPriceVariants);
  };

  const removeProductImage = (index) => {
    const newData = [...serverProductImage];
    newData.splice(index, 1);
    setServerProductImage(newData);
  };

  const removeProductBanner = (index) => {
    const newData = [...serverProductBanner];
    newData.splice(index, 1);
    setServerProductBanner(newData);
  };

  const removeMobileBanner = (index) => {
    const newData = [...serverMobileBanner];
    newData.splice(index, 1);
    setServerMobileBanner(newData);
  };

  return (
    <>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "70%", xl: "70%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <MDBox
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 , 10px",
            }}
          >
            <MDTypography variant="h6" py={0.9}>
              {isOpenUpdate ? `Update Product` : "Create Product"}
            </MDTypography>
          </Card>
          {Loading ? (
            <SkLoading />
          ) : (
            <MDBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              sx={({ palette: { dark, white, info } }) => ({
                justifyContent: "center",
                textAlign: "center",
                flexDirection: "column",
                gap: 3,
                width: "100%",
                flexWrap: "wrap",
                border: 1,
                borderColor: darkMode ? white.main : dark.main,
                borderRadius: 3,
                py: 3,
              })}
              component="form"
              role="form"
              onSubmit={handleSubmitCrateProduct}
            >
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Name <AstrieskIcon />
                </MDTypography>
                <MDInput
                  disabled={createUpdateLoading}
                  required={true}
                  type="text"
                  placeholder="Product Title"
                  fullWidth
                  name="name"
                  value={isData?.name}
                  onChange={handleForm}
                />
              </MDBox>

              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Description <AstrieskIcon />
                </MDTypography>
                <SkEditor
                  editorState={isProductDescription}
                  setEditorState={setIsProductDescription}
                  placeholder={"Product Description"}
                  isButton={true}
                  initialContent={
                    isOpenUpdate && singleProduct?.description ? singleProduct?.description : ""
                  }
                  // content={"description"}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">Product Key Benefit</MDTypography>
                <SkEditor
                  editorState={isProductkeyBenefit}
                  setEditorState={setIsProductkeyBenefit}
                  placeholder={"Product Key Benefit"}
                  isButton={true}
                  initialContent={
                    isOpenUpdate && singleProduct?.keyBenefit ? singleProduct?.keyBenefit : ""
                  }
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">Product Direction For Use</MDTypography>
                <SkEditor
                  editorState={isProductdirectionForUse}
                  setEditorState={setIsProductdirectionForUse}
                  placeholder={"Product Direction For Use"}
                  initialContent={
                    singleProduct?.directionForUse ? singleProduct?.directionForUse : ""
                  }
                  isButton={true}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">Product Safety Information</MDTypography>

                <SkEditor
                  editorState={isProductSafetyInformation}
                  setEditorState={setIsProductSafetyInformation}
                  placeholder={"Product Safety Information"}
                  initialContent={
                    singleProduct?.SafetyInformation ? singleProduct?.SafetyInformation : ""
                  }
                  isButton={true}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">Product Other Information</MDTypography>

                <SkEditor
                  editorState={isProductotherInformation}
                  setEditorState={setIsProductotherInformation}
                  placeholder={"Product other Information Server"}
                  initialContent={
                    singleProduct?.otherInformation ? singleProduct?.otherInformation : ""
                  }
                  isButton={true}
                />
              </MDBox>
              <MDBox
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDBox
                  lineHeight={1}
                  gap={3}
                  width={"100%"}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                ></MDBox>

                <MDBox
                  lineHeight={1}
                  gap={3}
                  width={"100%"}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 3,
                  }}
                >
                  <MDBox>
                    <MDTypography variant="h6" id="demo-controlled-radio-buttons-group">
                      {" "}
                      Select Multiple Variant
                    </MDTypography>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      row
                      value={multipleVariant}
                      onChange={(e) => setMultipleVariant(e.target.value)}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio sx={{ color: "info.main" }} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"No"}
                        control={<Radio sx={{ color: "info.main" }} />}
                        label="No"
                      />
                    </RadioGroup>
                  </MDBox>
                  {multipleVariant === "No" ? (
                    <MDBox
                      lineHeight={1}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      <MDBox
                        lineHeight={1}
                        gap={3}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <MDTypography variant="h6">
                          MRP <AstrieskIcon />
                        </MDTypography>
                        <MDInput
                          disabled={createUpdateLoading}
                          required={true}
                          type="text"
                          placeholder="MRP"
                          fullWidth
                          name="mrp"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyRupee
                                  fontSize="small"
                                  sx={({ palette: { white, info } }) => ({
                                    color: darkMode ? white.main : info.main,
                                  })}
                                />
                              </InputAdornment>
                            ),
                          }}
                          value={isData?.mrp}
                          onChange={handleForm}
                        />
                      </MDBox>
                      <MDBox
                        lineHeight={1}
                        gap={3}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "row",
                        }}
                      >
                        <MDBox
                          lineHeight={1}
                          gap={3}
                          width={"50%"}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <MDTypography variant="h6">
                            After Tax Value Price{" "}
                            <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
                              ( Incl Tax )
                            </span>{" "}
                            <AstrieskIcon />
                          </MDTypography>
                          <MDInput
                            disabled={createUpdateLoading}
                            required={true}
                            type="text"
                            placeholder="After Tex Value"
                            fullWidth
                            name="afterTaxValue"
                            value={isData?.afterTaxValue}
                            onChange={handleForm}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CurrencyRupee
                                    fontSize="small"
                                    sx={({ palette: { white, info } }) => ({
                                      color: darkMode ? white.main : info.main,
                                    })}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            helperText={
                              isData?.afterTaxValue &&
                              isData?.mrp &&
                              parseInt(isData?.mrp) < parseInt(isData?.afterTaxValue) && (
                                <MDTypography variant="body1" color="error">
                                  Price should be less than or equal to MRP
                                </MDTypography>
                              )
                            }
                          />
                        </MDBox>
                        <MDBox
                          lineHeight={1}
                          gap={3}
                          width={"50%"}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <MDTypography variant="h6">
                           Per Unit <AstrieskIcon />
                          </MDTypography>
                          <TextField
                            select
                            size="larger"
                            // placeholder="Unit"
                            fullWidth
                            value={unit}
                            InputProps={{
                              style: {
                                height: "2.75rem",
                              },
                            }}
                            defaultValue="kg"
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            {unitOption?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        lineHeight={1}
                        gap={3}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <MDTypography variant="h6">
                          Product Stock <AstrieskIcon />
                        </MDTypography>
                        <MDInput
                          disabled={createUpdateLoading}
                          required={true}
                          type="text"
                          placeholder="Stock"
                          fullWidth
                          name="stock"
                          value={isData?.stock}
                          onChange={handleForm}
                        />
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox
                      lineHeight={1}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        border: 1,
                        borderColor: "info.main",
                        p: 1.5,
                        borderRadius: 4,
                      }}
                    >
                      <MDBox
                        sx={{
                          width: "100%",
                          margin: "auto",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {/* <Paper elevation={3} style={{ padding: 16 }}> */}
                        <MDTypography variant="h5" gutterBottom>
                          Add Price Variant List
                        </MDTypography>

                        <MDTypography variant="h6">
                          Product MRP <AstrieskIcon />
                        </MDTypography>

                        <MDInput
                          disabled={createUpdateLoading}
                          required={false}
                          type="text"
                          placeholder="MRP"
                          fullWidth
                          name="mrpInput"
                          value={mrpInput}
                          onChange={(e) => {
                            const { value } = e.target;
                            setMrpInput(
                              isNaN(parseInt(value[value.length - 1]))
                                ? value.length === 1
                                  ? ""
                                  : value.slice(0, value.length - 1)
                                : value
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyRupee
                                  fontSize="small"
                                  sx={({ palette: { white, info } }) => ({
                                    color: darkMode ? white.main : info.main,
                                  })}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <MDTypography variant="h6">
                          After Tax Value Price{" "}
                          <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
                            ( Incl Tax )
                          </span>{" "}
                          <AstrieskIcon />
                        </MDTypography>

                        <MDInput
                          disabled={createUpdateLoading}
                          type="text"
                          placeholder="After Tax Value"
                          fullWidth
                          name="afterTaxValueInput"
                          value={afterTaxValueInput}
                          onChange={(e) => {
                            const { value } = e.target;
                            setAfterTaxValueInput(
                              isNaN(parseInt(value[value.length - 1]))
                                ? value.length === 1
                                  ? ""
                                  : value.slice(0, value.length - 1)
                                : value
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyRupee
                                  fontSize="small"
                                  sx={({ palette: { white, info } }) => ({
                                    color: darkMode ? white.main : info.main,
                                  })}
                                />
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            mrpInput &&
                            afterTaxValueInput &&
                            parseInt(mrpInput) < parseInt(afterTaxValueInput) && (
                              <MDTypography variant="h6" color="error">
                                Price should be less than or equal to MRP
                              </MDTypography>
                            )
                          }
                        />
                        <MDBox
                          lineHeight={1}
                          gap={1}
                          width={"100%"}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <MDTypography variant="h6">
                           Per Unit <AstrieskIcon />
                          </MDTypography>
                          <TextField
                            select
                            InputProps={{
                              style: {
                                height: "2.75rem",
                              },
                            }}
                            // placeholder="Unit"
                            fullWidth
                            value={unit}
                            defaultValue="kg"
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            {unitOption?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </MDBox>
                        <MDTypography variant="h6">
                          product stock <AstrieskIcon />
                        </MDTypography>
                        <MDInput
                          disabled={createUpdateLoading}
                          type="text"
                          placeholder="stock "
                          fullWidth
                          name="stockInput"
                          value={stockInput}
                          onChange={(e) => {
                            const { value } = e.target;
                            setStockInput(
                              isNaN(parseInt(value[value.length - 1]))
                                ? value.length === 1
                                  ? ""
                                  : value.slice(0, value.length - 1)
                                : value
                            );
                          }}
                        />
                        <MDButton
                          variant="contained"
                          color="primary"
                          onClick={addPriceVariant}
                          style={{ marginBottom: 16 }}
                        >
                          <Add /> Add Price Variant
                        </MDButton>
                        <List my={0.5}>
                          {priceVariant.map((variant, index) => (
                            <ListItem key={index}>
                              <MDTypography
                                variant="h6"
                                border={0.5}
                                borderColor={"info.main"}
                                borderRadius={2}
                                px={2}
                                py={1}
                              >
                                {`MRP: ${SkPrice(variant.mrp || 0)}, After Tax Value: ${SkPrice(
                                  variant.afterTaxValue || 0
                                )}, stock: ${variant.stock || 0}, unit: ${variant.unit || "-"}`}
                              </MDTypography>

                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => removePriceVariant(index)}
                                >
                                  <Delete
                                    sx={({ palette: { white, info } }) => ({
                                      color: darkMode ? white.main : info.main,
                                    })}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                        {/* </Paper> */}
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Return In Days <AstrieskIcon />
                </MDTypography>
                <MDInput
                  disabled={createUpdateLoading}
                  required={true}
                  type="text"
                  placeholder="Return In Days"
                  fullWidth
                  name="returnInDays"
                  value={isData?.returnInDays}
                  onChange={handleForm}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Parent Categories
                  <AstrieskIcon />
                </MDTypography>

                <ApnaSelect2
                  required={false}
                  data={category2 || []}
                  value={isPCategoriesId}
                  origin="Parent Categories "
                  onChange={(e) => setIsPCategoriesId(e.target.value)}
                  name="isPCategoriesId"
                  nameKey={"name"}
                  valueKey={"_id"}
                  simpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Sub Categories
                  <AstrieskIcon />
                </MDTypography>

                <ApnaSelect2
                  disabled={!isPCategoriesId}
                  required={true}
                  data={subCategory2 || []}
                  value={isSubCategoriesId}
                  // value={isData?.categoryId}
                  origin="Sub Category"
                  // onChange={handleForm}
                  onChange={(e) => setIsSubCategoriesId(e.target.value)}
                  name="categoryId"
                  nameKey={"name"}
                  valueKey={"_id"}
                  simpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select child Categories
                  <AstrieskIcon />
                </MDTypography>

                <ApnaSelect2
                  required={true}
                  data={childCategory || []}
                  value={isData?.categoryId}
                  origin="Child Category"
                  onChange={handleForm}
                  name="categoryId"
                  nameKey={"name"}
                  valueKey={"_id"}
                  simpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Brand <AstrieskIcon />
                </MDTypography>
                <ApnaSelect2
                  data={allBrands}
                  required={true}
                  value={isData?.brandId}
                  origin="Brand"
                  onChange={handleForm}
                  name="brandId"
                  nameKey={"name"}
                  valueKey={"_id"}
                  isSimpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Tax <AstrieskIcon />
                </MDTypography>
                <ApnaSelect2
                  data={allTax}
                  required={true}
                  value={isData?.taxId}
                  origin="Tax Slab"
                  onChange={handleForm}
                  name="taxId"
                  nameKey={"taxPercent"}
                  valueKey={"_id"}
                  isSimpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Disease
                  {/* <AstrieskIcon /> */}
                </MDTypography>

                <ApnaSelect2
                  // required={true}
                  data={disease}
                  value={isData?.disease}
                  origin="disease"
                  onChange={handleForm}
                  name="disease"
                  nameKey={"diceaseName"}
                  valueKey={"_id"}
                  simpleArray={false}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={3}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Select Dosh
                  {/* <AstrieskIcon /> */}
                </MDTypography>
                <ApnaSelect2
                  data={[
                    { _id: "VATADOSHA", name: " Vata Dosha" },
                    { _id: "KAPHADOSHA", name: " Kapha Dosha" },
                    { _id: "PITTADOSHA", name: " Pitta Dosha" },
                  ]}
                  // required={true}
                  value={isData?.dosha}
                  origin="dosha"
                  onChange={handleForm}
                  name="dosha"
                  nameKey={"name"}
                  valueKey={"_id"}
                  isSimpleArray={false}
                />
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Thumbnail Image <AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( image size - 266 × 244.8 px )
                  </MDTypography>
                </MDTypography>
                <ImagePicker
                  required={singleProduct ? false : true}
                  name="thumbnail"
                  multiple={false}
                  images={isThumbnil}
                  setImages={setIsThumbnil}
                />

                {!isThumbnil && serverThumbnail && (
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        height: "50px",
                        margin: "0 0.5rem",
                      }}
                    >
                      <img
                        className="Image"
                        style={{ width: "100%", height: "100%" }}
                        src={`${process.env.REACT_APP_URI}/${serverThumbnail}`}
                      />
                    </span>
                    <span
                      className="cross"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setServerThumbnail(null);
                      }}
                    >
                      <Cancel
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white?.main : dark.main,
                        })}
                      />
                    </span>
                  </div>
                )}
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Images <AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( image size - 474 × 320 px )
                  </MDTypography>
                </MDTypography>
                <ImagePicker
                  required={singleProduct ? false : true}
                  name="images"
                  multiple={true}
                  images={productImages}
                  setImages={setProductImages}
                  allow="true"
                />
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {!productImages &&
                    serverProductImage &&
                    serverProductImage?.length &&
                    serverProductImage.map((value, i) => (
                      <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                        <span
                          style={{
                            display: "inline-block",
                            width: "50px",
                            height: "50px",
                            margin: "0 0.5rem",
                          }}
                        >
                          <img
                            className="Image"
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            src={`${process.env.REACT_APP_URI}/${value}`}
                          />
                        </span>
                        <span
                          className="cross"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            removeProductImage(i);
                          }}
                        >
                          <Cancel
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white?.main : dark.main,
                            })}
                          />
                        </span>
                      </div>
                    ))}
                </div>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Banners <AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( image size - 1391.2 × 417.11 px )
                  </MDTypography>
                </MDTypography>
                <ImagePicker
                  required={singleProduct ? false : true}
                  name="images"
                  multiple={true}
                  images={productBanner}
                  setImages={setProductBanner}
                />
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {!productBanner &&
                    serverProductBanner &&
                    serverProductBanner?.length &&
                    serverProductBanner.map((value, i) => (
                      <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                        <span
                          style={{
                            display: "inline-block",
                            width: "50px",
                            height: "50px",
                            margin: "0 0.5rem",
                          }}
                        >
                          <img
                            className="Image"
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            src={`${process.env.REACT_APP_URI}/${value}`}
                          />
                        </span>
                        <span
                          className="cross"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            removeProductBanner(i);
                          }}
                        >
                          <Cancel
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white?.main : dark.main,
                            })}
                          />
                        </span>
                      </div>
                    ))}
                </div>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                lineHeight={1}
                gap={1}
                width={"90%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Product Mobile Banners <AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( image size - 359 × 121 px )
                  </MDTypography>
                </MDTypography>
                <ImagePicker
                  required={singleProduct ? false : true}
                  name="images"
                  multiple={true}
                  images={mobileBanner}
                  setImages={setMobileBanner}
                />
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {!mobileBanner &&
                    serverMobileBanner &&
                    serverMobileBanner?.length &&
                    serverMobileBanner.map((value, i) => (
                      <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                        <span
                          style={{
                            display: "inline-block",
                            width: "50px",
                            height: "50px",
                            margin: "0 0.5rem",
                          }}
                        >
                          <img
                            className="Image"
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            src={`${process.env.REACT_APP_URI}/${value}`}
                          />
                        </span>
                        <span
                          className="cross"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            removeMobileBanner(i);
                          }}
                        >
                          <Cancel
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white?.main : dark.main,
                            })}
                          />
                        </span>
                      </div>
                    ))}
                </div>
              </MDBox>

              <MDBox
                sx={{
                  width: "90%",
                  justifyContent: "flex-end",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                {" "}
                <MDButton
                  disabled={createUpdateLoading}
                  color={"info"}
                  verdant={"gradient"}
                  type={"submit"}
                >
                  {createUpdateLoading ? (
                    <CircularProgress size={20} />
                  ) : isOpenUpdate ? (
                    `Update Product`
                  ) : (
                    ` Create Product`
                  )}
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </SkModal>
    </>
  );
};

export default CreateForm;

CreateForm.propTypes = {
  ecom: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsSwitch: PropTypes.func,
  isSwitch: PropTypes.any,
  productId: PropTypes.string,
  updateProductModal: PropTypes.bool,
  setUpdateProductModal: PropTypes.bool,
  isOpenUpdate: PropTypes.bool,
  setIsOpenUpdate: PropTypes.bool,
};
