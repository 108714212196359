import { Close, Delete, Edit, Input, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
import { getAllTax, getSingleTax } from "redux/festures/taxSlice";
import { getAllDisease } from "redux/festures/diseaseSlice";
import { getSingleDisease } from "redux/festures/diseaseSlice";
import { updateDisease } from "redux/festures/diseaseSlice";
import { getAllAppointment } from "redux/festures/AppointmentSlice";
import { getSingleAppointment } from "redux/festures/AppointmentSlice";
import AppointmentDetailPage from "./AppointmentDetailPage";
// import { getAllAppoinment } from "redux/festures/AppoinmentSlice";
// import SingleDisease from "./SingleDisease";

// import Form from "./Form";
// import SingleBlog from "./SingleBlog";

const sortFilter = [
  {
    _id: "",
    name: "None",
  },
  {
    _id: "NEWTOOLD",
    name: "New to old",
  },
  {
    _id: "OLDTONEW",
    name: "Old to new",
  },
];
const columns = {
  AllDisease: [
    { Header: "S.No", accessor: "no" },
    { Header: "name", accessor: "name" },
    { Header: "Email/Mobile", accessor: "Email/Mobile" },
    { Header: "age /gender", accessor: "age /gender" },
    { Header: "date", accessor: "date" },
    { Header: "view", accessor: "view" },
    // { Header: "delete", accessor: "delete" },
    // { Header: "action", accessor: "action" },
  ],
};
const Appoinment = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { disease, singleDisease } = useSelector((state) => ({
    ...state?.isDisease,
  }));
  const [isPages, setIsPages] = useState(1);
  const { Loading, Appointment, page } = useSelector((state) => ({ ...state?.isAppointment }));
  // console.log(Appointment, "AppoinmentAppoinment");

  useEffect(() => {
    dispatch(
      getAllAppointment(
        `${process.env.REACT_APP_API}/getAllAboutPateint?page=${isPages}&sort=${sort}`
      )
    );
  }, [isPages, sort]);

  useEffect(() => {
    if (Appointment && Appointment.length > 0) {
      const temprows =
        Appointment &&
        Appointment?.at(0) &&
        Appointment?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          name: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                //   gap: 0.5,
                flexDirection: "column",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
              }}
            >
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "220px",
                  lineHeight: "18px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value?.name || "-"}
              </MDTypography>
            </MDBox>
          ),
          "Email/Mobile": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "220px",
                    lineHeight: "18px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* Name: {value?.name || "-"} */}
                  Mobile No: {value?.mobile || "-"}
                </MDTypography>

                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={12}
                >
                  Email Id: {value?.email || "-"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          "age /gender": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "220px",
                    lineHeight: "18px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Age: {value?.age || "-"} year
                </MDTypography>

                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={12}
                >
                  Gender: {value?.gender || "-"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          date: (
            <MDBox flexDirection="column">
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "220px",
                  lineHeight: "18px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Appoinment Date: {formattedDateServer(new Date(value?.date)) || ""}
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                fontSize={12}
              >
                Appoinment Time: {value?.time || ""}
              </MDTypography>
            </MDBox>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                dispatch(getSingleAppointment(value));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),

          delete: (
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateDisease({
                      url: `${process.env.REACT_APP_API}/dicease/disableDicease/${value?._id}`,
                    })
                  ).then((data) => {
                    console.log(data, "ghvhvhvhbvh");
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(getAllDisease(`${process.env.REACT_APP_API}/dicease/getAllDicease`));
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),
          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getSingleTax(`/getTaxById/${value?._id}/${admin}`));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [Appointment, isPages, sort]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
              Appoinment&apos;s Table{" "}
              </MDTypography>
            </MDBox>
            <MDBox py={1} display="flex" justifyContent="end">
              <MDBox width="250px" display="flex" flexDirection="column" padding="2%">
                <MDTypography variant="button">Short By</MDTypography>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {sortFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>
            <MDBox py={2}>
              {Loading ? (
                <SkLoading />
              ) : Appointment && Appointment.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns?.AllDisease,
                    rows: rowsData,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  isPages={Appointment && Appointment.length}
                  noEndBorder
                  canSearch={false}
                  showTotalEntries={false}
                  pagination={false}
                  isPagination={false}
                />
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}

              <MDBox
                sx={{
                  mt: 5,
                  // minHeigth: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack spacing={2} direction={"row"}>
                  <Pagination
                    sx={({ palette: { dark, white, info } }) => ({
                      "&.MuiPaginationItem-text": {
                        color: darkMode ? white.main : dark.main,
                      },
                      "&	.MuiPaginationItem-icon": {
                        color: darkMode ? white.main : dark.main,
                      },
                      "&		.MuiPaginationItem-textInfo": {
                        color: darkMode ? white.main : dark.main,
                      },
                    })}
                    color="info"
                    variant="text"
                    count={page}
                    page={isPages}
                    onChange={(e, value) => setIsPages(value)}
                  />
                </Stack>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        {/* <Form isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} /> */}
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height="auto"
        maxHeight="90vh"
        padding={3}
        overflowY={true}
      >
        <AppointmentDetailPage/>
      </SkModal>
    </>
  );
};

export default Appoinment;
