import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { Card } from "@mui/material";
import { useMaterialUIController } from "context";
export default function CCategoriesView() {
  const { isCategories } = useSelector((state) => ({
    ...state.isCategory,
  }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <div>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Category Details
            <MDTypography variant="body1" component="span" fontSize={11}>
              &nbsp; ( {isCategories?.categories?.name || "-"} | {isCategories?.type} )
            </MDTypography>
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 2,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          })}
        >
          <MDBox
            sx={{
              height: 200,
              width: 200,
              borderRadius: "50%",
              border: 3,
              borderColor: "primary.main",
            }}
          >
            <img
              src={`${process.env.REACT_APP_URI}/${isCategories?.categories?.icon}`}
              style={{ height: "100%", width: "100%", borderRadius: "50%", objectFit: "cover" }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../../assets/images/no123.jpeg");
              }}
            />
          </MDBox>
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              // border: 0.5,
              // borderColor: darkMode ? white.main : dark.main,
              // borderRadius: 3,

              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1.5,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Category Name :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {isCategories?.categories?.name || "-"}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Show in Home :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {isCategories?.categories?.showInHome ? (
                  <MDBadge badgeContent="Yes" color="success" variant="gradient" size="lg" />
                ) : (
                  <MDBadge badgeContent="No" color="error" variant="gradient" size="lg" />
                )}
              </MDTypography>
            </MDBox>
          </MDBox>
          {isCategories?.categories?.banner && isCategories?.categories?.banner?.length
            ? isCategories?.categories?.banner?.map((ele, i) => (
                <MDBox
                  key={i}
                  sx={{
                    height: "40vh",
                    width: "100%",
                  }}
                >
                  {ele?.type === "IMAGE" ? (
                    <>
                      <MDTypography variant="h6">Category Banner :</MDTypography>
                      <img
                        src={`${process.env.REACT_APP_URI}/${ele?.url}`}
                        style={{ height: "90%", width: "100%", objectFit: "cover" }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../../assets/images/no123.jpeg");
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <MDTypography variant="h6">Category Video</MDTypography>
                      <video width="320" height="240" controls>
                        <source src={`${process.env.REACT_APP_URI}/${ele?.url}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  )}
                </MDBox>
              ))
            : null}
        </MDBox>
      </MDBox>
    </div>
  );
}
