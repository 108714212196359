import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useDispatch, useSelector } from "react-redux";
import ImagePicker from "components/ApnaUploader";
import { Cancel, Delete, RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import MDInput from "components/MDInput";
import AstrieskIcon from "components/AstrieskIcon";
import Permitionselect from "components/Permitionselect";
import {
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from "@mui/material";

import { handleAlert } from "redux/festures/alertSlice";
import ApnaSelect from "components/ApnaSelect/ApnaSelect";

import { createAdmin } from "redux/festures/userSlice";
import { Stack } from "immutable";
import { getAllUsers } from "redux/festures/userSlice";
import { getAllPermissions } from "redux/festures/PermissionsSlice";
import { updateUser } from "redux/festures/userSlice";
import MDBadge from "components/MDBadge";

const Createform = ({ isOpenUpdate, updateData, setIsOpenUpdate, setIsOpen, userType }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { permissions } = useSelector((data) => ({ ...data?.isPermition }));

  // console.log(permissions, "permissions");

  const [isCouponsIcon, setIsCouponsIcon] = useState("");
  const [isShow, setIsShow] = useState("");

  const [isCategory, setIsCategory] = useState([]);
  const [isProducts, setIsProducts] = useState([]);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();

  const { Loading, singleCoupons } = useSelector((data) => ({ ...data?.isCoupons }));
  const { createUpdateLoading, singleUsers } = useSelector((data) => ({ ...data?.isUsers }));
  const { AllProducts } = useSelector((state) => ({ ...state.isProducts }));

  const [categorys, setCategorys] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesShown, setCategoriesShown] = useState({});
  // console.log(categoriesShown);
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    permissions: Object.fromEntries(
      Array.isArray(permissions) ? permissions.map((key) => [key, []]) : []
    ),
  });
  // console.log(singleUsers);
  // setCategorys(singleUsers?.permissions && Object.keys(singleUsers?.permissions))
  useEffect(() => {
    if (singleUsers && isOpenUpdate) {
      setUserData((prev) => ({
        ...prev,
        fullName: singleUsers?.fullName || "",
        password: "",
        email: singleUsers?.email || "",
        permissions: Object.fromEntries(
          Array.isArray(singleUsers?.permissions)
            ? singleUsers.permissions.map((categories) => {
                const category = categories?.categorys || "";
                const subcategories = categories?.subcategories || [];
                return [category, subcategories];
              })
            : []
        ),
      }));
      setCategoriesShown(singleUsers?.permissions);
    } else {
      setUserData({
        fullName: "",
        email: "",
        password: "",
        permissions: {},
      });
      setCategoriesShown({});
    }
  }, [singleUsers, isOpenUpdate]);

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "fullName") {
      setUserData((prev) => ({
        ...prev,
        fullName: value.replace(/[^a-z ]/gi, ""),
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("fullName", userData?.fullName || "");
    if (!isOpenUpdate) {
      formdata.append("email", userData?.email || "");
    }

    {
      userData?.password && formdata.append("password", userData?.password || "");
    }

    const permission = {};

    categories.forEach((category) => {
      permission[category?.categorys] = category?.subcategories || "";
    });

    formdata.append("permissions", JSON.stringify(permission));

    if (singleUsers && isOpenUpdate) {
      dispatch(
        updateUser({
          url: `${process.env.REACT_APP_API}updateSubAdmin/${singleUsers?._id}/${admin}`,
          data: formdata,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);

        dispatch(getAllUsers(`/getAllSubadmin/${admin}`));
      });
    } else {
      dispatch(
        createAdmin({
          url: `${process.env.REACT_APP_API}/createSubadmin/${admin}`,
          data: formdata,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);

        dispatch(getAllUsers(`/getAllSubadmin/${admin}`));
      });
    }
  };

  const addCategory = () => {
    setCategories([...categories, { categorys, subcategories }]);
    setSubcategories([]);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  const handleSubcategoryChange = (e) => {
    // console.log(e.target.value)
    if (e.target.value.includes("NONE")) {
      setSubcategories(["NONE"]);
    } else {
      setSubcategories(e.target.value.filter((ele) => ele !== "NONE"));
    }
  };

  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 5,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update Admin's ` : " Create Admin's"}
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          //   sx={{
          //
          //   }}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 3,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Full Name <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Full Name"
              fullWidth
              name="fullName"
              disabled={createUpdateLoading}
              value={userData?.fullName}
              onChange={handleForm}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Email <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="email"
              placeholder="Email"
              fullWidth
              name="email"
              disabled={createUpdateLoading || isOpenUpdate}
              value={userData?.email}
              onChange={handleForm}
            />
          </MDBox>

          <MDBox width="90%">
            <MDTypography variant="h6" align="center" gutterBottom>
              Select Permissions
            </MDTypography>

            <FormControl fullWidth margin="normal">
              <MDTypography variant="h6" align="left">
                Permissions
              </MDTypography>
              <Select
                sx={({ palette: { dark, white, info } }) => ({
                  width: "100%",
                  height: "3rem",
                  color: darkMode ? white?.main : dark?.main,
                  bgcolor: "transparent",
                  "& .MuiSelect-icon": {
                    color: darkMode ? white?.main : dark?.main,
                    display: "block !important",
                    fontSize: "1.5rem !important",
                  },
                })}
                id="category"
                value={categorys}
                onChange={(e) => {
                  setCategorys(e.target.value);
                }}
                // onChange={(selectedPermissions) => {
                //   setCategorys((prevUserData) => ({
                //     ...prevUserData,
                //     permissions: selectedPermissions && selectedPermissions?.length ? selectedPermissions[selectedPermissions?.length - 1] === "ALL" || selectedPermissions[selectedPermissions?.length - 1] === "NONE" ? selectedPermissions[selectedPermissions?.length - 1] === "ALL" ? ["ALL"] : ["NONE"] : selectedPermissions?.includes("ALL") ? [...selectedPermissions].toSpliced(selectedPermissions?.indexOf("ALL"), 1) : selectedPermissions?.includes("NONE") ? selectedPermissions.toSpliced(selectedPermissions?.indexOf("NONE"), 1) : selectedPermissions : [],
                //   }))}}
              >
                {Object.keys(permissions).map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <MDTypography variant="h6" align="left" gutterBottom>
                Select Sub Permissions
              </MDTypography>
              <Select
                id="subcategories"
                multiple
                value={subcategories}
                onChange={handleSubcategoryChange}
                sx={({ palette: { dark, white, info } }) => ({
                  width: "100%",
                  height: "3rem",
                  color: darkMode ? white?.main : dark?.main,
                  bgcolor: "transparent",
                  "&	.MuiSelect-icon": {
                    color: darkMode ? white?.main : dark?.main,
                    display: "block !important",
                    fontSize: "1.5rem !important",
                  },
                })}
              >
                {categorys.length
                  ? Object.values(permissions[categorys]).map((subcat, index) => (
                      <MenuItem key={subcat} value={subcat}>
                        {/* <Checkbox checked={subcat>1} />
                        <ListItemText primary={subcat} /> */}

                        {subcat}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>

            <MDButton
              variant="contained"
              color="primary"
              disabled={subcategories?.length > 0 && categorys?.length > 0 ? false : true}
              onClick={addCategory}
            >
              Add Permissions
            </MDButton>

            <List sx={{ mt: 1, alignItems: "center" }}>
              {categories.map((item, index) => (
                <ListItem key={index}>
                  <MDTypography variant="h6" align="center" gutterBottom>
                    {`${item.categorys} :-`}
                    {/* - ${item.subcategories} */}
                  </MDTypography>
                  {item.subcategories?.map((ele, i) => (
                    <MDBadge
                      key={i}
                      badgeContent={`${ele}`}
                      color={
                        (ele === "CREATE" && "success") ||
                        (ele === "UPDATE" && "warning") ||
                        (ele === "DISABLE" && "error") ||
                        (ele === "VIEWS" && "primary") ||
                        (ele === "NONE" && "dark")
                      }
                      variant="gradient"
                      size="md"
                    />
                  ))}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeCategory(index)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            {categories?.length > 0 ? null : (
              <List sx={{ mt: 1, alignItems: "center" }}>
                {categoriesShown &&
                  Object.entries(categoriesShown)?.map(([key, value]) => (
                    <ListItem key={key}>
                      <MDTypography variant="h6" align="center" gutterBottom>
                        {`${key} :-`}
                        {/* - ${item.subcategories} */}
                      </MDTypography>
                      {value?.map((ele, i) => (
                        <MDBadge
                          key={i}
                          badgeContent={`${ele}`}
                          color={
                            (ele === "CREATE" && "success") ||
                            (ele === "UPDATE" && "warning") ||
                            (ele === "DISABLE" && "error") ||
                            (ele === "VIEWS" && "primary") ||
                            (ele === "NONE" && "dark")
                          }
                          variant="gradient"
                          size="md"
                        />
                      ))}
                    </ListItem>
                  ))}
              </List>
            )}
          </MDBox>

          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">Password</MDTypography>
            <MDInput
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordVisibility ? (
                      <RemoveRedEye
                        sx={{ cursor: "pointer" }}
                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                        color="white"
                      />
                    ) : (
                      <VisibilityOff
                        sx={{ cursor: "pointer" }}
                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                        color="white"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              // required={true}
              type={passwordVisibility ? "text" : "password"}
              placeholder="Password"
              fullWidth
              disabled={createUpdateLoading}
              name="password"
              value={userData?.password}
              onChange={handleForm}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton
              disabled={createUpdateLoading}
              color={"info"}
              verdant={"gradient"}
              type={"submit"}
            >
              {createUpdateLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : isOpenUpdate ? (
                `Update Admin`
              ) : (
                ` Create Admin`
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default Createform;
Createform.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  updateData: PropTypes.any,
  userType: PropTypes.any,
  // isSelect: PropTypes.bool,
};
