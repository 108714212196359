import {
  Delete,
  Edit,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Visibility,
  Input,
} from "@mui/icons-material";
import {
  Card,
  Icon,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import PropTypes from "prop-types";
import SubCategoriesTable from "./SubCategoriesTable";
import { getSubCategory } from "redux/festures/categorySlice";
import { handleCategories } from "redux/festures/categorySlice";

export default function ParentCategoriesTable({
  row,
  index,

  rIndex,
  setRIndex,
  isOpenForm,
  setIsOpenForm,
  isOpenView,
  setIsOpenView,
  isOpenUpdate,
  setIsOpenUpdate,
  handleBinSwitch,
  handleChangeShowInHome,
  handleBinSwitchSub,
  handleChangeShowInHomeSub,
  handleBinSwitchChild,
  handleChangeShowInHomeChild,
}) {
  // const { row } = props;
  // console.log(row, "row DAta");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();

  const [rOpen, setROpen] = React.useState(false);
  const [rSubIndex, setRSubIndex] = React.useState(0);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const {
    Loading,
    category,
    subCategory,
    IsLoading,
    subCategoryData,
    createUpdateLoading,
    isPages,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));

  //   console.log("subCategory", subCategory);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setROpen(!rOpen);
              setRIndex(index);

              dispatch(
                getSubCategory({
                  url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${row?._id}&disable=false`,
                })
              );
            }}
          >
            {rOpen && index === rIndex ? (
              <KeyboardArrowUp
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            ) : (
              <KeyboardArrowDown
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          {index + 1}
        </TableCell>

        <TableCell
          component="th"
          align="flex-start"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 0.5,
              width: "100%",
            }}
          >
            <MDBox sx={{ height: 40, width: 40 }}>
              <img
                src={`${process.env.REACT_APP_URI}/${row?.icon}`}
                alt={"img"}
                onError={(e) => {
                  (e.onError = null), (e.target.src = require("../../../assets/images/no123.jpeg"));
                }}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            </MDBox>{" "}
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
              // wordBreak=" break-all"
              style={{
                maxWidth: "250px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row?.name}
              {/* {console.log(row?.name, "row?.name")} */}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell
          component="th"
          align="center"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          <IconButton aria-label="action-edit">
            <Switch
              value={row?.showInHome}
              checked={row?.showInHome}
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : info.main,
              })}
              onChange={(e) => {
                e.stopPropagation();
                handleChangeShowInHome(row?._id);
                // e.preventDefault();
                // handleChangeSwitch(value?._id);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </IconButton>
        </TableCell>
        {isAdmin?.permissions?.all ? (
          <TableCell
            component="th"
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <MDBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              zIndex={10}
              sx={{ width: "20%" }}
            >
              <IconButton
                aria-label="action_edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenView((prev) => ({ ...prev, parentCategories: true }));
                  dispatch(
                    handleCategories({
                      categories: row,
                      type: "Parent Categories",
                    })
                  );
                  // setViewData(value);
                  // setIsOpenView(true);
                }}
              >
                <Visibility
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </MDBox>
          </TableCell>
        ) : (
          isAdmin?.permissions?.category.includes("VIEWS") && (
            <TableCell
              component="th"
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <MDBox
                display="flex"
                alignItems="center"
                lineHeight={1}
                zIndex={10}
                sx={{ width: "20%" }}
              >
                <IconButton
                  aria-label="action_edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenView((prev) => ({ ...prev, parentCategories: true }));
                    dispatch(
                      handleCategories({
                        categories: row,
                        type: "Parent Categories",
                      })
                    );
                    //   setViewData(value);
                    //   setIsOpenView(true);
                  }}
                >
                  <Visibility
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                </IconButton>
              </MDBox>
            </TableCell>
          )
        )}
        {isAdmin?.permissions?.all ? (
          <TableCell
            align="center"
            component="th"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <Tooltip title={row?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_delete"
                // disabled={row?.disable}
                onClick={() => {
                  handleBinSwitch(row?._id);
                  //   setIsOpenDialog((Preview) => ({
                  //     ...Preview,
                  //     open: true,
                  //     isId: row?._id,
                  //   }));
                }}
              >
                {row?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("DISABLE") && (
            <TableCell
              align="center"
              component="th"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <Tooltip title={row?.disable ? "move to Active" : "delete"}>
                <IconButton
                  aria-label="action_delete"
                  // disabled={row?.disable}
                  onClick={() => {
                    handleBinSwitch(row?._id);
                    //   setIsOpenDialog((Preview) => ({
                    //     ...Preview,
                    //     open: true,
                    //     isId: row?._id,
                    //   }));
                  }}
                >
                  {row?.disable ? (
                    <Input
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? info.main : dark.main,
                      })}
                    />
                  ) : (
                    <Delete
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? info.main : dark.main,
                      })}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
          )
        )}

        {isAdmin?.permissions?.all ? (
          <TableCell
            component="th"
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenForm((prev) => ({ ...prev, parentCategories: true }));
                setIsOpenUpdate((prev) => ({ ...prev, parentCategories: true }));
                dispatch(
                  handleCategories({
                    categories: row,
                    type: "Parent Categories",
                  })
                );
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("UPDATE") && (
            <TableCell
              component="th"
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpenForm((prev) => ({ ...prev, parentCategories: true }));
                  setIsOpenUpdate((prev) => ({ ...prev, parentCategories: true }));
                  dispatch(
                    handleCategories({
                      categories: row,
                      type: "Parent Categories",
                    })
                  );
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={rOpen && index === rIndex} timeout="auto" unmountOnExit>
            <MDBox sx={{ textAlign: "center", width: "100%", backgroundColor: "#585858", py: 2 }}>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color="info"
                textDecoration="underline"
              >
                Sub Category details
              </MDTypography>
            </MDBox>
            <Table
              aria-label="collapsible table"
              sx={{ backgroundColor: "#585858", width: "100%" }}
            >
              <TableHead
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <TableRow
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                > */}
                {/* {" "}
                  <TableCell
                    align="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    
                    })}
                  >
                    Sub Category details
                  </TableCell> */}

                {/* </TableRow> */}
              </TableHead>

              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                      width: 40,
                    })}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    align="flex-start"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  >
                    Categories Details
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  >
                    Show in Home
                  </TableCell> */}
                  {isAdmin?.permissions?.all ? (
                    <TableCell
                      align="center"
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? white.main : dark.main,
                      })}
                    >
                      View
                    </TableCell>
                  ) : (
                    isAdmin?.permissions?.range?.includes("VIEWS") && (
                      <TableCell
                        align="center"
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white.main : dark.main,
                        })}
                      >
                        View
                      </TableCell>
                    )
                  )}
                  {isAdmin?.permissions?.all ? (
                    <TableCell
                      align="center"
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? white.main : dark.main,
                      })}
                    >
                      Delete
                    </TableCell>
                  ) : (
                    isAdmin?.permissions?.range?.includes("DISABLE") && (
                      <TableCell
                        align="center"
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white.main : dark.main,
                        })}
                      >
                        Delete
                      </TableCell>
                    )
                  )}
                  {isAdmin?.permissions?.all ? (
                    <TableCell
                      align="center"
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? white.main : dark.main,
                      })}
                    >
                      Update
                    </TableCell>
                  ) : (
                    isAdmin?.permissions?.range?.includes("UPDATE") && (
                      <TableCell
                        align="center"
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white.main : dark.main,
                        })}
                      >
                        Update
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {IsLoading ? (
                  <SkLoading />
                ) : subCategory && subCategory?.length > 0 ? (
                  subCategory?.map((value, i) => (
                    <SubCategoriesTable
                      key={i}
                      row={value}
                      index={i}
                      setRIndex={setRSubIndex}
                      rIndex={rSubIndex}
                      isOpenForm={isOpenForm}
                      setIsOpenForm={setIsOpenForm}
                      isOpenView={isOpenView}
                      setIsOpenView={setIsOpenView}
                      handleBinSwitch={handleBinSwitch}
                      handleChangeShowInHome={handleChangeShowInHome}
                      isOpenUpdate={isOpenUpdate}
                      setIsOpenUpdate={setIsOpenUpdate}
                      handleBinSwitchSub={handleBinSwitchSub}
                      handleChangeShowInHomeSub={handleChangeShowInHomeSub}
                      handleBinSwitchChild={handleBinSwitchChild}
                      handleChangeShowInHomeChild={handleChangeShowInHomeChild}
                    />
                  ))
                ) : (
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    my={1}
                    py={2}
                  >
                    No Data Found !
                  </MDTypography>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

ParentCategoriesTable.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,

  setRIndex: PropTypes.func,
  rIndex: PropTypes.number,
  isOpenForm: PropTypes.object,
  setIsOpenForm: PropTypes.func,
  isOpenView: PropTypes.object,
  setIsOpenView: PropTypes.func,

  isOpenUpdate: PropTypes.object,
  setIsOpenUpdate: PropTypes.func,
  handleBinSwitch: PropTypes.func,
  handleChangeShowInHome: PropTypes.func,
  handleBinSwitchSub: PropTypes.func,
  handleChangeShowInHomeSub: PropTypes.func,
  handleChangeShowInHomeChild: PropTypes.func,
  handleBinSwitchChild: PropTypes.func,
};
