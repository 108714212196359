import React from "react";
import { Card, Chip, Divider } from "@mui/material";
import { formattedDateServer } from "Utils/dateFunc";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
// import { sanitize } from "dompurify";
import { useSelector } from "react-redux";

const AppointmentDetailPage = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { SingleAppointment, Loading } = useSelector((state) => ({ ...state?.isAppointment }));
  console.log(SingleAppointment, "sssss");
  return (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      sx={{
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 , 10px",
        }}
      >
        <MDTypography variant="h6" py={0.9}>
          Single Appointment
        </MDTypography>
      </Card>
      {Loading ? (
        <SkLoading />
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info }, breakpoints }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            [breakpoints.up("xs")]: {
              py: 3,
            },
            [breakpoints.up("sm")]: {
              py: 3,
            },
            [breakpoints.up("md")]: {
              p: 3,
            },
            [breakpoints.up("lg")]: {
              p: 3,
            },
          })}
        >
          {/* <MDBox
          sx={{
            height: 200,
            width: 200,
            borderRadius: "50%",
            border: 3,
            borderColor: "primary.main",
          }}
        >
          <img
            src={`${process.env.REACT_APP_URI}/${SingleAppointment?.icon}`}
            style={{ height: "100%", width: "100%", borderRadius: "50%", objectFit: "cover" }}
            onError={(e) => {
              e.onerror = null;
              e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
            }}
          />
        </MDBox> */}
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              // border: 0.5,
              // borderColor: darkMode ? white.main : dark.main,
              // borderRadius: 3,
              px: 2,
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1.5,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Patient Name :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {SingleAppointment?.name}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Email :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {SingleAppointment?.email}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Age :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {SingleAppointment?.age}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Mobile No. :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {SingleAppointment?.mobile}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Date :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {formattedDateServer(new Date(SingleAppointment?.date)) || ""}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Time :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {SingleAppointment?.time}{" "}
              </MDTypography>
            </MDBox>

            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Create At :</MDTypography>
              <MDTypography variant="h6">
                {formattedDateServer(new Date(SingleAppointment?.createdAt))}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Last Update :</MDTypography>
              <MDTypography variant="h6">
                {formattedDateServer(new Date(SingleAppointment?.updatedAt))}
              </MDTypography>
            </MDBox>

            {SingleAppointment?.disease && (
              <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Message"
                  color="success"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>
            )}

            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                //   justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: SingleAppointment?.disease }}
              ></MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
};

export default AppointmentDetailPage;
