import { Box, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getPromotionCommission } from "redux/festures/partnerSlice";
import { getPincodeCommission } from "redux/festures/partnerSlice";
import { updateCommission } from "redux/festures/partnerSlice";

const Commission = () => {
  const [controller] = useMaterialUIController();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const { promotion, commissionPincode } = useSelector((state) => ({
    ...state.isPartner,
  }));
  const [promoPercentage, setPromoPercentage] = useState(null);
  const [pincodePercentage, setPincodePercentage] = useState(null);
  const { darkMode } = controller;

  useEffect(() => {
    dispatch(getPromotionCommission(`${process.env.REACT_APP_API}/getassuredPromotion`));
    dispatch(getPincodeCommission(`${process.env.REACT_APP_API}/getComissionPincodeWise`));
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Commission
              </MDTypography>
            </MDBox>
            <MDBox py={3} px={3}>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"100%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Assured Promotion :- {promotion?.partnerCommissionPerMonth}%
                </MDTypography>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <MDInput
                    required={true}
                    type="text"
                    placeholder="Percentage"
                    name="name"
                    value={promoPercentage}
                    onChange={(e) => setPromoPercentage(e.target.value.replace(/[^0-9]/gi, ""))}
                  />
                  {isAdmin?.permissions?.all && (
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      disabled={promoPercentage ? false : true}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          updateCommission({
                            url: `${process.env.REACT_APP_API}/updateAssuredPromotionIncome`,
                            data: {
                              assuredPromotionId: promotion?._id,
                              partnerCommissionPerMonth: promoPercentage,
                            },
                          })
                        ).then((res) => {
                          setPromoPercentage("");
                          dispatch(
                            handleAlert({
                              isOpen: true,
                              type: `${res?.payload?.success ? "success" : "error"}`,
                              msg: res?.payload?.message,
                            })
                          );
                          if (res?.payload) {
                            dispatch(
                              getPromotionCommission(
                                `${process.env.REACT_APP_API}/getassuredPromotion`
                              )
                            );
                          }
                        });
                      }}
                    >
                      Update
                    </MDButton>
                  )}
                </Box>
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"100%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                <MDTypography variant="h6">
                  Comission Pincode Wise :- {commissionPincode?.comissionPercentage}%
                </MDTypography>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <MDInput
                    required={true}
                    type="text"
                    placeholder="Percentage"
                    name="name"
                    value={pincodePercentage}
                    onChange={(e) => setPincodePercentage(e.target.value.replace(/[^0-9]/gi, ""))}
                  />
                  {isAdmin?.permissions?.all && (
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      disabled={pincodePercentage ? false : true}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          updateCommission({
                            url: `${process.env.REACT_APP_API}/updateCommisionPincodeWise`,
                            data: {
                              comissionPincodeId: commissionPincode?._id,
                              comissionPercentage: pincodePercentage,
                            },
                          })
                        ).then((res) => {
                          setPincodePercentage("");
                          dispatch(
                            handleAlert({
                              isOpen: true,
                              type: `${res?.payload?.success ? "success" : "error"}`,
                              msg: res?.payload?.message,
                            })
                          );
                          if (res?.payload) {
                            dispatch(
                              getPincodeCommission(
                                `${process.env.REACT_APP_API}/getComissionPincodeWise`
                              )
                            );
                          }
                        });
                      }}
                    >
                      Update
                    </MDButton>
                  )}
                </Box>
              </MDBox>
              {/* <MDBox
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox> */}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default Commission;
