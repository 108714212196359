import { Close, Delete, Edit, Input, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Avatar,
  AvatarGroup,
  MenuItem,
  Select,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { updateCoupons } from "redux/festures/couponsSlice";
import { getSingleCoupons } from "redux/festures/couponsSlice";
import { getAllCoupons } from "redux/festures/couponsSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import SingleCoupons from "./SingleCoupons";
import Form from "./Form";
import SingleCoupons from "./SingleCoupons";
import { getGlobalCoupons } from "redux/festures/couponsSlice";
import { getCategory } from "redux/festures/categorySlice";
import MDBadge from "components/MDBadge";
import ApnaSelect from "components/ApnaSelect/ApnaSelect";

// import Form from "./Form";
// import SingleBlog from "./SingleBlog";
// const columns = {
//   AllCoupons: [
// { Header: "S.No", accessor: "no" },
// { Header: " details", accessor: " details" },
// { Header: "date", accessor: "date" },
// { Header: "discount - % / qty", accessor: "discount - % / qty" },
// {
//   Header: "min order price / max discount price",
//   accessor: "min order price / max discount price",
// },

// {
//   Header: "category",
//   accessor: "category",
// },
//     // { Header: "disable", accessor: "disable" },
//     { Header: "delete", accessor: "delete" },
//     { Header: "view", accessor: "view" },
//     { Header: "action", accessor: "action" },
//   ],
// };

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: " details", accessor: " details" },
    { Header: "date", accessor: "date" },
    { Header: "discount - % / qty", accessor: "discount - % / qty" },
    {
      Header: "min order price / max discount price",
      accessor: "min order price / max discount price",
    },

    {
      Header: "category",
      accessor: "category",
    },

    isAdmin?.permissions?.all
      ? {
          Header: "delete",
          accessor: "delete",
        }
      : isAdmin?.permissions?.coupons?.includes("DISABLE") && {
          Header: "delete",
          accessor: "delete",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.coupons?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "action",
          accessor: "action",
        }
      : isAdmin?.permissions?.coupons?.includes("UPDATE") && {
          Header: "action",
          accessor: "action",
        },
  ].filter(Boolean);
}

const disableFilter = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: false,
    name: "Active",
  },
  {
    _id: true,
    name: "Delete",
  },
];

const Coupons = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const { Loading, coupons, isPages, singleCoupons } = useSelector((data) => ({
    ...data?.isCoupons,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const [istype, setIsType] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { category } = useSelector((state) => ({
    ...state.isCategory,
  }));

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const [isDisable, setIsDisable] = useState(false);
  const [pagess, setPagess] = useState(1);

  useEffect(() => {
    dispatch(
      getAllCoupons(
        `${process.env.REACT_APP_API}/getAllCoupons?disable=${
          isDisable === 0 ? "" : isDisable === false ? false : true || ""
        }&page=${isPages || ""}`
      )
    );
  }, [isDisable, pagess]);

  // useEffect(() => {
  //   dispatch(getCategory(`${process.env.REACT_APP_APII}/getAllCategoryByAdmin/${admin}`));
  // }, []);
  useEffect(() => {
    if (coupons && coupons.length > 0) {
      const temprows =
        coupons &&
        coupons?.at(0) &&
        coupons?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          " details": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.image}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 1,
                  flexDirection: "column",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                >
                  Name:
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "250px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.couponName || "N/A"}
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={12.5}
                  style={{
                    maxWidth: "250px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  CouponCode:
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={12.5}
                >
                  {value?.couponCode || "N/A"}
                </MDTypography>
                {/* <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={10}
                >
                  ID: {value?._id || "N/A"}
                </MDTypography> */}
              </MDBox>
            </MDBox>
          ),
          date: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                Str At : {formattedDateServer(new Date(value?.startDate))}
              </MDTypography>
              <MDTypography fontSize={12.5}>
                Ex At : {formattedDateServer(new Date(value?.endDate))}
              </MDTypography>
              {/* <MDTypography fontSize={12.5}>Validity : {value?.validity} Days</MDTypography> */}
            </MDBox>
          ),
          "discount - % / qty": (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {value?.discount || "N/A"}% / {value?.couponQuantity || "N/A"} .Qty
              </MDTypography>
              {/* <MDTypography fontSize={12.5}>Qty : {value?.couponQuantity || "N/A"}</MDTypography> */}
            </MDBox>
          ),
          "min order price / max discount price": (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>min order :</MDTypography>
              <MDTypography fontSize={12.5}>
                {SkPrice(value?.minimumOrderValue) || "N/A"}
              </MDTypography>
              <MDTypography fontSize={12.5}>max discount :</MDTypography>
              <MDTypography fontSize={12.5}>
                {SkPrice(value?.maximumDiscount) || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          // type: (
          //   <MDBadge
          //     badgeContent={value?.type == "ECOM_CATEGORY" ? "ECOM CATEGORY" : "SERVICE CATEGORY"}
          //     color={value?.type == "ECOM_CATEGORY" ? "success" : "error"}
          //     variant="gradient"
          //     size="lg"
          //   />
          // ),
          // <MDTypography fontSize={12.5}>{value?.type || "N/A"}</MDTypography>

          category: (
            <MDBox>
              {/* {value?.type == "ECOM_CATEGORY" ? ( */}
              <AvatarGroup
                max={3}
                sx={{
                  "& .MuiAvatarGroup-root": {
                    width: 24,
                    height: 24,
                    fontSize: 15,
                    borderColor: "white.main",
                  },
                  "& .MuiAvatar-root": {
                    width: 24,
                    height: 24,
                    fontSize: 15,
                    borderColor: "white.main",
                  },
                }}
              >
                {value?.categoryId?.map((ele, i) => (
                  //  console.log(ele?.banner?.at(0)?.url,"value"),
                  <Avatar
                    key={i}
                    src={`${process.env.REACT_APP_URI}/${ele?.icon}`}
                    sx={{
                      width: 24,
                      height: 24,
                      "& MuiAvatarGroup-root .MuiAvatar-root": {
                        width: 24,
                        height: 24,
                      },
                    }}
                  />
                ))}
              </AvatarGroup>
            </MDBox>
          ),

          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                dispatch(getSingleCoupons(value));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
          disable: (
            <Switch
              value={value?.disable}
              checked={value?.disable}
              color={"info"}
              onChange={(e) => {
                dispatch(
                  updateCoupons({
                    url: `${process.env.REACT_APP_API}/disableCoupon/${value?._id}/${admin}`,
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(getGlobalCoupons(`${process.env.REACT_APP_API}/getAllCoupons`));
                  }
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),

          delete: (
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateCoupons({
                      url: `${process.env.REACT_APP_API}/disableCoupon/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    // console.log(data,"ghvhvhvhbvh")
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(getGlobalCoupons(`${process.env.REACT_APP_API}/getAllCoupons`));
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
            // <IconButton
            //   disabled={value?.disable === true}
            //   aria-label="action_edit"
            //
            // >
            //   <Delete
            //     sx={({ palette: { dark, white, info } }) => ({
            //       color: darkMode ? info.main : dark.main,
            //     })}
            //   />
            // </IconButton>
          ),
          // showInHome: (
          //   <Switch
          //     value={value?.showInHome}
          //     checked={value?.showInHome}
          //     color={"info"}
          //     onChange={(e) => {
          //       // handleSwitchControl(value?._id);
          //       // setIsSwitch(!isSwitch);
          //       dispatch(
          //         updateCoupons({
          //           url: `${process.env.REACT_APP_API}/ShowInHomeBlog/${value?._id}/${admin}`,
          //         })
          //       ).then((data) => {
          //         dispatch(
          //           handleAlert({
          //             isOpen: true,
          //             type: `${data?.payload?.success ? "success" : "error"}`,
          //             msg: data?.payload?.message,
          //           })
          //         );
          //         if (data?.payload?.success) {
          //           dispatch(getAllcoupons(`/getAllBlog?adminId=${admin}`));
          //         }
          //       });
          //     }}
          //     inputProps={{ "aria-label": "controlled" }}
          //   />
          // ),
          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getSingleCoupons(value));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [coupons]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Coupon&apos;s Table{" "}
              </MDTypography>
              <MDBox display="flex" gap="5px">
                {isAdmin?.permissions?.all ? (
                  <MDButton
                    variant="gradient"
                    disabled={Loading}
                    color="dark"
                    sx={({ palette: { dark, white, info, success } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      // "&:hover": {
                      //   color: success.main,
                      //   backgroundColor: success.main,
                      // },
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(false);
                      // setIsType("ECOM_CATEGORY");
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create New Coupon
                  </MDButton>
                ) : (
                  isAdmin?.permissions?.coupons.includes("CREATE") && (
                    <MDButton
                      variant="gradient"
                      disabled={Loading}
                      color="dark"
                      sx={({ palette: { dark, white, info, success } }) => ({
                        color: white.main,
                        backgroundColor: dark.main,
                        // "&:hover": {
                        //   color: success.main,
                        //   backgroundColor: success.main,
                        // },
                      })}
                      onClick={() => {
                        setIsOpen(true);
                        setIsOpenUpdate(false);
                        // setIsType("ECOM_CATEGORY");
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp; Create New Coupon
                    </MDButton>
                  )
                )}
              </MDBox>
            </MDBox>

            <MDBox py={3} display="flex" justifyContent="end">
              <MDBox width="23%" display="flex" flexDirection="column" padding="2%">
                <MDTypography variant="button">Filter By Visibility</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isDisable}
                  onChange={(e) => setIsDisable(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {disableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : coupons && coupons.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={coupons && coupons.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        // type={istype}

        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"88vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <Form
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          type={istype}
        />
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <SingleCoupons />
      </SkModal>
    </>
  );
};

export default Coupons;
