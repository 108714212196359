import React from "react";
import { Card, Chip, Divider } from "@mui/material";
import { formattedDateServer } from "Utils/dateFunc";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import { sanitize } from "dompurify";
import { useSelector } from "react-redux";

const QuestionDetailPage = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { singleQuestion, Loading } = useSelector((state) => ({ ...state?.isQuestion }));
  console.log(singleQuestion);

  return (
    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      sx={{
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 , 10px",
        }}
      >
        <MDTypography variant="h6" py={0.9}>
          Single Question Details
        </MDTypography>
      </Card>
      {Loading ? (
        <SkLoading />
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info }, breakpoints }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            [breakpoints.up("xs")]: {
              py: 3,
            },
            [breakpoints.up("sm")]: {
              py: 3,
            },
            [breakpoints.up("md")]: {
              p: 3,
            },
            [breakpoints.up("lg")]: {
              p: 3,
            },
          })}
        >
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              px: 2,
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1.5,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
                  <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Question"
                  color="warning"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>
           
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
               <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {singleQuestion?.question}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
                  <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Answer 1"
                  color="primary"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>

            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
               <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {singleQuestion?.kaphaDoshaAnswer}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
                  <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Answer 2"
                  color="primary"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>

            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
               <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {singleQuestion?.pittaDoshaAnswer}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
                  <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Answer 3"
                  color="primary"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>

            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
               <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {singleQuestion?.vataDoshaAnswer}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Create At :</MDTypography>
              <MDTypography variant="h6">
                {formattedDateServer(new Date(singleQuestion?.createdAt))}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Last Update :</MDTypography>
              <MDTypography variant="h6">
                {formattedDateServer(new Date(singleQuestion?.updatedAt))}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
};

export default QuestionDetailPage;
