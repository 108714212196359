import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, CircularProgress, MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import AstrieskIcon from "components/AstrieskIcon";
import MDButton from "components/MDButton";
import { handleAlert } from "redux/festures/alertSlice";
import { createRange } from "redux/festures/RangeSlice";
import { getAllGlobleRange } from "redux/festures/RangeSlice";
import { isSingleData } from "redux/festures/RangeSlice";
import { updateRange } from "redux/festures/RangeSlice";
import { camelToFlat } from "Utils/dateFunc";
import SkEditor from "components/SKeditor/SkEditor";
import ApnaSelect2 from "components/ApnaSelect";
import { getAllGlobalProducts } from "redux/festures/productSlice";
import ApnaSelect from "components/ApnaSelect/ApnaSelect";

const SingleRange = ({ setIsOpenView }) => {
  const { singleDoshaRange } = useSelector((state) => ({ ...state?.isRange }));
  const { AllProducts, Loading } = useSelector((state) => ({ ...state.isProducts }));
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [products, setProducts] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  // console.log(products, "products");
  // console.log(AllProducts, "AllProducts");
  // console.log(about, "about");
  useEffect(() => {
    dispatch(getAllGlobalProducts(`${process.env.REACT_APP_API}productFilter?disable=${false}`));
  }, []);
  useEffect(() => {
    if (singleDoshaRange) {
      const productsArray =
        singleDoshaRange.products && singleDoshaRange.products.length > 0
          ? singleDoshaRange.products.map((items) => items)
          : [];
      setProducts(productsArray);
      setAbout(singleDoshaRange?.aboutDisease);
    }
  }, [singleDoshaRange]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(singleDoshaRange, "singleDoshaRange");
    try {
      setIsLoading(false);
      const data = {
        aboutDisease: about,
        products: products,
        diseaseRangeId: singleDoshaRange?.diseaseRangeId,
      };
      // console.log(data, "data");
      dispatch(
        updateRange({
          url: `${process.env.REACT_APP_API}/updateDiseaseRelated/${singleDoshaRange?._id}`,
          data: data,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data.payload.message,
          })
        );
        if (data?.payload.success) {
          dispatch(getAllGlobleRange(`${process.env.REACT_APP_API}/getAllDiseaseRange`));
          setIsOpenView(false);
        }
      });
    } catch (e) {
      setIsLoading(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: e?.response?.data?.message,
        })
      );
    }
  };

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "min" || name === "max") {
      setData((prev) => ({
        ...prev,
        [name]: isNaN(parseInt(value[value.length - 1]))
          ? value.length === 1
            ? ""
            : value.slice(0, value.length - 1)
          : value,
      }));
    } else
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
  };
  return (
    <>
      <MDBox
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {`Update ${camelToFlat(singleDoshaRange?.doshaId?.name)}`}
          </MDTypography>
        </Card>

        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            flexWrap: "wrap",
            border: 1,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            py: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleSubmitForm}
        >
          {/* <MDBox
            lineHeight={1}
            gap={2}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Name <AstrieskIcon />
              {/* <span style={{ fontSize: 12.5 }}>(min value should be greater then 30)</span> */}
          {/* </MDTypography>
            <MDInput
              disabled={isLoading}
              required={true}
              type="text"
              placeholder="Enter Name"
              fullWidth
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </MDBox> */}
          <MDBox
            lineHeight={1}
            gap={2}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <MDTypography variant="h6">
              Select Products <AstrieskIcon />
            </MDTypography>

            <ApnaSelect
              disabled={Loading}
              required={true}
              data={AllProducts}
              state={products}
              label="Products"
              placeholder="Products"
              // setState={handleForm}
              setState={setProducts}
              name="category"
              simpleArray={true}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={2}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <MDTypography variant="h6">
              About <AstrieskIcon />
              {/* (min value should be less then 100) */}
            </MDTypography>
            {/* <MDInput
              disabled={isLoading}
              required={true}
              type="text"
              placeholder="Enter Max Value"
              fullWidth
              name="max"
              value={data?.max}
              onChange={handleForm}
            /> */}

            <SkEditor
              required={true}
              disabled={isLoading}
              editorState={about}
              setEditorState={setAbout}
              placeholder={"About"}
              initialContent={singleDoshaRange ? singleDoshaRange?.aboutDisease : ""}
              isButton={true}
              // content={"Blog Content"}
            />
          </MDBox>

          <MDBox
            sx={{
              width: "90%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton disabled={isLoading} color={"info"} verdant={"gradient"} type={"submit"}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                `Update ${camelToFlat(singleDoshaRange?.doshaId?.name)}`
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default SingleRange;
SingleRange.propTypes = {
  setIsOpenView: PropTypes.func,
  isOpenView: PropTypes.bool,
};
