import axios from "axios";
import http from "Utils/api2"

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllCoupons = createAsyncThunk("getAllCoupons", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleCoupons = createAsyncThunk("getSingleCoupons", async (data) => {
  try {
    return data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalCoupons = createAsyncThunk("getGlobalCoupons", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateCoupons = createAsyncThunk("updateCoupons", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteCoupons = createAsyncThunk("deleteCoupons", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createCoupons = createAsyncThunk("createCoupons", async ({ url, data}) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const couponSlice = createSlice({
  name: "couponSlice",
  initialState: {
    coupons: null,
    singleCoupons: null,
    isPages:null,
    Loading: false,
    createupdateLoading:false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoupons.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.Loading = false;
        state.coupons = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getGlobalCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload?.data;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createCoupons.pending, (state, action) => {
        state.createupdateLoading = true;
      })
      .addCase(createCoupons.fulfilled, (state, action) => {
        state.createupdateLoading = false;
      })
      .addCase(updateCoupons.rejected, (state, action) => {
        state.createupdateLoading = false;
      })
      .addCase(updateCoupons.pending, (state, action) => {
        state.createupdateLoading = true;
      })
      .addCase(updateCoupons.fulfilled, (state, action) => {
        state.createupdateLoading = false;
      })
      .addCase(createCoupons.rejected, (state, action) => {
        state.createupdateLoading = false;
      })
      .addCase(getSingleCoupons.fulfilled, (state, action) => {
        state.singleCoupons = action.payload
      })
     
  },
});
export default couponSlice.reducer;
