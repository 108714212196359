import {
  CheckCircle,
  Close,
  Edit,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  Logout,
  PersonAdd,
  Settings,
  TaskAlt,
  TextFields,
  Unpublished,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Select,
  MenuItem,
  Menu,
  Tooltip,
  ListItemIcon,
  Divider,
  InputAdornment,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Autocomplete,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "redux/festures/orderSlice";
import { formattedDateServer } from "Utils/dateFunc";
import SingleOrderDetails from "./SingleOrder";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import { updateOrderDetails } from "redux/festures/orderSlice";
import { handleAlert } from "redux/festures/alertSlice";
import { useLocation } from "react-router-dom";
import { getSingleOrders } from "redux/festures/orderSlice";
import { SkPrice } from "Utils/dateFunc";

import { getGlobalOrders } from "redux/festures/orderSlice";
import { getAllState } from "redux/festures/locationSlice";
import { getAllCity } from "redux/festures/locationSlice";
import axios from "axios";

// const columns = {
//   allOrders: [
//     { Header: "S.No", accessor: "no", width: "20px" },
//     { Header: "Order Details", accessor: "order details" },
//     { Header: "Order Status", accessor: "order status" },
//     { Header: "Payment Method ", accessor: "payment method" },
//     { Header: "Order Total", accessor: "order total" },
//     { Header: "Place Order", accessor: "place Order" },
//     // { Header: "Assign Partner", accessor: "assignPartner" },
//     { Header: "Update", accessor: "update" },
//     { Header: "View", accessor: "view" },
//     // { Header: "action", accessor: "action" },
//   ],
// };

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no", width: "20px" },
    { Header: "Order Details", accessor: "order details" },
    { Header: "Order Status", accessor: "order status" },
    { Header: "Payment Method ", accessor: "payment method" },
    { Header: "Order Total", accessor: "order total" },
    { Header: "Place Order", accessor: "place Order" },

    // isAdmin?.permissions?.all
    //   ? {
    //       Header: "Update",
    //       accessor: "Update",
    //     }
    //   : isAdmin?.permissions?.orders?.includes("UPDATE") && {
    //       Header: "Update",
    //       accessor: "Update",
    //     },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
          textAlign: "center",
        }
      : isAdmin?.permissions?.orders?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },

    // isAdmin?.permissions?.orders?.includes("VIEWS") && {
    //   Header: "view",
    //   accessor: "view",
    // },
  ].filter(Boolean);
}

const mapStatusByName = {
  PENDING: "Pending",
  ORDERED: "Ordered",
  ACCEPTED: "Accepted",

  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  OUTOFDELIVERY: "Out For Delivery",
  DELIVERED: "Delivered",
  RETURNREQUEST: "Return Request",
  RETURNED: "Returned",
  // RETURN_REQUEST_APPROVED: "Return Request Approved",
  CANCELLED: "Cancelled",
  SHIPPED: "Shipped",
};

//Static Filters

const dateFilter = [
  {
    _id: 0,
    name: "All Products",
  },
  {
    _id: "today",
    name: "Today",
  },
  {
    _id: "week",
    name: "This Week",
  },
  {
    _id: "month",
    name: "This Month",
  },
  {
    _id: "year",
    name: "This Year",
  },
  // {
  //   _id: 'manually',
  //   name: 'Manually'
  // },
];

const sortByPrice = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: "high_to_low",
    name: "High To Low",
  },
  {
    _id: "low_to_high",
    name: "Low To High",
  },
];

const filterByStatusService = [
  {
    _id: 0,
    name: "Choose Status",
  },
  {
    _id: "PENDING",
    name: "PENDING",
  },
  {
    _id: "ORDERED",
    name: "ORDERED",
  },
  {
    _id: "ACCEPTED",
    name: "ACCEPTED",
  },
  {
    _id: "ONTHEWAY",
    name: "ONTHEWAY",
  },
  {
    _id: "WORKING",
    name: "WORKING",
  },
  {
    _id: "COMPLETED",
    name: "COMPLETED",
  },
  {
    _id: "CANCELLED",
    name: "CANCELLED",
  },
];
const filterByStatusEcomm = [
  {
    _id: 0,
    name: "Choose Status",
  },
  {
    _id: "PENDING",
    name: "Pending",
  },
  {
    _id: "ORDERED",
    name: "Ordered",
  },
  {
    _id: "OUT_OF_DELIVERY",
    name: "Out For Delivery",
  },
  {
    _id: "DELIVERED",
    name: "Delivered",
  },
  {
    _id: "RETURNREQUEST",
    name: "Return Request",
  },
  {
    _id: "RETURNED",
    name: "Returned",
  },
  {
    _id: "RETURN_REQUEST_APPROVED",
    name: "Return Request Approved",
  },
  {
    _id: "CANCELLED",
    name: "Cancelled",
  },
  {
    _id: "SHIPPED",
    name: "Shipped",
  },
  // {
  //   _id: "MULTI_STATUS",
  //   name: "Multi Status",
  // },
];

const filterByPaymentMethod = [
  {
    _id: 0,
    name: "Choose Payment Method",
  },
  {
    _id: "COD",
    name: "Cash On Delivery",
  },
  {
    _id: "ONLINE",
    name: "Online Transaction",
  },
];

const Orders = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [ecom, setEcom] = useState(false);
  const { pathname } = useLocation();
  const [filterCollapse, setFilterCollapse] = useState(false);
  const [createOrderModal, setCreateOrderModal] = useState(false);

  const [viewOrderId, setViewOrderId] = useState(null);
  const [viewProductModal, setViewProductModal] = useState(false);
  const [assignPartnerModal, setAssignPartnerModal] = useState(false);
  const [pagess, setPagess] = useState(1);

  const { Loading, AllOrders, isPages } = useSelector((data) => ({
    ...data?.isOrders,
  }));

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const isFindStatus = (isObject) => {
    // console.log(isObject, "isObject");
    let isStatusData = null;
    isObject?.product &&
      isObject?.product?.some((order) => {
        // console.log(order?.status, isObject?.product?.at(0)?.status, "gbhnjvhefbnverfhbene");
        if (order?.status === isObject?.product?.at(0)?.status) {
          isStatusData = isObject?.product?.at(0)?.status;
        } else {
          isStatusData = "Multiple Status";
        }
      });
    return isStatusData;
  };

  const [isOrderDetails, setIsOrderDetails] = useState(null);

  const [filter, setFilter] = useState(0);
  const [status, setStatus] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [stateName, setStateName] = useState("");

  const [cityName, setCityName] = useState("");

  const [fullName, setFullName] = useState("");
  const [pincode, setPincode] = useState("");
  // console.log(pincode, "pincode");
  const [shippingChargeId, setShippingChargeId] = useState("");
  const [userId, setUserId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [awb, setAwb] = useState("");

  useEffect(() => {
    dispatch(
      getAllOrders(
        `${process.env.REACT_APP_API}order/filterOrder?orderId=${orderId || ""}&userId=${
          userId || ""
        }&shippingChargeId=${shippingChargeId || ""}&paymentStatus=${
          paymentStatus || ""
        }&fullName=${fullName || ""}&cityName=${
          cityName || ""
        }&stateName=${stateName}&pincode=${pincode || ""}&status=${status || ""}&filter=${
          filter || ""
        }&page=${pagess}`
      )
    );
  }, [
    orderId,
    userId,
    shippingChargeId,
    paymentStatus,
    stateName,
    cityName,
    fullName,
    pincode,
    filter,
    status,
    pagess,
  ]);

  const [loading, setLoading] = useState(false);

  const [stateData, setStateData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [cityPinCode, setCityPinCode] = useState(null);
  const [stateId, setStateId] = useState(null);

  const isFetchState = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/getAllStateByCountryId?countryId=${"101"}`
      );
      setStateData(res?.data?.data);
      setLoading(false);
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const isFetchCity = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/getAllCityByStateId?stateId=${stateId}`
      );
      setCityData(res?.data?.data);
      setLoading(false);

      return res?.data?.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const isFetchPinCode = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/getPincodeByCityName?cityName=${cityName}`
      );

      setCityPinCode(res?.data?.data);

      setLoading(false);
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    isFetchState();
  }, []);

  // console.log(stateName,"stateName")

  useEffect(() => {
    if (stateName) {
      isFetchCity();
    }
    if (cityName) {
      isFetchPinCode();
    }
  }, [stateName, cityName]);

  const isStatusUpdateFunction = (isStatus, all, awb) => {
    const status = { status: isStatus };
    if (awb === "") {
      return dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: `Please Enter AWB Tracking Number`,
        })
      );
    }
    // console.log(isStatus, "isStatus");
    dispatch(
      updateOrderDetails({
        url: `/AllProductStatusUpdate/${isOrderDetails?._id}/${admin}`,

        data: isStatus === "SHIPPED" ? { ...status, awb } : { ...status },
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: `${data?.payload?.message}`,
        })
      );
      if (data?.payload?.success) {
        dispatch(getGlobalOrders(`${process.env.REACT_APP_API}order/filterOrder`));
        {
          viewProductModal &&
            dispatch(
              getSingleOrders(
                `${process.env.REACT_APP_API}/getOrderByOrderId/${isOrderDetails?._id}/${admin}`
              )
            );
        }
      }
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [dialogopen, setDialogopen] = useState(false);

  const handleClick = ({ event }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenMenu = ({ event }) => {
    setOpenMenu(event.currentTarget);
  };

  // console.log(AllOrders,"AllOrders")
  useEffect(() => {
    if (AllOrders && AllOrders.length > 0) {
      const temprows =
        AllOrders &&
        AllOrders?.at(0) &&
        AllOrders?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium", width: 10 }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          // Update: (
          //   <>
          //     <Tooltip
          //       title={
          //         value?.product && value?.product?.length === 1
          //           ? value?.product?.at(0)?.status || "N/A"
          //           : isFindStatus(value) || "N/A"
          //       }
          //     >
          //       <IconButton
          //         aria-controls="notification-menu"
          //         disabled={
          //           value?.product?.at(0)?.status === "PENDING" ||
          //           value?.product?.at(0)?.status === "CANCELLED" ||
          //           value?.product?.at(0)?.status === "DELIVERED" ||
          //           value?.product?.at(0)?.status === "RETURNREQUEST" ||
          //           value?.product?.at(0)?.status === "MULTI_STATUS" ||
          //           value?.product?.at(0)?.status === "RETURNED"
          //         }
          //         aria-haspopup="true"
          //         component="a"
          //         target="_blank"
          //         rel="noreferrer"
          //         variant="gradient"
          //         color="info"
          //         size="small"
          //         circular
          //         onClick={(e) => {
          //           handleOpenMenu({ event: e });
          //           setIsOrderDetails(value);
          //         }}
          //       >
          //         <MDBadge
          //           badgeContent="Update Status"
          //           color="primary"
          //           variant="gradient"
          //           size="lg"
          //         />
          //       </IconButton>
          //     </Tooltip>
          //   </>
          // ),
          "order details": (
            <MDBox
              sx={{
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
              }}
            >
              <MDBox sx={{ width: 45, height: 45, borderRadius: "50%" }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.userId?.image}`}
                  alt={value?.userId?.fullName}
                  title={value?.userId?.fullName}
                  style={{ width: "100%", height: "100%", objectFit: "auto", borderRadius: "50%" }}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",

                  justifyContent: "flex-start",
                }}
              >
                <MDTypography
                  sx={{ fontSize: 12, fontWeight: "medium" }}
                  variant="text"
                  style={{
                    maxWidth: "300px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* {console.log(value, "value")} */}
                  Name: {value?.userId?.fullName || "-"}
                </MDTypography>
                <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                  OrderId: {value?._id || "N/A"}
                </MDTypography>
                {/* {console.log(isFindStatus(value), "isFindStatus(value)")} */}
              </MDBox>
              {/* {console.log(isFindStatus(value), "isFindStatus(value)")} */}
            </MDBox>
          ),
          "order status":
            isAdmin?.permissions?.all || isAdmin?.permissions?.orders?.includes("UPDATE") ? (
              <Tooltip
                title={
                  value?.product && value?.product?.length === 1
                    ? value?.product?.at(0)?.status || "N/A"
                    : isFindStatus(value) || "N/A"
                }
              >
                <IconButton
                  disabled={
                    // value?.product?.at(0)?.status === "PENDING" ||
                    value?.product?.at(0)?.status === "CANCELLED" ||
                    value?.product?.at(0)?.status === "DELIVERED" ||
                    value?.product?.at(0)?.status === "RETURNREQUEST" ||
                    value?.product?.at(0)?.status === "RETURNREQUESTACCEPT" ||
                    value?.product?.at(0)?.status === "RETURNREQUESTCANCEL" ||
                    value?.product?.at(0)?.status === "MULTI_STATUS" ||
                    value?.product?.at(0)?.status === "RETURNED"
                  }
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setIsOrderDetails(value);
                  }}
                >
                  <MDBadge
                    // disabled={!isAdmin?.permissions?.all || !isAdmin?.permissions?.orders?.includes("UPDATE")}
                    badgeContent={
                      value?.product && value?.product?.length === 1
                        ? value?.product?.at(0)?.status || "N/A"
                        : isFindStatus(value) || "N/A"
                    }
                    color={
                      value?.product?.at(0)?.status && value?.product?.at(0)?.status.length === 1
                        ? (value?.product?.at(0)?.status === "DELIVERED" && "success") ||
                          (value?.product?.at(0)?.status === "CANCELLED" && "error") ||
                          (value?.product?.at(0)?.status === "OUTOFDELIVERY" && "info") ||
                          (value?.product?.at(0)?.status === "RETURNED" && "error") ||
                          (value?.product?.at(0)?.status === "RETURNREQUEST" && "warning") ||
                          (value?.product?.at(0)?.status === "ACCEPTED" && "success") ||
                          (value?.product?.at(0)?.status === "PENDING" && "warning") ||
                          (value?.product?.at(0)?.status === "ORDERED" && "primary") ||
                          (value?.product?.at(0)?.status === "SHIPPED" && "secondary")
                        : (isFindStatus(value) === "DELIVERED" && "success") ||
                          (isFindStatus(value) === "CANCELLED" && "error") ||
                          (isFindStatus(value) === "OUTOFDELIVERY" && "info") ||
                          (isFindStatus(value) === "RETURNED" && "error") ||
                          (isFindStatus(value) === "RETURNREQUEST" && "warning") ||
                          (isFindStatus(value) === "ACCEPTED" && "success") ||
                          (isFindStatus(value) === "PENDING" && "warning") ||
                          (isFindStatus(value) === "ORDERED" && "primary") ||
                          (isFindStatus(value) === "SHIPPED" && "secondary")
                    }
                    variant="gradient"
                    // sx={{ cursor: "pointer" }}
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <MDBadge
                disabled={true}
                // disabled={!isAdmin?.permissions?.all || !isAdmin?.permissions?.orders?.includes("UPDATE")}
                badgeContent={
                  value?.product && value?.product?.length === 1
                    ? value?.product?.at(0)?.status + `r` || "N/A"
                    : isFindStatus(value) || "N/A"
                }
                color={
                  value?.product?.at(0)?.status && value?.product?.at(0)?.status.length === 1
                    ? (value?.product?.at(0)?.status === "DELIVERED" && "success") ||
                      (value?.product?.at(0)?.status === "CANCELLED" && "error") ||
                      (value?.product?.at(0)?.status === "OUTOFDELIVERY" && "info") ||
                      (value?.product?.at(0)?.status === "RETURNED" && "error") ||
                      (value?.product?.at(0)?.status === "RETURNREQUEST" && "warning") ||
                      (value?.product?.at(0)?.status === "ACCEPTED" && "success") ||
                      (value?.product?.at(0)?.status === "PENDING" && "warning") ||
                      (value?.product?.at(0)?.status === "ORDERED" && "primary") ||
                      (value?.product?.at(0)?.status === "SHIPPED" && "secondary")
                    : (isFindStatus(value) === "DELIVERED" && "success") ||
                      (isFindStatus(value) === "CANCELLED" && "error") ||
                      (isFindStatus(value) === "OUTOFDELIVERY" && "info") ||
                      (isFindStatus(value) === "RETURNED" && "error") ||
                      (isFindStatus(value) === "RETURNREQUEST" && "warning") ||
                      (isFindStatus(value) === "ACCEPTED" && "success") ||
                      (isFindStatus(value) === "PENDING" && "warning") ||
                      (isFindStatus(value) === "ORDERED" && "primary") ||
                      (isFindStatus(value) === "SHIPPED" && "secondary")
                }
                variant="gradient"
                size="lg"
              />
            ),
          "payment method": (
            <MDBox
              sx={{
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // gap: 1,
              }}
            >
              {(value?.paymentStatus?.at(0) === "COD" && (
                <MDBadge badgeContent="COD" color="success" variant="gradient" size="lg" />
              )) ||
                (value?.paymentStatus?.at(0) === "ONLINE" && (
                  <MDBadge badgeContent="ONLINE" color="primary" variant="gradient" size="lg" />
                )) || (
                  <MDBadge
                    badgeContent={value?.paymentStatus || "N/A"}
                    color="error"
                    variant="gradient"
                    size="lg"
                  />
                )}
            </MDBox>
          ),
          "order total": (
            <MDTypography
              sx={{ fontSize: 12, fontWeight: "medium" }}
              variant="text"
              style={{
                maxWidth: "300px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.totalPrice ? SkPrice(value?.totalPrice) : "-"}
            </MDTypography>
          ),
          //   < MDBadge
          //     badgeContent={ value?.orderTotal }
          //     color="secondary"
          //     variant="gradient"
          //     size="lg"
          // />
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setViewProductModal(true);

                dispatch(
                  getSingleOrders(
                    `${process.env.REACT_APP_API}/getOrderByOrderId/${value?._id}/${admin}`
                  )
                );
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),

          "place Order": (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {formattedDateServer(new Date(value?.createdAt)) || "N/A"}
            </MDTypography>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [AllOrders, viewProductModal]);

  return (
    <>
      {/* {!ecom ? (
        <SkModal
          show={createOrderModal}
          unShow={setCreateOrderModal}
          width={{ sx: "100%", md: "55%", xl: "55%", sm: "100%" }}
          height={"auto"}
          padding={3}
          overflowY={true}
          maxHeight="90vh"
        >
          <CreateServiceOrder
            createOrderModal={createOrderModal}
            setCreateOrderModal={setCreateOrderModal}
          />
        </SkModal>
      ) : null} */}
      {/* <AssignPartner
        show={assignPartnerModal}
        unshow={setAssignPartnerModal}
        orderId={viewOrderId}
        setOrderId={setViewOrderId}
      /> */}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        {/* <MDBox py={5}>
          <Card id="delete-account">
            <MDBox p={1.5} display="flex" justifyContent="space-between" alignItems="center">
              <MDButton
                variant="gradient"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  // setIsOpen(true);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Users
              </MDButton>
              <MDButton
                variant="gradient"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  // setIsOpen2(true);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create subCategory
              </MDButton>
            </MDBox>{" "}
          </Card>
        </MDBox> */}
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Order &apos;s Table{" "}
              </MDTypography>
              {/* {ecom ? (
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: white.main,
                    backgroundColor: dark.main,
                    "&:hover": {
                      color: white.main,
                      backgroundColor: dark.main,
                    },
                  })}
                  onClick={() => {
                    setCreateOrderModal(true);
                    // setUpdateProductModal(false);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp; Create Order
                </MDButton>
              ) : null} */}
            </MDBox>
            <MDBox
              // px={3}
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "100%",
                gap: 0.5,
                [breakpoints.up("xs")]: {
                  flexDirection: "column",
                },
                [breakpoints.up("sm")]: {
                  flexDirection: "column",
                },
                [breakpoints.up("md")]: {
                  flexDirection: "column",
                },
                [breakpoints.up("lg")]: {
                  flexDirection: "column",
                },
              })}
            >
              {/* <MDBox
            py={3}
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 1.5,
              width: "50%",
            }}
          >
            <MDTypography variant="button"> Product Filter</MDTypography>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={isFilterName}
              onChange={(e) => {
                setIsFilterName(e.target.value);
                setPagess(1);
                setFilter(tabsName && tabsName[0]);
                setIsSearch("");
              }}
              sx={({ palette: { dark, white, info } }) => ({
                width: '100%',
                height: "3rem",
                color: darkMode ? white?.main : dark?.main,
                bgcolor: "transparent",
                "&	.MuiSelect-icon": {
                  color: darkMode ? white?.main : dark?.main,
                  display: "block !important",
                  fontSize: "1.5rem !important",
                },
              })}
            >
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"productId"}>ProductId</MenuItem>
            </Select>
          </MDBox> */}
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    pl: 3,
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    pl: 3,
                  },
                  [breakpoints.up("md")]: {
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    pt: 4,
                  },
                  [breakpoints.up("lg")]: {
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 1.5,
                    pt: 4,
                  },
                })}
              >
                <MDTypography variant="button">Order Filter</MDTypography>

                <MDInput
                  placeholder="Select Options"
                  type="text"
                  fullWidth
                  // disabled
                  name="fullName"
                  value={fullName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={({ palette: { dark, white, info } }) => ({
                          backgroundColor: darkMode ? white.main : dark.main,
                          height: "100%",
                          padding: "1rem",
                          borderRadius: "5px",
                        })}
                      >
                        {filterCollapse ? (
                          <KeyboardArrowUpRounded
                            onClick={() => setFilterCollapse(false)}
                            size="20"
                            sx={({ palette: { dark, white, info } }) => ({
                              color: !darkMode ? white.main : dark.main,
                              cursor: "pointer",
                            })}
                          />
                        ) : (
                          <KeyboardArrowDownRounded
                            onClick={() => setFilterCollapse(true)}
                            size="20"
                            sx={({ palette: { dark, white, info } }) => ({
                              color: !darkMode ? white.main : dark.main,
                              cursor: "pointer",
                            })}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  // onChange={(e) => setFullName(e.target.value)}
                />
              </MDBox>
              <Collapse
                in={filterCollapse}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100%", p: 3 }}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 0.5,
                    width: "100%",
                    [breakpoints.up("xs")]: {
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    },
                    [breakpoints.up("sm")]: {
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    },
                    [breakpoints.up("md")]: {
                      flexDirection: "row",
                      flexWrap: "wrap",
                    },
                    [breakpoints.up("lg")]: {
                      flexDirection: "row",
                      flexWrap: "wrap",
                    },
                  })}
                >
                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Filter By Duration</MDTypography>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      {dateFilter?.map((ele, i) => (
                        <MenuItem component="option" key={i} value={ele?._id}>
                          {ele?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Filter By Status</MDTypography>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      {filterByStatusEcomm?.map((ele, i) => (
                        <MenuItem key={i} value={ele?._id}>
                          {ele?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Filter By Payment Method</MDTypography>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      {filterByPaymentMethod?.map((ele, i) => (
                        <MenuItem key={i} value={ele?._id}>
                          {ele?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By Shipping Charge Id</MDTypography>

                    <MDInput
                      placeholder="Shipping Charge Id"
                      type="text"
                      fullWidth
                      name="shippingChargeId"
                      value={shippingChargeId}
                      onChange={(e) => setShippingChargeId(e.target.value)}
                    />
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By OrderId</MDTypography>

                    <MDInput
                      placeholder="Order Id"
                      type="text"
                      fullWidth
                      name="orderId"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                  </MDBox>
                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By User Id</MDTypography>

                    <MDInput
                      placeholder="User Id"
                      type="text"
                      fullWidth
                      name="userId"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By State Name</MDTypography>

                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={stateName}
                      onChange={(e) => {
                        setStateName(e.target.value);
                      }}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      <MenuItem key="default" value="">
                        Select State
                      </MenuItem>
                      {stateData?.map((ele, i) => (
                        <MenuItem key={i} value={ele?.name} onClick={(e) => setStateId(ele?.id)}>
                          {ele?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By City Name</MDTypography>

                    <Select
                      disabled={!stateName}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={cityName}
                      onChange={(e) => setCityName(e.target.value)}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      <MenuItem key="default" value="">
                        Select City
                      </MenuItem>

                      {cityData?.map((ele, i) => (
                        <MenuItem key={i} value={ele?.name}>
                          {ele?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>

                  <MDBox width="23%" display="flex" flexDirection="column">
                    <MDTypography variant="button">Sort By Pincode</MDTypography>

                    <Select
                      disabled={!cityName}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "100%",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      <MenuItem key="default" value="">
                        Select City Pincode
                      </MenuItem>
                      {cityPinCode && cityPinCode.length ? (
                        cityPinCode?.map((ele, i) => (
                          <MenuItem key={i} value={ele?.pincode}>
                            {ele?.cityName},{ele?.pincode}
                          </MenuItem>
                        ))
                      ) : (
                        <MDTypography variant="caption" align="center">
                          {" "}
                          No Pincode.....
                        </MDTypography>
                      )}
                    </Select>
                  </MDBox>
                </MDBox>
              </Collapse>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : AllOrders && AllOrders.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={AllOrders && AllOrders.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={viewProductModal}
        unShow={setViewProductModal}
        width={{ sx: "100%", md: "75%", xl: "75%", sm: "100%" }}
        height={"85vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <SingleOrderDetails
          viewOrderId={viewOrderId}
          setViewProductModal={setViewProductModal}
          isFindStatus={isFindStatus}
          isOrder={isOrderDetails}
          setIsOrder={setIsOrderDetails}
          handleMenuOpen={handleOpenMenu}
          // ecom={ecom}
        />
      </SkModal>

      {/* <Dialog open={dialogopen} onClose={setDialogopen}>
        

        <DialogActions></DialogActions>
      </Dialog> */}
      <SkModal
        show={dialogopen}
        unShow={setDialogopen}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        maxHeight={"80%"}
        padding={3}
        overflowY={true}
      >
        <MDBox
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            p: 2,
            border: 1,
          }}
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 , 10px",
            }}
          >
            <MDTypography variant="h6" py={0.9} sx={{ textTransform: "capitalize" }}>
              shipping status change
            </MDTypography>
          </Card>

          <MDBox sx={{ width: "100%", textAlign: "left" }}>
            <MDTypography sx={{ textTransform: "capitalize", my: 1, fontSize: 15 }}>
              shiprocket AWB tracking number
            </MDTypography>
            <MDInput
              required={true}
              // disabled={createUpdateLoading}
              type="text"
              placeholder="AWB Tracking Number "
              fullWidth
              name="awb"
              value={awb}
              onChange={(e) => setAwb(e.target.value)}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <MDButton color={"error"} verdant={"gradient"} onClick={() => setDialogopen(false)}>
              Cancel
            </MDButton>
            <MDButton
              // disabled={}
              color={"info"}
              verdant={"gradient"}
              onClick={() => {
                setOpenMenu(false);
                isStatusUpdateFunction("SHIPPED", true, awb);
                setDialogopen(false);
              }}
            >
              status change
            </MDButton>
          </MDBox>
        </MDBox>
      </SkModal>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        {/* { console.log(isFindStatus,"jdfbgdffd")}
       { console.log(isOrderDetails,"ghvjhvbh")} */}

        {/* {console.log(isOrderDetails, "isOrderDetails")}
        {console.log(
          isOrderDetails?.product && isOrderDetails?.items?.length === 1
            ? isOrderDetails?.product?.at(0)?.status || "N/A"
            : isFindStatus(isOrderDetails) || "N/A",
          "isStatus"
        )}  */}

        {isOrderDetails ? (
          <>
            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "PENDING") ||
            isFindStatus(isOrderDetails) === "PENDING " ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED", true);
                  }}
                >
                  Cancel
                </MenuItem>
              </>
            ) : null}
            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "ORDERED") ||
            isFindStatus(isOrderDetails) === "ORDERED" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("ACCEPTED", true);
                  }}
                >
                  Accepted
                </MenuItem>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED", true);
                  }}
                >
                  Cancel
                </MenuItem>
              </>
            ) : null}

            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "ACCEPTED") ||
            isFindStatus(isOrderDetails) === "ACCEPTED" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);

                    setDialogopen(true);
                    // isStatusUpdateFunction("SHIPPED", true, (awb = "1234"));
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <CheckCircle />
                  </MDAvatar>{" "} */}
                  Shipped
                </MenuItem>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED", true);
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <Unpublished sx={{ fontSize: "2rem" }} />
                  </MDAvatar> */}
                  Cancel
                </MenuItem>
              </>
            ) : null}
            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "SHIPPED") ||
            isFindStatus(isOrderDetails) === "SHIPPED" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("OUTOFDELIVERY", true);
                  }}
                >
                  Out For Delivery
                </MenuItem>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED", true);
                  }}
                >
                  Cancel
                </MenuItem>
              </>
            ) : null}
            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "OUTOFDELIVERY") ||
            isFindStatus(isOrderDetails) === "OUTOFDELIVERY" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("DELIVERED", true);
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <CheckCircle />
                  </MDAvatar>{" "} */}
                  Delivered
                </MenuItem>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED", true);
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <Unpublished sx={{ fontSize: "2rem" }} />
                  </MDAvatar> */}
                  Cancel
                </MenuItem>
              </>
            ) : null}
            {/* {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "RETURN") ||
            isOrderDetails?.product?.[0]?.status === "RETURNREQUEST" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("RETURNREQUEST");
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <CheckCircle />
                  </MDAvatar>{" "} */}
            {/* Approve Return Request
                </MenuItem>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("CANCELLED");
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <Unpublished sx={{ fontSize: "2rem" }} />
                  </MDAvatar> */}
            {/* Cancel
                </MenuItem>
              </>
            ) : null}  */}
            {(isOrderDetails?.product?.[0]?.status &&
              isOrderDetails?.product?.[0]?.status === "RETURNREQUEST") ||
            isOrderDetails?.product?.[0]?.status === "RETURNREQUEST" ? (
              <>
                <MenuItem
                  sx={{ border: "1px solid blue", mb: 1 }}
                  onClick={() => {
                    setOpenMenu(false);
                    isStatusUpdateFunction("RETURNED", true);
                  }}
                >
                  {/* <MDAvatar size="sm">
                    <CheckCircle />
                  </MDAvatar>{" "} */}
                  Returned
                </MenuItem>
              </>
            ) : null}
          </>
        ) : (
          <>
            <MenuItem
              sx={{ border: "1px solid blue", mb: 1 }}
              onClick={() => {
                setOpenMenu(false);
                isStatusUpdateFunction("CANCELLED");
              }}
            >
              {/* <MDAvatar size="sm">
              <CheckCircle />
            </MDAvatar>{" "} */}
              Cancel
            </MenuItem>
          </>
        )}
        {/* {(isOrderDetails?.items && isOrderDetails?.items?.length === 1
          ? isOrderDetails?.items?.at(0)?.status || "N/A"
          : isFindStatus(isOrderDetails) || "N/A") === "ORDERED" && (
            <>
              <MenuItem
                onClick={() => {
                  setOpenMenu(false);
                  isStatusUpdateFunction("CONFIRMED");
                }}
              >
                <MDAvatar size="sm">
                  <CheckCircle />
                </MDAvatar>{" "}
                CONFIRMED
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenMenu(false);
                  isStatusUpdateFunction("CANCELLED");
                }}
              >
                <MDAvatar size="sm">
                  <Unpublished sx={{ fontSize: "2rem" }} />
                </MDAvatar>
                REJECTED
              </MenuItem>
            </>
          )} */}
      </Menu>
    </>
  );
};

export default Orders;
