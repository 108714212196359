import { Card, IconButton, Tooltip, MenuItem, Menu, Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDBadge from "components/MDBadge";
import { getAllTransaction } from "redux/festures/AffiliateSlice";
import { updateAffilateTransactionStatus } from "redux/festures/AffiliateSlice";
import { getAllWithdraw } from "redux/festures/AffiliateSlice";
import Singlewithdral from "./Singlewithdral";
import SkModal from "components/SkModal";
import { Visibility } from "@mui/icons-material";
import { SkPrice } from "Utils/dateFunc";
import { getSingleWithdral } from "redux/festures/AffiliateSlice";

function createAlltransation(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "amount", accessor: "amount" },
    { Header: "affiliate User Id", accessor: "affiliateUserId" },
    { Header: "Transaction Type", accessor: "transactionType" },
    { Header: "type", accessor: "type" },
    //   { Header: "status", accessor: "status" },

    isAdmin?.permissions?.all
      ? {
          Header: "status",
          accessor: "status",
        }
      : isAdmin?.permissions?.affiliate?.includes("UPDATE") && {
          Header: "status",
          accessor: "status",
        },
    // isAdmin?.permissions?.all
    //   ? {
    //       Header: "view",
    //       accessor: "view",
    //     }
    //   : isAdmin?.permissions?.affiliate?.includes("VIEWS") && {
    //       Header: "view",
    //       accessor: "view",
    //     },
  ].filter(Boolean);
}
const Withdraw = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [sigleId,setSigleId ] = useState("");
 
  const [isOpenView, setIsOpenView] = useState(false);
  const { Loading, withdraw, isPages,singlewithdral } = useSelector((state) => ({ ...state?.isAffiliate }));
  const [transactionId, setTrasactionId] = useState(null);

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAlltransation(isAdmin);

  const [pagess, setPagess] = useState(1);

  console.log(withdraw,"jhbhvbhbdshccb")
  console.log(singlewithdral,"vgvhjkljhgfhj")

  useEffect(() => {
    dispatch(getAllWithdraw(`${process.env.REACT_APP_API}/getAllWithDrawalRequest?transactionType=WITHDRAWAL&page=${pagess || ""}`));
  }, [pagess]);

  const handleStatusChange = (status) => {
    dispatch(
      updateAffilateTransactionStatus({
        url: `${process.env.REACT_APP_API}/updateRequest/${transactionId}`,
        data: { status: status },
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getAllWithdraw(`${process.env.REACT_APP_API}/getAllWithDrawalRequest?transactionType=WITHDRAWAL&page=${pagess || ""}`));
      }
    });
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = ({ event }) => {
    setOpenMenu(event.currentTarget);
  };

  useEffect(() => {
    if (withdraw && withdraw.length > 0) {
      const temprows =
        withdraw &&
        withdraw?.at(0) &&
        withdraw?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          amount: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{SkPrice(value?.amount) || "N/A"}</MDTypography>
            </MDBox>
          ),
          affiliateUserId: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.affiliateUserId || "N/A"}</MDTypography>
            </MDBox>
          ),
          transactionType: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.transactionType || "N/A"}</MDTypography>
            </MDBox>
          ),
          type: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.type || "N/A"}</MDTypography>
            </MDBox>
          ),
          "change Status": (
            <>
              <Tooltip title={value?.status && value?.status?.length === 1 && value?.status}>
                <IconButton
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setTrasactionId(value?._id);
                  }}
                >
                  <MDBadge
                    badgeContent="Update Status"
                    color="primary"
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          status: (
            <>
              <Tooltip title={value?.status || "N/A"}>
                <IconButton
                  aria-controls="notification-menu"
                  disabled={value?.status === "CANCELED" ||value?.status ==="APPROVED"}
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setTrasactionId(value?._id);
                    setSigleId(value)
                  }}
                >
                  <MDBadge
                    badgeContent={value?.status}
                    color={
                      (value?.status === "PENDING" && "warning") ||
                      (value?.status === "APPROVED" && "success") ||
                      (value?.status === "CANCELED" && "error")
                    }
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                // dispatch(getSingleWithdral(`/getByAffiliateTransactionId/${value?._id}`));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),


        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [withdraw]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Affiliate Withdrawal&apos;s Table{" "}
              </MDTypography>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : withdraw && withdraw.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={withdraw && withdraw.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>


      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <Singlewithdral />
      </SkModal>


      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >


        <>
        {sigleId?.status=== "PENDING" && 
        <>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("APPROVED");
            }}
          >
            APPROVED
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("CANCELED");
            }}
          >
            CANCELLED
          </MenuItem>
        </>
        }
        {/* {sigleId?.status=== "APPROVED" && 
        <>
         
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("CANCELED");
            }}
          >
            CANCELLED
          </MenuItem>
        </>
        } */}


          {/* <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("PENDING");
            }}
          >
            PENDING
          </MenuItem> */}
        
        </>
      </Menu>
    </>
  );
};

export default Withdraw;
