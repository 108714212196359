import axios from "axios";
import http from "Utils/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getIsHomesData = createAsyncThunk("getIsHomesData", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getIsHomeById = createAsyncThunk("getIsHomeById", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getIsHomeGlobal = createAsyncThunk("getIsHomeGlobal", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateIsHome = createAsyncThunk("updateIsHome", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteIsHome = createAsyncThunk("deleteIsHome", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createIsHomes = createAsyncThunk("createIsHomes", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const homeSlice = createSlice({
  name: "homeSlice",
  initialState: {
    homeData: null,
    singleHome: null,

    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIsHomesData.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getIsHomesData.fulfilled, (state, action) => {
        state.Loading = false;
        state.homeData = action.payload?.data;
      })
      .addCase(getIsHomeGlobal.fulfilled, (state, action) => {
        state.Loading = false;
        state.homeData = action.payload?.data;
      })
      .addCase(getIsHomesData.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createIsHomes.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(createIsHomes.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(createIsHomes.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getIsHomeById.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getIsHomeById.fulfilled, (state, action) => {
        state.Loading = false;
        state.singleHome = action.payload?.data;
      })
      .addCase(getIsHomeById.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export default homeSlice.reducer;
