import { Add, Cancel, Delete } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getBrands } from "redux/festures/brandSlice";
import { updateBrands } from "redux/festures/brandSlice";
import { createBrands } from "redux/festures/brandSlice";
import AstrieskIcon from "components/AstrieskIcon";
import SkEditor from "components/SKeditor/SkEditor";
import { updateDisease } from "redux/festures/diseaseSlice";
import { createDisease } from "redux/festures/diseaseSlice";
import { getAllDisease } from "redux/festures/diseaseSlice";
import { useTheme } from "@mui/material/styles";
import MDBadge from "components/MDBadge";
// import { sanitize } from "dompurify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const CreateDisease = ({ isOpen, isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isDiseaseName, setIsDiseaseName] = useState("");
  const [isDiseaseIcon, setIsDiseaseIcon] = useState(null);
  const [isDiseaseDisc, setIsDiseaseDisc] = useState("");
  const [isDiseaseControlDisc, setIsDiseaseControlDisc] = useState("");

  const [isTitle, setIsTitle] = useState("");
  const [isShow, setIsShow] = useState("");
  const [isBrandShowHome, setIsBrandShowHome] = useState(false);
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();

  const { Loading, singleDisease, createUpdateLoading } = useSelector((state) => ({
    ...state?.isDisease,
  }));
  // console.log(singleDisease);

  const theme = useTheme();
  const [methodIndex, setMethodIndex] = React.useState(0);

  // new method add field
  const [method, setMethod] = useState([
    // {
    //   title: [],
    //   suggestions: [],
    //   image: [],
    // },
  ]);

  const [newTitle, setNewTitle] = useState("");
  const [newSuggestions, setNewSuggestions] = useState("");
  const [newImage, setNewImage] = useState("");

  // console.log(method);
  // const formData = new FormData();
  // method.forEach((variant, index) => {
  //   formData.append(`title`, variant?.title || "");
  //   formData.append(`suggestions`, variant?.suggestions || "");
  //   formData.append(`image`, variant?.image || "");
  // });
  // console.log(...formData, "form", method);
  const addMethods = () => {
    const title = newTitle;
    const suggestions = newSuggestions;
    const image = newImage;
    const newObject = {
      title: title,
      suggestions: suggestions,
      image: image,
    };
    if (title !== "" && suggestions !== "" && image !== "") {
      setMethod([...method, newObject]);

      // setMethod([...method, { title, suggestions, image }]);
      setNewTitle("");
      setNewSuggestions("");
      setNewImage(null);
    }
  };

  const removeMethod = (index) => {
    const newPriceVariants = [...method];
    newPriceVariants.splice(index, 1);
    setMethod(newPriceVariants);
  };

  const [data, setData] = useState([]);
  const updateArray = (index, updatedTitle, updatedSuggestions, updatedImage) => {
    const newData = data.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          title: updatedTitle,
          suggestions: updatedSuggestions,
          image: updatedImage,
        };
      }
      return item;
    });

    setData(newData);
  };
  // console.log(isDiseaseIcon);

  useEffect(() => {
    if (singleDisease && isOpenUpdate) {
      setIsDiseaseName(singleDisease?.diceaseName);
      setIsShow(singleDisease?.icon);
      setIsDiseaseDisc(singleDisease?.description);
      setIsDiseaseControlDisc(singleDisease?.diceaseControlDiscription);
      // setIsDiseaseIcon(singleDisease?.icon);
      setData(singleDisease?.method);
    } else {
      setIsDiseaseName("");
      setIsShow("");
      setIsDiseaseDisc("");
      setIsDiseaseControlDisc("");
    }
  }, [singleDisease, isOpenUpdate, isOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // if ((isDiseaseIcon && isDiseaseIcon !== "") || (isShow && isShow !== "")) {
    if (isOpenUpdate) {
      const formData = new FormData();
      formData.append("diceaseName", isDiseaseName);
      formData.append("description", isDiseaseDisc);
      formData.append("diceaseControlDiscription", isDiseaseControlDisc);
      isDiseaseIcon && formData.append("icon", isDiseaseIcon);

      dispatch(
        updateDisease({
          url: `${process.env.REACT_APP_API}dicease/updateDicease/${singleDisease?._id}`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);
        setIsDiseaseName("");
        setIsDiseaseIcon("");

        dispatch(getAllDisease(`${process.env.REACT_APP_API}/dicease/getAllDicease`));
      });
    } else {
      const formData = new FormData();
      formData.append("diceaseName", isDiseaseName);
      isDiseaseIcon && formData.append("icon", isDiseaseIcon);
      formData.append("description", isDiseaseDisc);
      formData.append("diceaseControlDiscription", isDiseaseControlDisc);

      method && method?.length > 0 && method?.forEach((variant, index) => {
        formData.append(`method[${index}][title]`, variant?.title || "");
        formData.append(`method[${index}][suggestions]`, variant?.suggestions || "");
        // formData.append(`method[${index}][image]`, variant?.image || "");
        formData.append(`image`, variant?.image || "");
      });

      dispatch(
        createDisease({
          url: `${process.env.REACT_APP_API}dicease/createDicease`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);
        setIsDiseaseName("");
        setIsDiseaseIcon(null);

        dispatch(getAllDisease(`${process.env.REACT_APP_API}/dicease/getAllDicease`));
      });
    }
    // }
    // else {
    //   dispatch(
    //     handleAlert({
    //       isOpen: true,
    //       type: "warning",
    //       msg: "all filed is required",
    //     })
    //   );
    // }
  };

  const handleFormSubmitMehtod = (i) => {
    if (isOpenUpdate) {
      const formData = new FormData();
      formData.append("index", i + 1);
      formData.append("title", data[i]?.title);
      formData.append("suggestions", data[i]?.suggestions);
      formData.append("image", data[i]?.image);
      // isDiseaseIcon && formData.append("icon", isDiseaseIcon);

      dispatch(
        updateDisease({
          url: `${process.env.REACT_APP_API}dicease/updateDicease/${singleDisease?._id}`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);
        setIsDiseaseName("");
        setIsDiseaseIcon(null);
        dispatch(getAllDisease(`${process.env.REACT_APP_API}/dicease/getAllDicease`));
      });
    }
  };
  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update Disease ` : " Create Disease"}
          </MDTypography>
        </Card>
        <MDBox
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            width: "100%",
            p: 3,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Disease Name <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              disabled={createUpdateLoading}
              type="text"
              placeholder="Disease Name"
              fullWidth
              name="name"
              value={isDiseaseName}
              onChange={(e) => setIsDiseaseName(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Disease Discription <AstrieskIcon />
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( type only English language )
              </MDTypography>
            </MDTypography>
            <SkEditor
              editorState={isDiseaseDisc}
              setEditorState={setIsDiseaseDisc}
              placeholder={"Disease Discription "}
              isButton={true}
              initialContent={(singleDisease && isOpenUpdate && singleDisease?.description) || ""}
              // content={"description"}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              mt: 0.5,
            }}
          >
            <MDTypography variant="h6">
              Disease Control Discription
              <AstrieskIcon />
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( type only English language )
              </MDTypography>
            </MDTypography>
            <SkEditor
              required={true}
              editorState={isDiseaseControlDisc}
              setEditorState={setIsDiseaseControlDisc}
              placeholder={"Disease Control Discription "}
              isButton={true}
              initialContent={
                (singleDisease && isOpenUpdate && singleDisease?.diceaseControlDiscription) || ""
              }
              // content={"description"}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Disease Image{" "}
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 240 × 240 px )
              </MDTypography>
            </MDTypography>
            <ImagePicker
              disabled={createUpdateLoading}
              // required={true}
              name="icon"
              multiple={false}
              images={isDiseaseIcon}
              setImages={setIsDiseaseIcon}
            />

            {isDiseaseIcon === null && isShow && (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "50px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    src={`${process.env.REACT_APP_URI}/${isShow}`}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsShow(null);
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </div>
            )}
          </MDBox>

          {/* create method process */}

          {!isOpenUpdate && (
            <MDBox width={"100%"} sx={{ mb: 2 }}>
              <MDTypography width={"100%"} sx={{ fontSize: "1.2rem" }} variant="h6">
                Add Methods
              </MDTypography>
              <MDBox
                lineHeight={1}
                width={"100%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography variant="h6">
                  Title <AstrieskIcon />
                </MDTypography>
                <MDInput
                  // required={true}
                  // disabled={createUpdateLoading}
                  type="text"
                  placeholder="Method Title"
                  fullWidth
                  name="title"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                gap={1}
                width={"100%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  mt: 1,
                }}
              >
                <MDTypography variant="h6">
                  Suggestions
                  <AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( type only English language )
                  </MDTypography>
                </MDTypography>
                <SkEditor
                  // required={true}
                  editorState={newSuggestions}
                  setEditorState={(e) => setNewSuggestions(e)}
                  placeholder={"Suggestions"}
                  isButton={true}
                  initialContent={newSuggestions}
                  // content={"description"}
                />
              </MDBox>
              <MDBox
                lineHeight={1}
                width={"100%"}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                <MDTypography variant="h6">
                  Method Image{" "}<AstrieskIcon />
                  <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; ( image size - 277 × 221 px )
                  </MDTypography>
                </MDTypography>
                <ImagePicker
                  disabled={createUpdateLoading}
                  // required={true}
                  name="icon"
                  multiple={false}
                  images={newImage}
                  setImages={(e) => {
                    setNewImage(e);
                  }}
                />
                {/* {newImage && (
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        height: "50px",
                        margin: "0 0.5rem",
                      }}
                    >
                      <img
                        className="Image"
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        src={{newImage}}
                      />
                    </span>
                    <span
                      className="cross"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNewImage("");
                      }}
                    >
                      <Cancel
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white?.main : dark.main,
                        })}
                      />
                    </span>
                  </div>
                )} */}
              </MDBox>
              <MDButton
                variant="contained"
                color="primary"
                onClick={addMethods}
                style={{ marginBottom: 16 }}
              >
                <Add /> Add Method
              </MDButton>
            </MDBox>
          )}

          {method?.length > 0 && (
            <MDBox width={"100%"} sx={{ mb: 2 }}>
              <MDTypography width={"100%"} sx={{ fontSize: "1.2rem" }} variant="h6">
                Added Methods
              </MDTypography>
              {method &&
                method?.length > 0 &&
                method?.map((ele, index) => (
                  <MDBox key={index} width={"100%"}>
                    <MDBox
                      lineHeight={1}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <MDTypography variant="h6">Title : {ele?.title}</MDTypography>
                    </MDBox>
                    <MDBox
                      lineHeight={1}
                      gap={1}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        mt: 1,
                      }}
                    >
                      <MDTypography variant="h6">
                        Suggestions :
                      </MDTypography>
                        <MDTypography
                          variant="body1"
                          component="span"
                          fontSize={20}
                          dangerouslySetInnerHTML={{ __html: ele?.suggestions }}
                        ></MDTypography>
                    </MDBox>
                    <MDBox
                      lineHeight={1}
                      width={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        mt: 2,
                      }}
                    >
                      <MDTypography variant="h6">
                        Method Image{" "} 
                      </MDTypography>
                      <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <span
                          style={{
                            display: "inline-block",
                            width: "50px",
                            height: "50px",
                            margin: "0 0.5rem",
                          }}
                        >
                          <img
                            className="Image"
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            // src={`${ele?.image?.name}`}
                            src={URL.createObjectURL(ele?.image)}
                            />
                            {/* {console.log(ele?.image?.name)} */}
                        </span>
                        {/* <span
                          className="cross"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNewImage("");
                          }}
                        >
                          <Cancel
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white?.main : dark.main,
                            })}
                          />
                        </span> */}
                      </div>
                    </MDBox>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => removeMethod(index)}
                      style={{ marginBottom: 16 }}
                    >
                      <Delete
                        sx={({ palette: { white, info } }) => ({
                          color: darkMode ? white.main : info.main,
                        })}
                      />{" "}
                      Delete
                    </MDButton>
                  </MDBox>
                ))}
            </MDBox>
          )}

          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton
              disabled={createUpdateLoading}
              color={"info"}
              verdant={"gradient"}
              type={"submit"}
            >
              {createUpdateLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : isOpenUpdate ? (
                `Update Disease`
              ) : (
                ` Create Disease`
              )}
            </MDButton>
          </MDBox>
        </MDBox>

        {data?.length > 0 && (
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderTop: 1,
              borderTopColor: darkMode ? white.main : info.main,
              mt: 3,
            })}
          >
            {" "}
            <MDBadge
              badgeContent="Methods"
              color="success"
              variant="gradient"
              size="lg"
              sx={{ zIndex: 10, mt: -1.5 }}
            />
          </MDBox>
        )}

        {/* update methods process */}

        {data?.length > 0 && (
          <MDBox
            width={"100%"}
            sx={{ border: "2px solid white", borderRadius: "0.5rem", p: 1.5, mt: 2 }}
          >
            {/* For method update process */}

            {data &&
              data?.length > 0 &&
              data?.map((ele, i) => (
                <MDBox
                  key={i}
                  width={"100%"}
                  sx={{ mb: 2 }}
                  component="form"
                  role="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmitMehtod(i);
                  }}
                >
                  <MDBox
                    lineHeight={1}
                    width={"100%"}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <MDTypography variant="h6">
                      Title <AstrieskIcon />
                    </MDTypography>
                    <MDInput
                      // required={true}
                      // disabled={createUpdateLoading}
                      type="text"
                      placeholder="Disease Name"
                      fullWidth
                      name="title"
                      value={ele?.title}
                      onChange={(e) => updateArray(i, e.target.value, ele?.suggestions, ele?.image)}
                    />
                  </MDBox>
                  <MDBox
                    lineHeight={1}
                    gap={1}
                    width={"100%"}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      mt: 1,
                    }}
                  >
                    <MDTypography variant="h6">
                      Suggestions
                      <AstrieskIcon />
                      <MDTypography variant="body1" component="span" fontSize={11}>
                        &nbsp; ( type only English language )
                      </MDTypography>
                    </MDTypography>
                    <SkEditor
                      editorState={ele?.suggestions}
                      setEditorState={(e) => updateArray(i, ele?.title, e, ele?.image)}
                      placeholder={"Suggestions"}
                      isButton={true}
                      initialContent={ele?.suggestions || ""}
                      // content={"description"}
                    />
                  </MDBox>
                  <MDBox
                    lineHeight={1}
                    width={"100%"}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      mt: 2,
                    }}
                  >
                    <MDTypography variant="h6">
                      Method Image{" "}
                      <MDTypography variant="body1" component="span" fontSize={11}>
                        &nbsp; ( image size - 277 × 221 px )
                      </MDTypography>
                    </MDTypography>
                    <ImagePicker
                      disabled={createUpdateLoading}
                      // required={true}
                      name="icon"
                      multiple={false}
                      images={ele?.icon}
                      setImages={(e) => {
                        updateArray(i, ele?.title, ele?.suggestions, e);
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "50px",
                          height: "50px",
                          margin: "0 0.5rem",
                        }}
                      >
                        <img
                          className="Image"
                          style={{ width: "100%", height: "100%", objectFit: "contain" }}
                          src={`${process.env.REACT_APP_URI}/${ele?.image}`}
                          // src={URL.createObjectURL(ele?.image)}
                        />
                      </span>
                      <span
                        className="cross"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsShow(null);
                        }}
                      >
                        <Cancel
                          sx={({ palette: { dark, white, info } }) => ({
                            color: darkMode ? white?.main : dark.main,
                          })}
                        />
                      </span>
                    </div>
                  </MDBox>
                  <MDBox
                    sx={{
                      width: "100%",
                      justifyContent: "flex-end",
                      textAlign: "center",
                      display: "flex",
                    }}
                  >
                    {" "}
                    <MDButton
                      disabled={createUpdateLoading}
                      color={"info"}
                      verdant={"gradient"}
                      type={"submit"}
                    >
                      {createUpdateLoading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : isOpenUpdate ? (
                        `Update Method`
                      ) : (
                        ` Create Method`
                      )}
                    </MDButton>
                  </MDBox>
                </MDBox>
              ))}
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default CreateDisease;
CreateDisease.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  isOpen: PropTypes.any,
  //   setIsSwitch: PropTypes.func,
  //   isSwitch: PropTypes.any,
  //   productId: PropTypes.string,
  //   updateProductModal: PropTypes.bool,
  //   setUpdateProductModal: PropTypes.bool,
};
