import axios from "axios";
import http from "Utils/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllBlogs = createAsyncThunk("getAllBlogs", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleBlog = createAsyncThunk("getSingleBlog", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalBlog = createAsyncThunk("getGlobalBlog", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateBlog = createAsyncThunk("updateBlog", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteBlog = createAsyncThunk("deleteBlog", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createPostBlogs = createAsyncThunk("createPostBlogs", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const blogSlice = createSlice({
  name: "blogSlice",
  initialState: {
    blogs: null,
    singleBlogs: null,
    isPages: null,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.Loading = false;
        state.blogs = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getGlobalBlog.fulfilled, (state, action) => {
        state.blogs = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getAllBlogs.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createPostBlogs.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(createPostBlogs.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(createPostBlogs.rejected, (state, action) => {
        state.Loading = false;
      })
 
      .addCase(getSingleBlog.fulfilled, (state, action) => {
       
        state.singleBlogs = action.payload?.data;
      })
  
  },
});
export default blogSlice.reducer;
