import { Close, Edit, Visibility, VisibilityOff, Delete, Input } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Select,
  MenuItem,
  Tooltip,
  Rating,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getSingleAttribute } from "redux/festures/AttributeSlice";
import { getSingleBlog } from "redux/festures/blogSlice";
import { updateBlog } from "redux/festures/blogSlice";
import { getAllBlogs } from "redux/festures/blogSlice";
import { formattedDateServer } from "Utils/dateFunc";
import Form from "./Form";
import SingleTactimonial from "./SingleTactimonial";
import { getGlobalBlog } from "redux/festures/blogSlice";
import MDInput from "components/MDInput";
import { updateTactimonials } from "redux/festures/TactimonialsSlice";
import { getSingleTactimonials } from "redux/festures/TactimonialsSlice";
import { getAllTactimonials } from "redux/festures/TactimonialsSlice";
import { getAllglobleTactimonials } from "redux/festures/TactimonialsSlice";
import MenuSelecter from "components/MenuSelecter";

const disableFilter = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: false,
    name: "Active",
  },
  {
    _id: true,
    name: "Deleted",
  },
];

function createAlltactimonials(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "Tactimonials details", accessor: "Tactimonials details" },
    { Header: "Description", accessor: "Description" },
    { Header: "Rating", accessor: "rating" },
    { Header: "date", accessor: "date" },

    isAdmin?.permissions?.all
      ? {
          Header: "disable",
          accessor: "disable",
        }
      : isAdmin?.permissions?.orders?.includes("DISABLE") && {
          Header: "disable",
          accessor: "disable",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.orders?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "action",
          accessor: "action",
        }
      : isAdmin?.permissions?.tactimonials?.includes("UPDATE") && {
          Header: "action",
          accessor: "action",
        },
  ].filter(Boolean);
}

const Tactimonials = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  // const { Loading, blogs, isPages ,singleBlogs} = useSelector((data) => ({ ...data?.isBlogs }));
  const { Loading, singleTactimonials, isPages, tactimonials } = useSelector((data) => ({
    ...data?.isTactimonials,
  }));

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAlltactimonials = createAlltactimonials(isAdmin);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const [isDisable, setIsDisable] = useState(false);

  const [pagess, setPagess] = useState(1);

  // console.log(isDisable,"isDisable")

  useEffect(() => {
    dispatch(
      getAllTactimonials(
        `/getAllTactimonial${`?disable=${
          isDisable === 0 ? "" : isDisable === false ? false : true || ""
        }`}&page=${isPages || ""}`
      )
    );
  }, [isDisable, pagess]);
  // console.log(singleBlogs, "singleblogs");
  useEffect(() => {
    if (tactimonials && tactimonials.length > 0) {
      const temprows =
        tactimonials &&
        tactimonials?.at(0) &&
        tactimonials?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          "Tactimonials details": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.image}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
              {/* <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                }}
              > */}
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "250px",
                  lineHeight: "20px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Name: {value?.name || "N/A"}
              </MDTypography>

              {/* </MDBox> */}
            </MDBox>
          ),
          Description: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
              fontSize={10}
              style={{
                maxWidth: "250px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{ __html: value?.description }}
            />
          ),
          rating: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: " flex-start",
                gap: 0.5,
              }}
            >
              {/* <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              > */}
              {/* <MDTypography variant="h6">Rating :</MDTypography>
                    <MDTypography variant="h6"  
                    style={{
                        maxWidth: "400px",
                        lineHeight: "20px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      >{value?.rating || "N/A"}</MDTypography> */}
              <Rating name="read-only" value={value?.rating} readOnly />
              {/* </MDBox> */}
            </MDBox>
          ),
          date: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={10}>
                Create: {formattedDateServer(new Date(value?.createdAt))}
              </MDTypography>
              <MDTypography fontSize={10}>
                Last Update: {formattedDateServer(new Date(value?.updatedAt))}
              </MDTypography>
            </MDBox>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                dispatch(getSingleTactimonials(`/getByTactimonialId/${value?._id}`));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
          disable: (
            // <Switch
            //   value={value?.disable}
            //   checked={value?.disable}
            //   color={"info"}
            //   onChange={(e) => {

            //     dispatch(
            //       updateBlog({
            //         url: `${process.env.REACT_APP_API}/disableBlog/${value?._id}/${admin}`,
            //       })
            //     ).then((data) => {
            //       dispatch(
            //         handleAlert({
            //           isOpen: true,
            //           type: `${data?.payload?.success ? "success" : "error"}`,
            //           msg: data?.payload?.message,
            //         })
            //       );
            //       if (data?.payload?.success) {
            //         dispatch(getGlobalBlog(`/getAllBlog?adminId=${admin}`));
            //       }
            //     });
            //   }}
            //   inputProps={{ "aria-label": "controlled" }}
            // />
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateTactimonials({
                      url: `${process.env.REACT_APP_API}/disableTactimonial/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(getAllglobleTactimonials(`/getAllTactimonial`));
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),
          showInHome: (
            <Switch
              value={value?.showInHome}
              checked={value?.showInHome}
              color={"info"}
              onChange={(e) => {
                // handleSwitchControl(value?._id);
                // setIsSwitch(!isSwitch);
                dispatch(
                  updateTactimonials({
                    url: `${process.env.REACT_APP_API}/ShowInHomeBlog/${value?._id}/${admin}`,
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(getAllglobleTactimonials(`/getAllTactimonial`));
                  }
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getSingleTactimonials(`/getByTactimonialId/${value?._id}`));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [tactimonials]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Testimonials Table{" "}
              </MDTypography>

              {isAdmin?.permissions?.all ? (
                <MDButton
                  disabled={Loading}
                  variant="gradient"
                  color="dark"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: white.main,
                    backgroundColor: dark.main,
                    "&:hover": {
                      color: white.main,
                      backgroundColor: dark.main,
                    },
                  })}
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenUpdate(false);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp; Create Testimonials
                </MDButton>
              ) : (
                isAdmin?.permissions?.tactimonials.includes("CREATE") && (
                  <MDButton
                    disabled={Loading}
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(false);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create Testimonial
                  </MDButton>
                )
              )}
            </MDBox>
            <MDBox
              px={3}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox width="23%" display="flex" flexDirection="column" py={2}>
                {/* <MDTypography variant="button"> Tactimonial Visibility </MDTypography> */}
                {/* <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isDisable}
                  onChange={(e) => setIsDisable(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {disableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <MenuSelecter isDisable={isDisable} setIsDisable={setIsDisable} />
              </MDBox>
              {/* <MDBox
                pt={4}
                px={1}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 1.5,
                }}
              >
                <MDInput
                  placeholder="search here...... "
                  type="text"
                  fullWidth
                  name="sarch here"
                  value={isSearch}
                  onChange={(e) => setIsSearch(e.target.value)}
                />
              </MDBox> */}
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : tactimonials && tactimonials.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAlltactimonials,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={tactimonials && tactimonials.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />{" "}
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <Form isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} />
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <SingleTactimonial />
      </SkModal>
    </>
  );
};

export default Tactimonials;
