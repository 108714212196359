import {
  Close,
  Delete,
  Edit,
  Input,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Select,
  InputAdornment,
  Collapse,
  MenuItem,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
import { getAllTax, getSingleTax } from "redux/festures/taxSlice";
import { getAllGlobalProducts } from "redux/festures/productSlice";
import { getSingleProduct } from "redux/festures/productSlice";
import MDInput from "components/MDInput";
import { updateProducts } from "redux/festures/productSlice";
import CreateForm from "./CreateForm";
import SingleProduct from "./SingleProduct";
import MDBadge from "components/MDBadge";
import { getProductsWithoutLoading } from "redux/festures/productSlice";
import { getBrands } from "redux/festures/brandSlice";
import { getCategory } from "redux/festures/categorySlice";

// import Form from "./Form";
// import SingleBlog from "./SingleBlog";
// const columns = {
//   col: [
//     { Header: "S.No", accessor: "no" },
//     { Header: "Thumbnail", accessor: "thumbnail" },
//     { Header: "Title", accessor: "title" },
//     // { Header: "Price", accessor: "price" },
//     { Header: "MRP/Price Variant", accessor: "MRP/Price Variant" },
//     //   {
//     //     Header: "featured/bestselling/trending",
//     //     accessor: "featured/bestselling/trending",
//     //   },
//     { Header: "Category", accessor: "category" },
//     // { Header: "Stock / Qty", accessor: "showStock/Qty" },
//     // { Header: "Visibility", accessor: "visibility" },
//     { Header: "delete", accessor: "delete" },

//     { Header: "View", accessor: "view" },
//     { Header: "Action", accessor: "action" },
//   ],
//   // },
// };

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "product-details", accessor: "product-details" },
    { Header: "Stock", accessor: "Stock" },
    // { Header: "Price", accessor: "price" },
    { Header: "MRP/Price Variant", accessor: "MRP/Price Variant" },
    //   {
    //     Header: "featured/bestselling/trending",
    //     accessor: "featured/bestselling/trending",
    //   },
    { Header: "Category", accessor: "category" },

    isAdmin?.permissions?.all
      ? {
          Header: "delete",
          accessor: "delete",
        }
      : isAdmin?.permissions?.product?.includes("DISABLE") && {
          Header: "delete",
          accessor: "delete",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.product?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },

    isAdmin?.permissions?.all
      ? {
          Header: "action",
          accessor: "action",
        }
      : isAdmin?.permissions?.product?.includes("UPDATE") && {
          Header: "action",
          accessor: "action",
        },
  ].filter(Boolean);
}

const Product = () => {
  const dispatch = useDispatch();
  const admin = localStorage.getItem("admin_id");
  // const { pathname } = useLocation();
  //   const ProductSlice = useSelector((state) => state.productSlice);
  //   const totalPage = useSelector((state) => state.productSlice.totalPage);
  //   const filterPage = useSelector((state) => state.productSlice.filterPage);
  //   const admin = useSelector((state) => state.authSlice.admin);
  const { AllProducts, Loading, page } = useSelector((state) => ({ ...state.isProducts }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [value, setValue] = useState("");
  const [isFilterName, setIsFilterName] = useState("name");
  const [isSort, setIsSort] = useState("Sort By");
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  //filter states

  const [filterCollapse, setFilterCollapse] = useState(false);
  const [AllSubCategory, setAllSubCategory] = useState([]);
  const [AllTax, setAllTax] = useState([]);
  const { city } = useSelector((state) => state.isCity);

  const [rowsData, setRowsData] = useState([]);

  const [viewProductModal, setViewProductModal] = useState(false);
  const [viewProductId, setViewProductId] = useState("");

  // const [current, setcurrent] = useState(null);

  //Static Filters

  const dateFilter = [
    {
      _id: 0,
      name: "All Products",
    },
    {
      _id: "today",
      name: "Today",
    },
    {
      _id: "week",
      name: "This Week",
    },
    {
      _id: "month",
      name: "This Month",
    },
    {
      _id: "year",
      name: "This Year",
    },
    // {
    //   _id: 'manually',
    //   name: 'Manually'
    // },
  ];

  const sortByPrice = [
    {
      _id: 0,
      name: "All",
    },
    {
      _id: "HIGHTOLOW",
      name: "High To Low",
    },
    {
      _id: "LOWTOHIGH",
      name: "Low To High",
    },
  ];

  const disableFilter = [
    {
      _id: null,
      name: "All",
    },
    {
      _id: true,
      name: "Disable",
    },
    {
      _id: false,
      name: "Enable",
    },
  ];
  const isDisableFilter = [
    {
      _id: "",
      name: "All",
    },
    {
      _id: false,
      name: "Active",
    },
    {
      _id: true,
      name: "Deleted",
    },
  ];

  const [categoryId, setCategoryId] = useState(0);
  const [name, setName] = useState("");
  const [filter, setFilter] = useState(0);
  const [rating, setRating] = useState(0);
  const [sold, setSold] = useState(0);
  const [stock, setStock] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [productId, setproductId] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const [isPages, setIsPages] = useState(1);
  const [updateProductModal, setUpdateProductModal] = useState(false);

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const { category } = useSelector((data) => ({ ...data?.isCategory }));
  const { brands } = useSelector((data) => ({ ...data.isBrand }));

  useEffect(() => {
    dispatch(
      getAllGlobalProducts(
        `${process.env.REACT_APP_API}productFilter?categoryId=${categoryId || ""}&name=${
          name || ""
        }&filter=${filter || ""}&price=${price || ""}&brandId=${brandId || ""}&productId=${
          productId || ""
        }&discount=${discount || ""}&stock=${stock || ""}&sold=${sold || ""}&rating=${
          rating || ""
        }&disable=${isDisable}&page=${isPages}
         `
      )
    );
  }, [
    isPages,
    isOpen,
    categoryId,
    name,
    filter,
    rating,
    sold,
    stock,
    price,
    discount,
    brandId,
    productId,
    isDisable,
  ]);
  useEffect(() => {
    dispatch(getBrands(`${process.env.REACT_APP_API}getAllBrand?disable=${false}`));
    dispatch(
      getCategory(
        `${process.env.REACT_APP_API}getAllCategoryWithPcategory/${admin}?disable=${false}`
      )
    );
  }, []);

  useEffect(() => {
    if (AllProducts && AllProducts.length > 0) {
      const temprows =
        AllProducts &&
        AllProducts?.at(0) &&
        AllProducts?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          "product-details": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.thumbnail}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 0.5,
                  flexDirection: "column",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                }}
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "220px",
                    lineHeight: "18px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Name: {value?.name || "-"}
                </MDTypography>

                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  fontSize={12}
                >
                  ProductId: {value?._id || "-"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),

          Stock: (
            <MDBadge badgeContent={value?.stock || "N/A"} color="primary" size="md" max={1000} />
          ),
          "MRP/Price Variant": (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
            >
              {value?.mrp ? (
                <MDBadge
                  badgeContent={value?.mrp ? `${SkPrice(value?.mrp)} ` : null}
                  color="success"
                  size="md"
                  container
                />
              ) : (
                <MDBadge badgeContent="multiple" color="primary" size="md" container />
              )}
            </MDTypography>
          ),
          category: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
              style={{
                maxWidth: "250px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.categoryId?.name || "-"}
            </MDTypography>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setViewProductModal(true);
                setViewProductId(value);
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),

          delete: (
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateProducts({
                      url: `${process.env.REACT_APP_API}/disableProduct/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    // console.log(data,"ghvhvhvhbvh")
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(
                        getProductsWithoutLoading(
                          `${process.env.REACT_APP_API}productFilter?categoryId=${
                            categoryId || ""
                          }&name=${name || ""}&filter=${filter || ""}&price=${
                            price || ""
                          }&brandId=${brandId || ""}&productId=${productId || ""}&discount=${
                            discount || ""
                          }&stock=${stock || ""}&sold=${sold || ""}&rating=${
                            rating || ""
                          }&disable=${isDisable}&page=${isPages}`
                        )
                      );
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),
          action: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(true);
                setUpdateProductModal(true);
                // console.log(value?._id);
                dispatch(getSingleProduct(value));
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [AllProducts]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Product&apos;s Table{" "}
              </MDTypography>

              {isAdmin?.permissions?.all ? (
                <MDButton
                  disabled={Loading}
                  variant="gradient"
                  color="dark"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: white.main,
                    backgroundColor: dark.main,
                    "&:hover": {
                      color: white.main,
                      backgroundColor: dark.main,
                    },
                  })}
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenUpdate(false);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp; Create Product
                </MDButton>
              ) : (
                isAdmin?.permissions?.product.includes("CREATE") && (
                  <MDButton
                    disabled={Loading}
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(false);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create Product
                  </MDButton>
                )
              )}
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 3,
                width: "100%",
              }}
            >
              <MDBox
                // px={3}
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  gap: 0.5,
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("md")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("lg")]: {
                    flexDirection: "column",
                  },
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    [breakpoints.up("xs")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pl: 3,
                    },
                    [breakpoints.up("sm")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pl: 3,
                    },
                    [breakpoints.up("md")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pt: 4,
                    },
                    [breakpoints.up("lg")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pt: 4,
                    },
                  })}
                >
                  <MDTypography variant="button">Product Filter</MDTypography>

                  <MDInput
                    disabled={Loading}
                    placeholder="Name..."
                    type="text"
                    fullWidth
                    name="name"
                    value={name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={({ palette: { dark, white, info } }) => ({
                            backgroundColor: darkMode ? white.main : dark.main,
                            height: "100%",
                            padding: "1rem",
                            borderRadius: "5px",
                          })}
                        >
                          {filterCollapse ? (
                            <KeyboardArrowUpRounded
                              onClick={() => setFilterCollapse(false)}
                              size="20"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: !darkMode ? white.main : dark.main,
                                cursor: "pointer",
                              })}
                            />
                          ) : (
                            <KeyboardArrowDownRounded
                              onClick={() => setFilterCollapse(true)}
                              size="20"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: !darkMode ? white.main : dark.main,
                                cursor: "pointer",
                              })}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </MDBox>
                <Collapse
                  in={filterCollapse}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%", p: 3 }}
                >
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 0.5,
                      width: "100%",
                      [breakpoints.up("xs")]: {
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      },
                      [breakpoints.up("sm")]: {
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      },
                      [breakpoints.up("md")]: {
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                      [breakpoints.up("lg")]: {
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    })}
                  >
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Duration</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {dateFilter?.map((ele, i) => (
                          <MenuItem component="option" key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Category</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={categoryId}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setCategoryId((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, categoryId: e.target.value },
                        //   }));
                        // }}

                        onChange={(e) => setCategoryId(e.target.value)}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        <MenuItem value={0}>Choose Category</MenuItem>
                        {category?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>

                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Rating</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setcurrent((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, cityId: e.target.value },
                        //   }));
                        // }}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {/* <MenuItem value={0}>Choose ratting</MenuItem> */}
                        {sortByPrice?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>

                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Sort By Price</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setcurrent((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, price: e.target.value },
                        //   }));
                        // }}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {sortByPrice?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Sold</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={sold}
                        onChange={(e) => setSold(e.target.value)}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setcurrent((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, cityId: e.target.value },
                        //   }));
                        // }}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {/* <MenuItem value={0}>Choose ratting</MenuItem> */}
                        {sortByPrice?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Stock</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setcurrent((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, cityId: e.target.value },
                        //   }));
                        // }}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {/* <MenuItem value={0}>Choose ratting</MenuItem> */}
                        {sortByPrice?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Filter By Discount</MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        // onChange={(e) => {
                        //   setIsPages(1);
                        //   setcurrent((prev) => ({
                        //     ...prev,
                        //     filter: { ...prev?.filter, cityId: e.target.value },
                        //   }));
                        // }}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        {/* <MenuItem value={0}>Choose ratting</MenuItem> */}
                        {sortByPrice?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>

                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Brand </MDTypography>
                      <Select
                        disabled={Loading}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={brandId}
                        onChange={(e) => setBrandId(e.target.value)}
                        sx={({ palette: { dark, white, info } }) => ({
                          width: "100%",
                          height: "3rem",
                          color: darkMode ? white?.main : dark?.main,
                          bgcolor: "transparent",
                          "&	.MuiSelect-icon": {
                            color: darkMode ? white?.main : dark?.main,
                            display: "block !important",
                            fontSize: "1.5rem !important",
                          },
                        })}
                      >
                        <MenuItem value={0}>Choose Brand</MenuItem>
                        {brands?.map((ele, i) => (
                          <MenuItem key={i} value={ele?._id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <MDInput
                        disabled={Loading}
                        placeholder="Brand"
                        type="text"
                        fullWidth
                        name="brandId"
                        value={brandId}
                        onChange={(e) => setBrandId(e.target.value)}
                      /> */}
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Product Id </MDTypography>

                      <MDInput
                        disabled={Loading}
                        placeholder="product Id"
                        type="text"
                        fullWidth
                        name="productId"
                        value={productId}
                        onChange={(e) => setproductId(e.target.value)}
                      />
                    </MDBox>
                  </MDBox>
                </Collapse>
              </MDBox>
              <MDBox width="23%" display="flex" flexDirection="column" p={3}>
                <MDTypography variant="button"> Product Visibility </MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isDisable}
                  onChange={(e) => setIsDisable(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {isDisableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>

            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : AllProducts && AllProducts.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={AllProducts && AllProducts.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={page}
                        page={isPages}
                        onChange={(e, value) => setIsPages(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">data not fond !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        overflowY={true}
      >
        <CreateForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          // setIsSwitch={setIsSwitch}
          productId={productId}
          updateProductModal={updateProductModal}
          setUpdateProductModal={setUpdateProductModal}
          // ecom={pathname === '/products/ecom-products' ? true : false}
        />
        {/* <Form isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} /> */}
      </SkModal>
      <SkModal
        show={viewProductModal}
        unShow={setViewProductModal}
        width={{ sx: "100%", md: "55%", xl: "55%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
        disableScrollbar={true}
      >
        <SingleProduct single={viewProductId} />
      </SkModal>
    </>
  );
};

export default Product;
