import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useDispatch, useSelector } from "react-redux";
import ImagePicker from "components/ApnaUploader";
import { Cancel, CurrencyRupee, Percent } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import MDInput from "components/MDInput";
import AstrieskIcon from "components/AstrieskIcon";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { getAllBlogs } from "redux/festures/blogSlice";
import { updateBlog } from "redux/festures/blogSlice";
import { createPostBlogs } from "redux/festures/blogSlice";
import { handleAlert } from "redux/festures/alertSlice";
import ApnaSelect from "components/ApnaSelect/ApnaSelect";
import { getCategory } from "redux/festures/categorySlice";
import { getAllProducts } from "redux/festures/productSlice";
import { formattedDateServer } from "Utils/dateFunc";
import {
  getAllmemberships,
  getSingleMembership,
  getDisableMember,
} from "redux/festures/membershipSlice";
import SkDatePicker from "components/SkDataPicker";
import { getUpdatememberships, getCreatememberships } from "redux/festures/membershipSlice";
import { getAllTax } from "redux/festures/taxSlice";
import ApnaSelect2 from "components/ApnaSelect";
import AttributeInput from "components/ApnaSelect/AnkMultiInput";

const Form = ({ isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    dispatch(getAllTax(`${process.env.REACT_APP_API}/getAllTax`));
  }, []);

  const { tax, singleTax } = useSelector((state) => ({ ...state?.isTax }));

  const [memberData, setMemberData] = useState({
    title: "",
    mrp: "",
    priceDiscount: "",
    afterTaxValue: "",
    taxId: "",
    benefits: [],
    validity: "",
    freeDelivery: "",
  });

  // const [icon,setIcon] =useState("")
  // const [serverIcon,setServerIcon] =useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [isCouponsIcon, setIsCouponsIcon] = useState("");
  const [isShow, setIsShow] = useState("");
  const [isSelection, setIsSelection] = useState("category");
  const [isCategory, setIsCategory] = useState([]);
  const [isProducts, setIsProducts] = useState([]);
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { Loading, memberships, singlemembership } = useSelector((data) => ({
    ...data?.isMembership,
  }));

  // console.log(singlemembership, "singlemembership");

  useEffect(() => {
    if (singlemembership && isOpenUpdate) {
      setIsShow(singlemembership?.icon);
      // setBenefitsList(singlemembership?.benefits.map((ele) => ele))
      setMemberData((prev) => ({
        ...prev,
        title: singlemembership?.title,
        mrp: singlemembership?.mrp,
        priceDiscount: singlemembership?.priceDiscount,
        afterTaxValue: singlemembership?.afterTaxValue,
        taxId: singlemembership?.taxId,
        benefits: singlemembership?.benefits?.map((ele) => ele),
        validity: singlemembership?.validity,
        freeDelivery: singlemembership?.freeDelivery,
      }));
    } else {
      setIsShow("");
      // setBenefitsList("")
      setMemberData((prev) => ({
        ...prev,
        title: "",
        mrp: "",
        benefits: [],
        taxId: "",
        afterTaxValue: "",
        priceDiscount: "",
        validity: "",
        freeDelivery: "",
      }));
    }
  }, [singlemembership, isOpenUpdate]);

  const handleForm = (e) => {
    const { name, value } = e.target;
    setMemberData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    isCouponsIcon && formData.append("icon", isCouponsIcon);
    formData.append("title", memberData?.title);

    formData.append("mrp", memberData?.mrp);
    formData.append("taxId", memberData?.taxId);
    formData.append("afterTaxValue", memberData?.afterTaxValue);
    formData.append("validity", memberData?.validity);
    formData.append("freeDelivery", memberData?.freeDelivery);
    formData.append("priceDiscount", memberData?.priceDiscount);

    memberData?.benefits.forEach((benefits, index) =>
      formData.append(`benefits[${index}]`, benefits)
    );

    if (isOpenUpdate) {
      setIsLoading(true);
      dispatch(
        getUpdatememberships({
          url: `${process.env.REACT_APP_API}updateMembership/${singlemembership?._id}`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          setIsOpen(false);
          setIsOpenUpdate(false);
          setIsShow("");
          setIsLoading(false);
          setMemberData((prev) => ({
            ...prev,
            title: "",
            mrp: "",
            benefits: [],
            taxId: "",
            afterTaxValue: "",
            // beforeTaxValue: "",
            validity: "",
            freeDelivery: "",
          }));
          dispatch(getAllmemberships(`${process.env.REACT_APP_API}/getAllMembership`));
        }
      });
    } else {
      setIsLoading(true);
      dispatch(
        getCreatememberships({
          url: `${process.env.REACT_APP_API}createMembership`,
          data: formData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          setIsOpen(false);
          setIsLoading(false);
          setIsOpenUpdate(false);
          setIsShow("");
          setMemberData((prev) => ({
            ...prev,
            title: "",
            benefits: [],
            mrp: "",
            taxId: "",
            // beforeTaxValue: "",
            afterTaxValue: "",
            validity: "",
            freeDelivery: "",
          }));
          dispatch(getAllmemberships(`${process.env.REACT_APP_API}/getAllMembership`));
        }
      });
    }
    // }
    // else {
    //   dispatch(
    //     handleAlert({
    //       isOpen: true,
    //       type: "warning",
    //       msg: "all fields are required!",
    //     })
    //   );
    // }
  };

  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 5,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update MemberShip's ` : " Create MemberShip's"}
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 3,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Title Name <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Title Name"
              fullWidth
              name="title"
              value={memberData?.title}
              onChange={handleForm}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Tex Id <AstrieskIcon />
            </MDTypography>
            <ApnaSelect2
              required={true}
              // disabled={createUpdateLoading}
              data={tax}
              origin="taxId"
              value={memberData?.taxId}
              name="taxId"
              onChange={handleForm}
              // onChange={(e) =>
              //   setMemberData((prev) => ({
              //     ...prev,
              //     taxId: e.target.value,
              //   }))
              // }
              nameKey="taxPercent"
              valueKey="_id"
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              MRP <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="mrp"
              fullWidth
              name="mrp"
              value={memberData?.mrp}
              onChange={handleForm}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupee
                      fontSize="small"
                      sx={({ palette: { white, info } }) => ({
                        color: darkMode ? white.main : info.main,
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              After Tax Value <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="After Tax Value"
              fullWidth
              name="afterTaxValue"
              value={memberData?.afterTaxValue}
              onChange={handleForm}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupee
                      fontSize="small"
                      sx={({ palette: { white, info } }) => ({
                        color: darkMode ? white.main : info.main,
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Price Discount{" "}
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 500,
                }}
              >
                ( in % )
              </span>{" "}
              <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Price Discount"
              fullWidth
              name="priceDiscount"
              value={memberData?.priceDiscount}
              onChange={handleForm}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Percent
                      fontSize="small"
                      sx={({ palette: { white, info } }) => ({
                        color: darkMode ? white.main : info.main,
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>{" "}
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              validity{" "}
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: 500,
                }}
              >
                ( in Day&apos;s )
              </span>{" "}
              <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Before Tax Value"
              fullWidth
              name="validity"
              value={memberData?.validity}
              onChange={handleForm}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
              }}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography
              variant="h6"
              sx={{
                textTransform: "capitalize",
              }}
            >
              minimum order value for Free Delivery <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="freeDelivery"
              fullWidth
              name="freeDelivery"
              value={memberData?.freeDelivery}
              onChange={handleForm}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupee
                      fontSize="small"
                      sx={({ palette: { white, info } }) => ({
                        color: darkMode ? white.main : info.main,
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Benefits <AstrieskIcon />
            </MDTypography>

            <AttributeInput
              placeholder={"benefits"}
              name={"benefits"}
              attributes={memberData?.benefits}
              setAttributes={(e) => handleForm({ target: { name: "benefits", value: e } })}
              addField={(e) =>
                handleForm({
                  target: {
                    name: "benefits",
                    value: e,
                  },
                })
              }
              removeField={(e) =>
                handleForm({
                  target: {
                    name: "benefits",
                    value: e,
                  },
                })
              }
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Icon
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( icon size - 400.64 × 200 px )
              </MDTypography>
            </MDTypography>
            <ImagePicker
              // required={true}
              name="icon"
              multiple={false}
              images={isCouponsIcon}
              setImages={setIsCouponsIcon}
              //
            />

            {isCouponsIcon === "" && isShow && (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "50px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%" }}
                    src={`${process.env.REACT_APP_URI}/${isShow}`}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsShow(null);
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </div>
            )}
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
              {isOpenUpdate
                ? isLoading
                  ? `Updating... Membership`
                  : `Update Membership`
                : isLoading
                ? `Creating... Membership`
                : `Create Membership`}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default Form;
Form.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
};
