import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllFeedBack = createAsyncThunk("getAllFeedBack", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getAllglobleFeedBack = createAsyncThunk("getAllglobleFeedBack", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleFeedBack = createAsyncThunk("getSingleFeedBack", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateFeedBack = createAsyncThunk("updateFeedBack", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const FeedBackSlice = createSlice({
  name: "FeedBackSlice",
  initialState: {
    feedBack: null,
    singleFeedBack: null,
    Loading: false,
    isPages:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeedBack.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllFeedBack.fulfilled, (state, action) => {
        state.Loading = false;
        state.feedBack = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getAllglobleFeedBack.fulfilled, (state, action) => {
      
        state.feedBack = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getAllFeedBack.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSingleFeedBack.fulfilled, (state, action) => {
        state.Loading = false;
        state.singleFeedBack = action.payload?.data;
      })
  },
});
export default FeedBackSlice.reducer;
