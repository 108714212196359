import { Delete, Edit, KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  Card,
  Icon,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import PropTypes from "prop-types";

import SingleRange from "./SingleRange";

// import Form from "./Form";

import { getAllRange } from "redux/festures/RangeSlice";

import SkConfirm from "components/SkComfirm";

import Form from "./Form";
import { deleteRange } from "redux/festures/RangeSlice";
import { getAllGlobleRange } from "redux/festures/RangeSlice";
import { isSingleData } from "redux/festures/RangeSlice";
import { isSigleDoshaRange } from "redux/festures/RangeSlice";
import { camelToFlat } from "Utils/dateFunc";

function Row({ row, index, setIsOpenDialog, setIsOpen, setIsOpenUpdate, setIsOpenView }) {
  // const { row } = props;
  // console.log(row, "row DAta");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUp
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            ) : (
              <KeyboardArrowDown
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          {index + 1}
        </TableCell>

        <TableCell
          align="center"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          {row?.max}
        </TableCell>
        <TableCell
          align="center"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          {row?.min}
        </TableCell>
        {isAdmin?.permissions?.all ? (
          <TableCell
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <IconButton
              aria-label="action_delete"
              // disabled={row?.disable}
              onClick={() => {
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: true,
                  isId: row?._id,
                }));
              }}
            >
              <Delete
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("DISABLE") && (
            <TableCell
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <IconButton
                aria-label="action_delete"
                // disabled={row?.disable}
                onClick={() => {
                  setIsOpenDialog((Preview) => ({
                    ...Preview,
                    open: true,
                    isId: row?._id,
                  }));
                }}
              >
                <Delete
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </TableCell>
          )
        )}
        {isAdmin?.permissions?.all ? (
          <TableCell
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(true);
                // setUpdateProductModal(true);
                // // console.log(value?._id);
                dispatch(isSingleData(row));
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("UPDATE") && (
            <TableCell
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MDBox sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <MDBox sx={{ display: "flex", justifyContent: "space-around" }}>
                  {row?.productData?.map((value, index) => (
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      type={"button"}
                      sx={{ textTransform: "capitalize" }}
                      //  onClick={() => setIsOpen(index === 0)}
                      onClick={() => {
                        dispatch(isSigleDoshaRange(value));
                        setIsOpenView(true);
                      }}
                      key={index}
                    >
                      {camelToFlat(value?.doshaId?.name)}
                    </MDButton>
                  ))}
                </MDBox>
              </Table>
            </MDBox>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    no: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    productData: PropTypes.array.isRequired,
    _id: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  setIsOpenDialog: PropTypes.func,
  setIsOpen: PropTypes.func,
  setIsOpenUpdate: PropTypes.func,
  setIsOpenView: PropTypes.func,
};

const RangePrice = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [viewData, setViewData] = useState("");

  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const { Loading, Range } = useSelector((state) => ({ ...state?.isRange }));

  useEffect(() => {
    dispatch(getAllRange(`${process.env.REACT_APP_API}/getAllDiseaseRange`));
  }, []);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Dosh Range{" "}
              </MDTypography>

              <MDButton
                variant="gradient"
                color="dark"
                disabled={Loading}
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(false);
                  dispatch(isSingleData(null));
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Range
              </MDButton>
            </MDBox>

            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : Range && Range?.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead sx={{ display: "table-header-group" }}>
                      <TableRow>
                        <TableCell />
                        <TableCell
                          sx={({ palette: { dark, white, info } }) => ({
                            color: darkMode ? white.main : dark.main,
                            width: 40,
                          })}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={({ palette: { dark, white, info } }) => ({
                            color: darkMode ? white.main : dark.main,
                          })}
                        >
                          Max
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={({ palette: { dark, white, info } }) => ({
                            color: darkMode ? white.main : dark.main,
                          })}
                        >
                          Min
                        </TableCell>

                        {isAdmin?.permissions?.all ? (
                          <TableCell
                            align="center"
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white.main : dark.main,
                            })}
                          >
                            Delete
                          </TableCell>
                        ) : (
                          isAdmin?.permissions?.range?.includes("DISABLE") && (
                            <TableCell
                              align="center"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: darkMode ? white.main : dark.main,
                              })}
                            >
                              Disable
                            </TableCell>
                          )
                        )}
                        {isAdmin?.permissions?.all ? (
                          <TableCell
                            align="center"
                            sx={({ palette: { dark, white, info } }) => ({
                              color: darkMode ? white.main : dark.main,
                            })}
                          >
                            Update
                          </TableCell>
                        ) : (
                          isAdmin?.permissions?.range?.includes("UPDATE") && (
                            <TableCell
                              align="center"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: darkMode ? white.main : dark.main,
                              })}
                            >
                              Update
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Range &&
                        Range?.length > 0 &&
                        Range?.map((range, i) => (
                          <Row
                            key={i}
                            row={range}
                            index={i}
                            setIsOpenDialog={setIsOpenDialog}
                            setIsOpen={setIsOpen}
                            setIsOpenUpdate={setIsOpenUpdate}
                            setIsOpenView={setIsOpenView}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">data not fond !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        maxHeight={"90vh"}
        padding={3}
        overflowY={true}
      >
        <Form
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        maxHeight={"90vh"}
        padding={3}
        overflowY={true}
      >
        <SingleRange isOpenView={isOpenView} setIsOpenView={setIsOpenView} />
      </SkModal>

      <SkConfirm
        dialogTitle={"Delete"}
        dialogContent={"Are you sure you want to Delete this Disease Range?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        rate={true}
        dialogAction={
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,

              px: 3,
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{ width: "50%" }}
              color="error"
              onClick={() => {
                // isStatusUpdateFunction("CANCELLED", true);
                dispatch(
                  deleteRange({
                    url: `${process.env.REACT_APP_API}deleteDiseaseRange/${isOpenDialog?.isId}`,
                  })
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data.payload.message,
                    })
                  );
                  if (data?.payload.success) {
                    dispatch(getAllGlobleRange(`${process.env.REACT_APP_API}/getAllDiseaseRange`));
                  }
                });
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              yes
            </MDButton>
          </MDBox>
        }
      />
    </>
  );
};

export default RangePrice;
