import { Card, Chip, Divider } from "@mui/material";
import { SkPrice } from "Utils/dateFunc";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import React from "react";
import { useSelector } from "react-redux";

const SingleTransition = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { Loading, singletransition, isPages } = useSelector((state) => ({
    ...state?.isAffiliate,
  }));

  // console.log(singletransition, "singletransition");

  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Affilate Transaction
          </MDTypography>
        </Card>
        {Loading ? (
          <SkLoading />
        ) : (
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            sx={({ palette: { dark, white, info } }) => ({
              border: 0.5,
              borderColor: darkMode ? white.main : dark.main,
              borderRadius: 3,
              p: 3,
              width: "100%",
              //   height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 2,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                // border: 0.5,
                // borderColor: darkMode ? white.main : dark.main,
                // borderRadius: 3,
                px: 2,
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              {singletransition?.affiliateProductId && (
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 3,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                  })}
                >
                  <MDTypography variant="h6">Affiliate Product Id</MDTypography>
                  <MDTypography variant="h6">
                    {/* {SkPrice(singleprice?.min) || 0} */}
                    {singletransition?.affiliateProductId?.productId || ""}
                  </MDTypography>
                </MDBox>
              )}

              {singletransition?.affiliateUserId && (
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 3,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                  })}
                >
                  <MDTypography variant="h6">Affiliate User Id</MDTypography>
                  <MDTypography variant="h6">
                    {/* {SkPrice(singleprice?.max) || 0} */}
                    {singletransition?.affiliateUserId?._id || ""}
                  </MDTypography>
                </MDBox>
              )}

              {singletransition?.amount && (
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 3,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                  })}
                >
                  <MDTypography variant="h6"> Amount :</MDTypography>

                  <MDTypography variant="h6">
                    {SkPrice(singletransition?.amount) || ""}
                  </MDTypography>
                </MDBox>
              )}

              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Status :</MDTypography>
                {singletransition?.status && (
                  <MDBadge
                    badgeContent={singletransition?.status}
                    color={
                      (singletransition?.status === "PENDING" && "warning") ||
                      (singletransition?.status === "APPROVED" && "success") ||
                      (singletransition?.status === "CANCELED" && "error")
                    }
                    variant="gradient"
                    size="lg"
                  />
                )}
              </MDBox>

              {singletransition?.orderId && (
                <>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                    image
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Order Details"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6"> Name :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.address?.name || "N/A"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6"> Mobile :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.address?.mobile || "N/A"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6"> Address :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.address?.cityName || ""},
                      {singletransition?.orderId?.address?.stateName || ""},
                      {singletransition?.orderId?.address?.pincode || ""}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6">Courier Service :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.courierService || "N/A"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6">Discount :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.discount || "0"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6"> Total Price :</MDTypography>

                    <MDTypography variant="h6">
                      {SkPrice(singletransition?.orderId?.totalPrice) || "0"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6">Product Id :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.product?.productId || "N/A"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                    <MDTypography variant="h6"> User Id :</MDTypography>

                    <MDTypography variant="h6">
                      {singletransition?.orderId?.userId || "N/A"}
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 3,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                    })}
                  >
                   

                    <MDTypography variant="h6">Payment Status :</MDTypography>
                    {singletransition?.orderId?.paymentStatus?.at(0) && (
                      <MDBadge
                        badgeContent={singletransition?.orderId?.paymentStatus?.at(0)}
                        color={
                          (singletransition?.orderId?.paymentStatus?.at(0) === "COD" && "success") ||
                          (singletransition?.orderId?.paymentStatus?.at(0) === "ONLINE" && "error")
                        }
                        variant="gradient"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </>
              )}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default SingleTransition;
