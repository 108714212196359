import { People } from "@mui/icons-material";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgreement } from "redux/festures/partnerSlice";
import AstrieskIcon from "components/AstrieskIcon";
import FileUploader from "components/ApnaUploader/pdfUploader";
import { updateAgreement } from "redux/festures/partnerSlice";
import { handleAlert } from "redux/festures/alertSlice";

const Agreements = () => {
  const [controller] = useMaterialUIController();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const { agreement } = useSelector((state) => ({
    ...state.isPartner,
  }));
  const { darkMode } = controller;
  const [openModel, setOpenModel] = useState(false);
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    dispatch(getAgreement(`${process.env.REACT_APP_API}/getAgreement`));
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (pdf) {
      const formdata = new FormData();
      formdata.append("agreementPdf", pdf);
      dispatch(
        updateAgreement({ url: `${process.env.REACT_APP_API}/updateAgreement`, data: formdata })
      ).then((res) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${res?.payload?.success ? "success" : "error"}`,
            msg: res?.payload?.message,
          })
        );
        if (res?.payload) {
          setPdf(null);
          setOpenModel(false);
        }
      });
    } else {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "Upload PDf First",
        })
      );
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Agreement PDF
              </MDTypography>
              <MDBox sx={{ display: "flex", gap: "2%" }}>
                {isAdmin?.permissions?.all ? (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    // disabled={true}
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      setOpenModel(true);
                    }}
                  >
                    <People />
                    &nbsp; Update
                  </MDButton>
                ) : (
                  isAdmin?.permissions?.subAdmin.includes("CREATE") && (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      // disabled={true}
                      sx={({ palette: { dark, white, info } }) => ({
                        color: white.main,
                        backgroundColor: dark.main,
                        "&:hover": {
                          color: white.main,
                          backgroundColor: dark.main,
                        },
                      })}
                      onClick={() => {
                        setOpenModel(true);
                      }}
                    >
                      <People />
                      &nbsp; Update
                    </MDButton>
                  )
                )}
              </MDBox>
            </MDBox>
            <MDBox py={3} px={3}>
              {" "}
              {agreement ? (
                <div>
                  <iframe
                    src={`${process.env.REACT_APP_URI}/${agreement?.agreementPdf}`}
                    width="100%"
                    height="600px"
                  />
                </div>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
            <SkModal
              show={openModel}
              unShow={setOpenModel}
              width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
              height={"auto"}
              padding={3}
              overflowY={true}
            >
              <MDBox
                display="flex"
                alignItems="center"
                lineHeight={1}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: 5,
                  width: "100%",
                }}
              >
                <Card
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 , 10px",
                  }}
                >
                  <MDTypography variant="h6" py={0.9}>
                    Update Agreement
                  </MDTypography>
                </Card>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                lineHeight={1}
                sx={({ palette: { dark, white, info } }) => ({
                  border: 0.5,
                  borderColor: darkMode ? white.main : dark.main,
                  borderRadius: 3,
                  p: 3,
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: 3,
                  width: "100%",
                  my: 2,
                })}
                component="form"
                role="form"
                onSubmit={handleFormSubmit}
              >
                <MDBox
                  lineHeight={1}
                  gap={1}
                  width={"90%"}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  display="flex"
                  flexDirection="column"
                >
                  <MDTypography variant="h6">
                    Agreement PDF <AstrieskIcon />
                  </MDTypography>
                  <MDBox
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: 3,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input
                      type="file"
                      accept=".pdf"
                      id="file-input"
                      onChange={(e) => setPdf(e.target.files[0])}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file-input" style={{ width: "100%" }}>
                      <MDButton variant="gradient" color="info" component="span" fullWidth>
                        {pdf ? pdf.name : " Choose File "}
                      </MDButton>
                    </label>
                  </MDBox>
                </MDBox>

                <MDBox
                  sx={{
                    width: "100%",
                    justifyContent: "flex-end",
                    textAlign: "center",
                    display: "flex",
                  }}
                >
                  {" "}
                  <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
                    Update
                  </MDButton>
                </MDBox>
              </MDBox>
            </SkModal>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default Agreements;
