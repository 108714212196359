import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllOffer = createAsyncThunk("getAllOffer", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});


export const updateOffer = createAsyncThunk("updateOffer", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const OfferSlice = createSlice({
  name: "OfferSlice",
  initialState: {
    offer: null,

    Loading: false,
    updateLoading: false,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllOffer.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllOffer.fulfilled, (state, action) => {
        state.Loading = false;
        state.offer = action.payload?.data;
      })
      .addCase(getAllOffer.rejected, (state, action) => {
        state.Loading = false;
      })
     
      .addCase(updateOffer.pending, (state, action) => {
        state.updateLoading = true;
      })
      .addCase(updateOffer.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateOffer.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});
export default OfferSlice.reducer;
