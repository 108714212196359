import axios from "axios";

import http from "Utils/api2";
import { handleAlert } from "./alertSlice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllState = createAsyncThunk("getAllState", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllCity = createAsyncThunk("getAllCity", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const locationSlice = createSlice({
  name: "locationSlice",
  initialState: {
    AllState: null,
    AllCity:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllState.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllState.fulfilled, (state, action) => {
        state.Loading = false;
        state.AllState = action.payload?.data;
      })
      .addCase(getAllState.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getAllCity.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllCity.fulfilled, (state, action) => {
        state.Loading = false;
        state.AllCity = action.payload?.data;
        console.log(action.payload,"cityytytytytytyty")
      })
      .addCase(getAllCity.rejected, (state, action) => {
        state.Loading = false;
      })
  },
});
export default locationSlice.reducer;
