// import React, { useState } from 'react';
// import { Container, Typography, Button, Select, MenuItem, FormControl, InputLabel, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';

// function App() {
//   const [category, setCategory] = useState('personal');
//   const [subcategories, setSubcategories] = useState(['shopping']);
//   const [categories, setCategories] = useState([]);

//   const categoryOptions = ['personal', 'work'];
//   const allSubcategories = {
//     personal: ['shopping', 'exercise'],
//     work: ['meetings', 'projects'],
//   };

//   const addCategory = () => {
//     setCategories([...categories, { category, subcategories }]);
//     setSubcategories(['shopping']); // Reset selected subcategories after adding a category
//   };

//   const removeCategory = (index) => {
//     const updatedCategories = [...categories];
//     updatedCategories.splice(index, 1);
//     setCategories(updatedCategories);
//   };

//   const handleSubcategoryChange = (e) => {
//     setSubcategories(e.target.value);
//   };

//   return (
//     <Container maxWidth="sm">
//       <Typography variant="h4" align="center" gutterBottom>
//         To-Do List
//       </Typography>

//       <FormControl fullWidth margin="normal">
//         <InputLabel htmlFor="category">Select Category</InputLabel>
//         <Select
//           id="category"
//           value={category}
//           onChange={(e) => {
//             setCategory(e.target.value);
//           }}
//         >
//           {categoryOptions.map((cat) => (
//             <MenuItem key={cat} value={cat}>
//               {cat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <FormControl fullWidth margin="normal">
//         <InputLabel htmlFor="subcategories">Select Subcategories</InputLabel>
//         <Select
//           id="subcategories"
//           multiple
//           value={subcategories}
//           onChange={handleSubcategoryChange}
//         >
//           {allSubcategories[category]?.map((subcat) => (
//             <MenuItem key={subcat} value={subcat}>
//               {subcat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <Button variant="contained" color="primary" onClick={addCategory}>
//         Add Category
//       </Button>

//       <List>
//         {categories.map((item, index) => (
//           <ListItem key={index}>
//             <ListItemText primary={`${item.category} - ${item.subcategories.join(', ')}`} />
//             <ListItemSecondaryAction>
//               <IconButton edge="end" aria-label="delete" onClick={() => removeCategory(index)}>
//                 <DeleteIcon />
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         ))}
//       </List>
//     </Container>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMaterialUIController } from "context";
import { useSelector } from "react-redux";

function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [category, setCategory] = useState("personal");
  const [subcategories, setSubcategories] = useState(["shopping"]);
  const [categories, setCategories] = useState([]);

  const categoryOptions = ["personal", "work"];
  const allSubcategories = {
    personal: ["shopping", "exercise"],
    work: ["meetings", "projects"],
  };

  // useEffect(() => {
  
  // }, [])
  

  const { permissions } = useSelector((data) => ({ ...data.isPermission }));

 

  const addCategory = () => {
    setCategories([...categories, { category, subcategories }]);
    setSubcategories([]);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  const handleSubcategoryChange = (e) => {
    setSubcategories(e.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" align="center" gutterBottom>
        Select Permissions
      </Typography>

      <FormControl fullWidth margin="normal">
        <Typography variant="body1" align="left">
          Permissions
        </Typography>
        <Select
          sx={({ palette: { dark, white, info } }) => ({
            width: "100%",
            height: "3rem",
            color: darkMode ? white?.main : dark?.main,
            bgcolor: "transparent",
            "& .MuiSelect-icon": {
              color: darkMode ? white?.main : dark?.main,
              display: "block !important",
              fontSize: "1.5rem !important",
            },
          })}
          id="category"
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
        >
          {categoryOptions.map((cat) => (
            <MenuItem key={cat} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography variant="subtitle1" align="center" gutterBottom>
          Select Sub Permissions
        </Typography>
        <Select
          id="subcategories"
          multiple
          value={subcategories}
          onChange={handleSubcategoryChange}
          sx={({ palette: { dark, white, info } }) => ({
            width: "100%",
            height: "3rem",
            color: darkMode ? white?.main : dark?.main,
            bgcolor: "transparent",
            "&	.MuiSelect-icon": {
              color: darkMode ? white?.main : dark?.main,
              display: "block !important",
              fontSize: "1.5rem !important",
            },
          })}
        >
          {allSubcategories[category]?.map((subcat) => (
            <MenuItem key={subcat} value={subcat}>
              {subcat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={addCategory}>
        Add Permissions
      </Button>

      <List>
        {categories.map((item, index) => (
          <ListItem key={index}>
            <Typography variant="h6" align="center" gutterBottom>
              {`${item.category} - ${item.subcategories.join(", ")}`}
            </Typography>

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => removeCategory(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default App;

// function App() {
//     const [controller] = useMaterialUIController();
//     const { darkMode } = controller;
//   const [category, setCategory] = useState(null);
//   const [subcategories, setSubcategories] = useState(["shopping"]);
//   const [categories, setCategories] = useState([]);

//   const categoryOptions = ["personal", "work"];
//   const allSubcategories = {
//     personal: ["shopping", "exercise"],
//     work: ["meetings", "projects"],
//   };

//   const { permissions } = useSelector((data) => ({ ...data.isPermition }));

// console.log(permissions,"componentdjdnfsd")

//   const addCategory = () => {
//     setCategories([...categories, { category, subcategories }]);
//     setSubcategories([]);
//   };

//   const removeCategory = (index) => {
//     const updatedCategories = [...categories];
//     updatedCategories.splice(index, 1);
//     setCategories(updatedCategories);
//   };

//   const handleSubcategoryChange = (e) => {
//     setSubcategories(e.target.value);
//   };

//   return (
//     <Container maxWidth="sm">
//       <Typography variant="h6" align="center" gutterBottom>
//         Select Permissions
//       </Typography>

//       <FormControl fullWidth margin="normal">
//         <Typography variant="body1" align="left" >
//           Permissions
//         </Typography>

//         {/* <InputLabel htmlFor="category">Select Category</InputLabel> */}
//         <Select
//           sx={({ palette: { dark, white, info } }) => ({
//             width: "100%",
//             height: "3rem",
//             color: darkMode ? white?.main : dark?.main,
//             bgcolor: "transparent",
//             "&	.MuiSelect-icon": {
//               color: darkMode ? white?.main : dark?.main,
//               display: "block !important",
//               fontSize: "1.5rem !important",
//             },
//           })}
//           id="category"
//           value={category}
//           onChange={(e) => {
//             setCategory(e.target.value);
//           }}
//         >
//           {category?.map((cat) => (
//             <MenuItem key={cat} value={cat}>
//               {cat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <FormControl fullWidth margin="normal">
//         <Typography variant="subtitel" align="center" gutterBottom>
//           Select Sub Permissions
//         </Typography>
//         {/* <InputLabel htmlFor="subcategories">Select Subcategories</InputLabel> */}
//         <Select
//           id="subcategories"
//           multiple
//           value={subcategories}
//           onChange={handleSubcategoryChange}

//           sx={({ palette: { dark, white, info } }) => ({
//             width: "100%",
//             height: "3rem",
//             color: darkMode ? white?.main : dark?.main,
//             bgcolor: "transparent",
//             "&	.MuiSelect-icon": {
//               color: darkMode ? white?.main : dark?.main,
//               display: "block !important",
//               fontSize: "1.5rem !important",
//             },
//           })}
//         >
//          {Object.values(permissions[category])?.map((subcat) => (
//             <MenuItem key={subcat} value={subcat}>
//               {subcat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <Button variant="contained" color="primary" onClick={addCategory}>
//         Add Permissions
//       </Button>

//       <List>
//         {categories.map((item, index) =>
//           <ListItem key={index}>
//             {/* <ListItemText primary={`${item.category} - ${item.subcategories.join(", ")}`} /> */}

//             <Typography variant="h6" align="center" gutterBottom>
//             {`${item.category} - ${item.subcategories.join(", ") } `}
//       </Typography>

//             <ListItemSecondaryAction>
//               <IconButton edge="end" aria-label="delete" onClick={() => removeCategory(index)}>
//                 <DeleteIcon />
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         )}
//       </List>
//     </Container>
//   );
// }

// export default App;

// import React, { useState } from "react";
// import {
//   Container,
//   Typography,
//   Button,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useMaterialUIController } from "context";
// import { useSelector } from "react-redux";

// function App() {
//   const [controller] = useMaterialUIController();
//   const { darkMode } = controller;
//   const [category, setCategory] = useState("customer"); // Set a default category
//   const [subcategories, setSubcategories] = useState(["DISABLE"]);
//   const [categories, setCategories] = useState([]);

//   const categoryOptions = ["customer", "subAdmin"];
//   const allSubcategories = {
//     customer: ["DISABLE", "UPDATE", "VIEWS", "NONE"],
//     subAdmin: ["CREATE", "DISABLE", "UPDATE", "VIEWS", "NONE"],
//   };

//   // const { permissions } = useSelector((data) => ({ ...data.isPermition }));

//   console.log(permissions,"permissions")

//   const addCategory = () => {
//     setCategories([...categories, { category, subcategories }]);
//     setSubcategories([]);
//   };

//   const removeCategory = (index) => {
//     const updatedCategories = [...categories];
//     updatedCategories.splice(index, 1);
//     setCategories(updatedCategories);
//   };

//   const handleSubcategoryChange = (e) => {
//     setSubcategories(e.target.value);
//   };

//   return (
//     <Container maxWidth="sm">
//       <Typography variant="h6" align="center" gutterBottom>
//         Select Permissions
//       </Typography>

//       <FormControl fullWidth margin="normal">
//         <Typography variant="body1" align="left">
//           Permissions
//         </Typography>
//         <Select
//           sx={({ palette: { dark, white } }) => ({
//             width: "100%",
//             height: "3rem",
//             color: darkMode ? white?.main : dark?.main,
//             bgcolor: "transparent",
//             "& .MuiSelect-icon": {
//               color: darkMode ? white?.main : dark?.main,
//               display: "block !important",
//               fontSize: "1.5rem !important",
//             },
//           })}
//           id="category"
//           value={category}
//           onChange={(e) => {
//             setCategory(e.target.value);
//           }}
//         >
//           {Object.values(permissions).map((cat,kay) => (
//             <MenuItem key={cat} value={cat}>
//               {cat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <FormControl fullWidth margin="normal">
//         <Typography variant="subtitle1" align="center" gutterBottom>
//           Select Sub Permissions
//         </Typography>
//         <Select
//           id="subcategories"
//           multiple
//           value={subcategories}
//           onChange={handleSubcategoryChange}
//           sx={({ palette: { dark, white } }) => ({
//             width: "100%",
//             height: "3rem",
//             color: darkMode ? white?.main : dark?.main,
//             bgcolor: "transparent",
//             "& .MuiSelect-icon": {
//               color: darkMode ? white?.main : dark?.main,
//               display: "block !important",
//               fontSize: "1.5rem !important",
//             },
//           })}
//         >
//           {Object.values(allSubcategories[category])?.map((subcat) => (
//             <MenuItem key={subcat} value={subcat}>
//               {subcat}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>

//       <Button variant="contained" color="primary" onClick={addCategory}>
//         Add Permissions
//       </Button>

//       <List>
//         {categories.map((item, index) => (
//           <ListItem key={index}>
//             <Typography variant="h6" align="center" gutterBottom>
//               {`${item.category} - ${item.subcategories.join(", ")}`}
//             </Typography>
//             <ListItemSecondaryAction>
//               <IconButton
//                 edge="end"
//                 aria-label="delete"
//                 onClick={() => removeCategory(index)}
//               >
//                 <DeleteIcon />
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//         ))}
//       </List>
//     </Container>
//   );
// }

// export default App;
