// import React, { useState, useCallback } from "react";
// import { useDropzone } from "react-dropzone";
// import Cropper from "react-easy-crop";

// const ImageUploader = ({ images, setImages }) => {
//   console.log(images, "images");
//   console.log(setImages, "setImages");
//   // const [selectedImage, setSelectedImage] = useState(null);
//   const [croppedImage, setCroppedImage] = useState(null);
//   console.log(croppedImage, "croppedImage");
//   console.log(setCroppedImage, "setCroppedImage");
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);

//   const onDrop = useCallback((acceptedFiles) => {
//     const file = acceptedFiles[0];
//     setImages(URL.createObjectURL(file));
//     setCroppedImage(null);
//   }, []);

//   const onCropChange = (croppedArea, croppedAreaPixels) => {
//     setCrop(croppedAreaPixels?.x ? croppedAreaPixels : { x: 0, y: 0 });
//   };

//   const onZoomChange = (zoomValue) => {
//     setZoom(zoomValue);
//   };

//   const handleCropComplete = async (croppedAreaPixels) => {
//     const croppedImg = await getCroppedImg(images, croppedAreaPixels);
//     setCroppedImage(croppedImg);
//   };

//   return (
//     <div>
//       <ImagePicker onDrop={onDrop} />
//       {images && (
//         <ImageCropper
//           image={images}
//           crop={crop}
//           zoom={zoom}
//           onCropChange={onCropChange}
//           onZoomChange={onZoomChange}
//           onCropComplete={handleCropComplete}
//         />
//       )}
//       {croppedImage && (
//         <div>
//           <h2>Cropped Image</h2>
//           <img src={URL.createObjectURL(croppedImage)} alt="Cropped" />
//         </div>
//       )}
//     </div>
//   );
// };

// const ImagePicker = ({ onDrop }) => {
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     accept: "image/*",
//   });

//   return (
//     <div {...getRootProps()} style={dropzoneStyle}>
//       <input {...getInputProps()} />
//       {isDragActive ? (
//         <p>Drop the image here...</p>
//       ) : (
//         <p>Drag 'n' drop an image here, or click to select one</p>
//       )}
//     </div>
//   );
// };

// const dropzoneStyle = {
//   border: "2px solid #ccc",
//   borderRadius: "4px",
//   padding: "20px",
//   textAlign: "center",
//   cursor: "pointer",
//   width: "100%",
// };

// const ImageCropper = ({ image, crop, zoom, onCropChange, onZoomChange, onCropComplete }) => {
//   return (
//     <div style={cropperContainerStyle}>
//       <Cropper
//         image={image}
//         crop={crop}
//         zoom={zoom}
//         aspect={4 / 3}
//         onCropChange={onCropChange}
//         onZoomChange={onZoomChange}
//         onCropComplete={onCropComplete}
//       />
//     </div>
//   );
// };

// const cropperContainerStyle = {
//   position: "relative",
//   width: "100%",
//   height: "300px",
//   overflow: "hidden",
// };

// const getCroppedImg = (imageSrc, pixelCrop) => {
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   const image = new Image();
//   image.src = imageSrc;

//   return new Promise((resolve) => {
//     image.onload = () => {
//       canvas.width = pixelCrop.width;
//       canvas.height = pixelCrop.height;

//       ctx.drawImage(
//         image,
//         pixelCrop.x,
//         pixelCrop.y,
//         pixelCrop.width,
//         pixelCrop.height,
//         0,
//         0,
//         pixelCrop.width,
//         pixelCrop.height
//       );

//       canvas.toBlob((blob) => {
//         resolve(blob);
//       }, "image/jpeg");
//     };
//   });
// };

// export default ImageUploader;

// import React, { useEffect, useState } from "react";
// import "./ImagePicker.css";
// import PropTypes from "prop-types";
// import { Cancel } from "@mui/icons-material";
// import { useMaterialUIController } from "context";
// // import ArgonTypography from "components/ArgonTypography";
// // import CancelIcon from "@mui/icons-material/Cancel";

// const ImagePicker = ({
//   images,
//   setImages,
//   name,
//   multiple,
//   isImageURLs,
//   isImageURLsImages,
//   ...rest
// }) => {
// const [imageURLs, setImageURLs] = useState([]);
// const [controller] = useMaterialUIController();
// const { darkMode } = controller;
// useEffect(() => {
//   if (images && multiple && images?.length > 0) {
//     const newImageURls = [];
//     images && images?.forEach((image) => newImageURls.push(URL.createObjectURL(image)));
//     setImageURLs(newImageURls);
//   } else setImageURLs([]);

//   if (images && typeof images === "object" && !multiple) {
//     const newImageURLs = [];

//     if (images instanceof File || images instanceof Blob) {
//       newImageURLs.push(URL.createObjectURL(images));
//       setImageURLs(newImageURLs);
//     } else if (Array.isArray(images)) {
//       images.forEach((image) => {
//         if (image instanceof File || image instanceof Blob) {
//           newImageURLs.push(URL.createObjectURL(image));
//         }
//       });

//       setImageURLs(newImageURLs);
//     }
//   }
// }, [images]);

// function onImageChange(e) {
//   if (e.target.files && e.target.files.length) {
//     const files = Array.from(e.target.files);

//     if (multiple) {
//       setImages(files);
//     } else {
//       setImages(files[0]);
//     }

//     const newImageURLs = files?.map((file) => URL.createObjectURL(file));
//     setImageURLs(newImageURLs);
//   } else {
//     setImages(null);
//     setImageURLs([]);
//   }
// }

//   // console.log(imageURLs);
//   return (
//     <div style={{ width: "100%" }}>
// <input
//   type="file"
//   id={name || "file"}
//   name={name}
//   className="custom-file-input"
//   multiple={multiple ? true : false}
//   accept="image/*"
//   onChange={onImageChange}
//   {...rest}
//   style={{ width: "100%" }}
// />
//       <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
//         {imageURLs &&
//           imageURLs.map((imageSrc, i) => {
//             return (
//               <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
// <span
//   style={{
//     display: "inline-block",
//     width: "50px",
//     height: "50px",
//     margin: "0 0.5rem",
//   }}
// >
//   <img
//     className="Image"
//     key={i}
//     style={{ width: "100%", height: "100%", objectFit: "contain" }}
//     src={imageSrc}
//   />
// </span>
// <span
//   className="cross"
//   style={{
//     cursor: "pointer",
//   }}
//   onClick={() => {
//     if (multiple) {
//       const temp = [...images];
//       temp.splice(i, 1);
//       setImages([...temp]);
//     } else setImages(null);
//     document.getElementById(name || "file").value = "";
//   }}
// >
//   <Cancel
//     sx={({ palette: { dark, white, info } }) => ({
//       color: darkMode ? white?.main : dark.main,
//     })}
//   />
// </span>
//     </div>
//   );
// })}
//       </div>
//     </div>
//   );
// };

// export default ImagePicker;

// ImagePicker.propTypes = {
//   setImages: PropTypes.array.isRequired,
//   name: PropTypes.string,
//   value: PropTypes.string,
//   className: PropTypes.string,
//   multiple: PropTypes.string,
//   images: PropTypes.any,
//   isImageURLs: PropTypes.any,
//   isImageURLsImages: PropTypes.any,
// };

import React, { useEffect, useState } from "react";
import "./ImagePicker.css";
import PropTypes from "prop-types";
import { Cancel } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { CardMedia } from "@mui/material";

const ImagePicker = ({
  images,
  setImages,
  name,
  multiple,
  isImageURLs,
  isImageURLsImages,
  allow,
  ...rest
}) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isVideo, setIsVideo] = useState(true);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropChange = (crop) => setCrop(crop);
  const onZoomChange = (zoom) => setZoom(zoom);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.src = imageURLs[0];
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      img,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    const croppedImageDataUrl = canvas.toDataURL("image/jpeg");

    setCroppedImage(croppedImageDataUrl);
  };

  useEffect(() => {
    if (images && multiple && images?.length > 0) {
      const newImageURls = [];
      images && images?.forEach((image) => newImageURls.push(URL.createObjectURL(image)));
      setImageURLs(newImageURls);
    } else setImageURLs([]);

    if (images && typeof images === "object" && !multiple) {
      const newImageURLs = [];

      if (images instanceof File || images instanceof Blob) {
        newImageURLs.push(URL.createObjectURL(images));
        setImageURLs(newImageURLs);
      } else if (Array.isArray(images)) {
        images.forEach((image) => {
          if (image instanceof File || image instanceof Blob) {
            newImageURLs.push(URL.createObjectURL(image));
          }
        });

        setImageURLs(newImageURLs);
      }
    }
  }, [images]);

  function onImageChange(e) {
    if (e.target.files && e.target.files.length) {
      const files = Array.from(e.target.files);

    //  checking that files having image or video
      const supportedImageFormats = ["png", "jpg", "jpeg"];
      const lowerCaseFileName = files[0]?.name?.toLowerCase();
      const result = supportedImageFormats.some((format) => lowerCaseFileName.endsWith(format));
      setIsVideo(result);

      if (multiple) {
        setImages(files);
      } else {
        setImages(files[0]);
      }

      const newImageURLs = files?.map((file) => URL.createObjectURL(file));
      setImageURLs(newImageURLs);
    } else {
      setImages(null);
      setImageURLs([]);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <input
        type="file"
        id={name || "file"}
        name={name}
        className="custom-file-input"
        multiple={multiple ? true : false}
        accept={allow ? "image/*,video/*" : "image/*"}
        onChange={onImageChange}
        {...rest}
        style={{ width: "100%" }}
      />
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {images &&
          imageURLs &&
          imageURLs.map((imageSrc, i) => {
            return (
              <>
                {isVideo ? (
                  <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        height: "50px",
                        margin: "0 0.5rem",
                      }}
                    >
                      <img
                        className="Image"
                        key={i}
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        src={imageSrc}
                      />
                    </span>
                    <span
                      className="cross"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (multiple) {
                          const temp = [...images];
                          temp.splice(i, 1);
                          setImages([...temp]);
                        } else setImages(null);
                        document.getElementById(name || "file").value = "";
                      }}
                    >
                      <Cancel
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white?.main : dark.main,
                        })}
                      />
                    </span>
                  </div>
                ) : (
                  <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        height: "50px",
                        margin: "0 0.5rem",
                      }}
                    >
                      <CardMedia
                        component="video"
                        sx={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                        image={`${imageSrc}`}
                        title="wellmarts"
                        autoPlay={false}
                        controls
                        // poster={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../assets/images/no123.jpeg");
                        }}
                      />
                    </span>
                    <span
                      className="cross"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (multiple) {
                          const temp = [...images];
                          temp.splice(i, 1);
                          setImages([...temp]);
                        } else setImages(null);
                        document.getElementById(name || "file").value = "";
                      }}
                    >
                      <Cancel
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? white?.main : dark.main,
                        })}
                      />
                    </span>
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default ImagePicker;

ImagePicker.propTypes = {
  setImages: PropTypes.array.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.string,
  images: PropTypes.any,
  isImageURLs: PropTypes.any,
  isImageURLsImages: PropTypes.any,
  allow: PropTypes.any,
};
