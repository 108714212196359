import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllQuestions = createAsyncThunk("getAllQuestions ", async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getSingleQuestion = createAsyncThunk("getSingleQuestion", async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const deleteQuestion = createAsyncThunk("deleteQuestion", async ({ url }) => {
  try {
    const res = await axios.delete(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const questionAnswerSlice = createSlice({
  name: "QuestionSlice",
  initialState: {
    Loading: false,
    questions: null,
    singleQuestion:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuestions.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllQuestions.fulfilled, (state, action) => {
        state.Loading = false;
        state.questions = action.payload?.data;
      })
      .addCase(getAllQuestions.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSingleQuestion.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getSingleQuestion.fulfilled, (state, action) => {
        state.Loading = false;
        state.singleQuestion = action.payload?.data;
      })
      .addCase(getSingleQuestion.rejected, (state, action) => {
        state.Loading = false;
      })
  },
});
export default questionAnswerSlice.reducer;
