import React, { useState, useEffect } from "react";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import axios from "axios";
import { handleAlert } from "redux/festures/alertSlice";
import { skCompany } from "redux/festures/isCompany";
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";

const Skeditor = ({
  editorState,
  setEditorState,
  placeholder,
  initialContent,
  content,
  isButton,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialContent) {
      const blocksFromHTML = convertFromHTML(initialContent);
      const contentState = ContentState?.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState?.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [initialContent]);
  const onChangeEditor = (state) => {
    setEditorState(state);
  };

  const convertContentToHTML = () => {
    const contentState = editorState?.getCurrentContent();
    if (contentState) {
      const rawContentState = convertToRaw(contentState);
      const htmlContent = draftToHtml(rawContentState);
      return htmlContent;
    }
    return ""; // or any default value you prefer
  };

  const htmlContent = convertContentToHTML();
  // console.log(htmlContent, "htmlContent");
  const handleSubmitForm = async () => {
    const formData = new FormData();
    formData.append(content, convertContentToHTML());

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}company/updateCompany`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(
        handleAlert({
          isOpen: true,
          type: response?.data?.success ? "success" : "error",
          msg: response?.data?.message,
        })
      );
      dispatch(skCompany({ url: `/company/getCompany` }));
      // console.log(response.data, "response");
      // Handle success
    } catch (error) {
      // console.log(error, "responseERROR");
      // Handle error
    }
  };
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        resolve({ data: { link: data.target.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <>
      <MDBox
        sx={({ palette: { dark, white, info } }) => ({
          backgroundColor: darkMode ? "#fff" : "#000",
          color: darkMode ? "#000" : "#fff",
          p: 0.5,
          width: "100%",
          minHeight: "70vh",
          // display: "block",
        })}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={onChangeEditor}
          placeholder={placeholder}
          toolbar={{
            image: {
              uploadEnabled: true,
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              alt: { present: true, mandatory: false },
            },
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "embedded",
              "emoji",
              "image",
              "remove",
              "history",
            ],
            inline: {
              options: ["bold", "italic", "underline", "strikethrough", "superscript", "subscript"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
            },
            fontSize: {
              options: [12, 14, 16, 18, 24, 30, 36, 48],
            },
            list: {
              options: ["unordered", "ordered", "indent", "outdent"],
              indent: { icon: "indent", className: undefined },
              outdent: { icon: "outdent", className: undefined },
            },
            textAlign: {
              options: ["left", "center", "right", "justify"],
            },
            colorPicker: {
              colors: [
                "rgba(0, 0, 0, 1)",
                "rgba(255, 0, 0, 1)",
                "rgba(0, 0, 255, 1)",
                "rgba(0, 255, 0, 1)",
              ],
            },
            link: {
              defaultTargetOption: "_blank",
            },
            history: {
              inDropdown: true,
            },
            list: {
              options: ["unordered", "ordered", "indent", "outdent"], // Add "indent" and "outdent" options
            },
          }}
        />

        {/* <div>
        <h3>HTML Output:</h3>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div> */}
      </MDBox>
      {!isButton && (
        <MDBox
          sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", my: 1.5 }}
        >
          {isAdmin?.permissions?.all ? (
            <MDButton color={"info"} verdant={"gradient"} onClick={handleSubmitForm}>
              update
            </MDButton>
          ) : (
            isAdmin?.permissions?.company.includes("UPDATE") && (
              <MDButton color={"info"} verdant={"gradient"} onClick={handleSubmitForm}>
                update
              </MDButton>
            )
          )}
        </MDBox>
      )}
    </>
  );
};

export default Skeditor;

Skeditor.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  handleSubmitForm: PropTypes.func,
  initialContent: PropTypes.string,
  content: PropTypes.string,
  placeholder: PropTypes.string,
  isButton: PropTypes.bool,
};
