import {
  Close,
  Edit,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Collapse,
  InputAdornment,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
// import { getAllTax,getSingleTax } from "redux/festures/taxSlice";
import { getAllContect } from "redux/festures/ContectSlice";
import MDInput from "components/MDInput";

const columns = {
  AllTax: [
    { Header: "S.No", accessor: "no" },
    { Header: "company", accessor: "company" },
    { Header: "name / email", accessor: "name / email" },
    { Header: "mobile", accessor: "mobile" },
    { Header: "date", accessor: "date" },
    { Header: "message", accessor: "message" },
  ],
};

// function createAllBrands(isAdmin) {
//   return [
//     { Header: "S.No", accessor: "no", width: "20px" },
//     { Header: "Order Details", accessor: "order details" },
//     { Header: "Order Status", accessor: "order status" },
//     { Header: "Payment Method ", accessor: "payment method" },
//     { Header: "Order Total", accessor: "order total" },
//     { Header: "Place Order", accessor: "place Order" },

//     isAdmin?.permissions?.all
//       ? {
//           Header: "Update",
//           accessor: "Update",
//         }
//       : isAdmin?.permissions?.orders?.includes("UPDATE") && {
//           Header: "Update",
//           accessor: "Update",
//         },
//     isAdmin?.permissions?.all
//       ? {
//           Header: "view",
//           accessor: "view",
//         }
//       : isAdmin?.permissions?.orders?.includes("VIEWS") && {
//           Header: "view",
//           accessor: "view",
//         },

//   ].filter(Boolean);
// }
const ContectUs = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const [filterCollapse, setFilterCollapse] = useState(false);
  const { Loading, contect, isPages } = useSelector((state) => ({ ...state?.isContect }));
  const [pagess, setPagess] = useState(1);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    dispatch(
      getAllContect(
        `${process.env.REACT_APP_API}/getAllContactUs?page=${pagess || ""}&phone=${
          phone || ""
        }&email=${email || ""}&message=${message || ""}&company=${company || ""}`
      )
    );
  }, [pagess, phone, email, message, company]);

  useEffect(() => {
    if (contect && contect.length > 0) {
      const temprows =
        contect &&
        contect?.at(0) &&
        contect?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          company: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
                flexDirection: "column",
              }}
            >
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "250px",
                  lineHeight: "20px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Company : {value?.company || "N/A"}
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                fontSize={10}
              >
                User Id: {value?.userId || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          "name / email": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
                flexDirection: "column",
              }}
            >
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "250px",
                  lineHeight: "20px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Name: {value?.name || "N/A"}
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                fontSize={10}
              >
                Email : {value?.email || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          mobile: (
            <MDBox>
              <MDTypography variant="button" fontWeight="medium">
                {value?.phone || "N/A"}
              </MDTypography>
            </MDBox>
          ),

          date: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={10}>
                Create: {formattedDateServer(new Date(value?.createdAt))}
              </MDTypography>
              <MDTypography fontSize={10}>
                Last Update: {formattedDateServer(new Date(value?.updatedAt))}
              </MDTypography>
            </MDBox>
          ),

          message: (
            <MDBox>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "250px",
                  lineHeight: "20px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value?.message || "N/A"}
              </MDTypography>
            </MDBox>
          ),

          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getSingleTax(`/getTaxById/${value?._id}/${admin}`));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [contect]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Contect&apos;Us Table{" "}
              </MDTypography>
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 3,
                width: "100%",
              }}
            >
              <MDBox
                // px={3}
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  gap: 0.5,
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("md")]: {
                    flexDirection: "column",
                  },
                  [breakpoints.up("lg")]: {
                    flexDirection: "column",
                  },
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    [breakpoints.up("xs")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pl: 3,
                    },
                    [breakpoints.up("sm")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pl: 3,
                    },
                    [breakpoints.up("md")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pt: 4,
                    },
                    [breakpoints.up("lg")]: {
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 1.5,
                      pt: 4,
                    },
                  })}
                >
                  <MDTypography variant="button">Contect Filter</MDTypography>

                  <MDInput
                    disabled={Loading}
                    placeholder="company name..."
                    type="text"
                    fullWidth
                    name="company"
                    value={company}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={({ palette: { dark, white, info } }) => ({
                            backgroundColor: darkMode ? white.main : dark.main,
                            height: "100%",
                            padding: "1rem",
                            borderRadius: "5px",
                          })}
                        >
                          {filterCollapse ? (
                            <KeyboardArrowUpRounded
                              onClick={() => setFilterCollapse(false)}
                              size="20"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: !darkMode ? white.main : dark.main,
                                cursor: "pointer",
                              })}
                            />
                          ) : (
                            <KeyboardArrowDownRounded
                              onClick={() => setFilterCollapse(true)}
                              size="20"
                              sx={({ palette: { dark, white, info } }) => ({
                                color: !darkMode ? white.main : dark.main,
                                cursor: "pointer",
                              })}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </MDBox>
                <Collapse
                  in={filterCollapse}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%", p: 3 }}
                >
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 0.5,
                      width: "100%",
                      [breakpoints.up("xs")]: {
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      },
                      [breakpoints.up("sm")]: {
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      },
                      [breakpoints.up("md")]: {
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                      [breakpoints.up("lg")]: {
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    })}
                  >
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button">Search by Email</MDTypography>
                      <MDInput
                        disabled={Loading}
                        placeholder="Search Email ...."
                        type="email"
                        fullWidth
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setPagess(1);
                        }}
                      />
                    </MDBox>

                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button"> Search by Phone </MDTypography>

                      <MDInput
                        disabled={Loading}
                        placeholder="Search phone...."
                        type="number"
                        fullWidth
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setPagess(1);
                        }}
                      />
                    </MDBox>
                    <MDBox width="23%" display="flex" flexDirection="column">
                      <MDTypography variant="button"> Search by message </MDTypography>

                      <MDInput
                        disabled={Loading}
                        placeholder="Search message...."
                        type="text"
                        fullWidth
                        name="message"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setPagess(1);
                        }}
                      />
                    </MDBox>
                  </MDBox>
                </Collapse>
              </MDBox>
            </MDBox>

            {/* <MDBox py={3}>
              <MDBox width="23%" display="flex" flexDirection="column" px={2}>
                <MDTypography variant="button"> Search </MDTypography>

                <MDInput
                  disabled={Loading}
                  placeholder="Search...."
                  type="text"
                  fullWidth
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </MDBox>
            </MDBox> */}
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : contect && contect.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: columns?.AllTax,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={contect && contect.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        {/* <Form isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} /> */}
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      ></SkModal>
    </>
  );
};

export default ContectUs;
